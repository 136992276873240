/* @flow */

import produce from 'immer';
import _ from 'lodash';
import * as types from '../actions';
import cartGroup from './cart-group';

const data = (
  state = {
    loaded: true,
    isEmpty: true,
    all: cartGroup(undefined, {}),
    grouped: [],
    planified: {},
  },
  action,
) =>
  produce(state, draft => {
    let grouped;
    let planified;
    let index;
    let group;
    let all;
    let entry;
    switch (action.type) {
      case types.CLEAR_CART:
        return {
          loaded: true,
          isEmpty: true,
          all: cartGroup(undefined, {}),
          grouped: [],
          planified: {},
        };
      case types.LOADED_PLANIFIED:
        grouped = state.grouped.map((group, i) => {
          let planifiedRecipe = Object.values(action.data).find(recipe => {
            return recipe.slug === group.recipe.slug;
          });
          return cartGroup(group, {
            type: types.LOADED_PLANIFIED,
            data: planifiedRecipe,
          });
        });
        draft.planified = action.data;
        draft.grouped = orderGrouped(grouped);
        return;
      case types.ADD_ELEMENT_TO_CART:
        entry = Object.assign({}, action.data.entry, {
          key: action.data.key,
        });
        index = state.grouped.findIndex(group => {
          return entry.recipe.slug === group.recipe.slug;
        });
        group = cartGroup(state.grouped[index], {
          type: types.ADD_ELEMENT_TO_CART,
          data: entry,
        });
        let planifiedRecipe = Object.values(state.planified).find(recipe => {
          return recipe.slug === entry.recipe.slug;
        });
        if (planifiedRecipe) {
          group = cartGroup(group, {
            type: types.LOADED_PLANIFIED,
            data: planifiedRecipe,
          });
        }
        if (index === -1) {
          draft.grouped.push(group);
        } else {
          draft.grouped.splice(index, 1, group);
        }
        draft.all = cartGroup(state.all, {
          type: types.ADD_ELEMENT_TO_CART,
          data: entry,
        });
        draft.isEmpty = draft.all.isEmpty;
        return;
      case types.REMOVE_ELEMENT_FROM_CART:
        index = state.grouped.findIndex(group => {
          return action.data.entry.recipe.slug === group.recipe.slug;
        });
        if (index !== -1) {
          draft.grouped[index] = cartGroup(state.grouped[index], {
            type: types.REMOVE_ELEMENT_FROM_CART,
            data: action.data,
          });
          if (draft.grouped[index].items.length === 0) {
            draft.grouped.splice(index, 1);
          }
        }
        draft.all = cartGroup(state.all, {
          type: types.REMOVE_ELEMENT_FROM_CART,
          data: action.data,
        });
        draft.isEmpty = draft.all.isEmpty;
        return;
      case types.TOGGLE_CART_ELEMENTS_SELECTED_VISIBILITY:
        if (action.data.slug === 'default') {
          draft.all = cartGroup(state.all, {
            type: types.TOGGLE_CART_ELEMENTS_SELECTED_VISIBILITY,
            data: action.data,
          });
          return;
        } else {
          index = state.grouped.findIndex(group => {
            return action.data.slug === group.recipe.slug;
          });
          if (index !== -1) {
            group = cartGroup(state.grouped[index], {
              type: types.TOGGLE_CART_ELEMENTS_SELECTED_VISIBILITY,
              data: action.data,
            });
            draft.grouped.splice(index, 1, group);
          }
          return;
        }
    }
  });

const orderGrouped = function(groups, planified) {
  let planifiedRecipe;
  return _.sortBy(
    _.filter(groups, group => {
      return group.items.length > 0;
    }),
    group => {
      let slug = group.recipe.slug;
      if (slug === 'other') {
        return new Date();
      } else {
        return group.planifiedDate;
      }
    },
  );
};

export default data;
