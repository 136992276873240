/* @flow */

import * as types from '../actions'
import produce from 'immer'

const data = (
  state = {
    show: false,
    value: {},
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SHOW_TOAST:
        draft.show = true
        draft.value = action.data
        return
      case types.HIDE_TOAST:
        draft.show = false
        draft.value = {}
        return
    }
  })

export default data
