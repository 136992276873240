/* @flow */

import * as types from '../actions'
import produce from 'immer'
import Diet from './diet'

const data = (
  state = {
    list: {},
    info: {
      days: [],
      meals: [],
    },
  },
  action
) =>
  produce(state, draft => {
    let list, key, diet

    switch (action.type) {
      case types.LOADED_DIET:
        list = {}
        key = action.data.key
        diet = Diet(state.list[key], action)
        draft.list[key] = diet
        return
      case types.RECEIVE_DIETS_INFO:
        draft.info = action.data
        return
    }
  })

export default data
