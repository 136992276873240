import * as firebaseActions from './firebase-actions';
export const LOADED_PORTAL = 'LOADED_PORTAL';
export const REFRESH_PORTAL = 'REFRESH_PORTAL';

const refreshPortal = (data: Object): Object => {
  return {
    type: REFRESH_PORTAL,
    data,
  };
};

const receivePortal = (data: Object): Object => {
  return {
    type: LOADED_PORTAL,
    data,
  };
};

export const fetch = (): Function => {
  return async (dispatch, getState) => {
    const portal = await firebaseActions.getPortal();
    dispatch(receivePortal(portal));
  };
};

export const refresh = (): Function => {
  return async (dispatch, getState) => {
    dispatch(refreshPortal());
    await firebaseActions.forceRemoteConfigRefresh();
    const portal = await firebaseActions.getPortal();
    dispatch(receivePortal(portal));
  };
};
