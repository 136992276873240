import * as firebaseActions from './firebase-actions'
export const SHOW = 'SHOW_CURRENT_RECIPES'
export const HIDE = 'HIDE_CURRENT_RECIPES'

export const show = (data: Object): Object => {
  return {
    type: SHOW,
    data,
  }
}

export const hide = (data: Object): Object => {
  return {
    type: HIDE,
    data,
  }
}

export const addCurrentRecipe = (data: Object): Function => {
  return (dispatch, getState) => {
    firebaseActions.addCurrentRecipe(data)
  }
}

export const deleteCurrentRecipe = (data: Object): Function => {
  return (dispatch, getState) => {
    firebaseActions.deleteCurrentRecipe(data)
  }
}
