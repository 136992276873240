/* @flow */

import * as types from '../actions'

const data = (
  state = {
    navigator: null,
    routerState: null,
  },
  action
) => {
  let routeState
  let routeAction
  switch (action.type) {
    case types.SET_APP_NAVIGATOR:
      let navigator = action.data
      routeAction = navigator.router.getActionForPathAndParams(action.routeName)
      routeAction.params = {title: 'Últimas recetas'}
      const initialState = navigator.router.getStateForAction(routeAction)
      return Object.assign({}, state, {
        navigator: navigator,
        routerState: initialState,
      })
    case types.NAVIGATE:
      routeAction = state.navigator.router.getActionForPathAndParams(
        action.routeName
      )
      routeAction.params = action.params
    // falls through
    case types.NAVIGATE_BACK:
      routeState = state.navigator.router.getStateForAction(
        routeAction || action,
        state.routerState
      )
      if (action.reset) {
        routeState.index = 0
        routeState.routes = routeState.routes.splice(0, 1)
      }
      return Object.assign({}, state, {
        routerState: routeState || state.routerState,
      })
    case types.NAVIGATE_SET_PARAMS:
      let key = action.key
      delete action.key
      routeState = state.navigator.router.getStateForAction(
        {
          ...action,
          key: key || state.routerState.routes[state.routerState.index].key,
        },
        state.routerState
      )
      return Object.assign({}, state, {
        routerState: routeState,
      })
    default:
      return state
  }
}

export default data
