/* @flow */

import * as types from '../actions/release-notes'
import produce from 'immer'

const data = (
  state: Object = {
    list: {},
    loaded: false,
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case types.RECEIVE_RELEASE_NOTES:
        Object.keys(action.data).forEach(key => {
          draft[key] = action.data[key]
        })
        draft.loaded = true
    }
  })

export default data
