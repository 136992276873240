import _ from 'lodash';
import * as firebaseActions from './firebase-actions';
export const CHANGE_RATTING = 'CHANGE_RATTING';

const changeRatting = (id, data: Object): Object => {
  return {
    type: CHANGE_RATTING,
    id,
    data,
  };
};

export const enterRatting = (id): Function => {
  return (dispatch, getState) => {
    firebaseActions.enterRatting(id, (obj, action) => {
      dispatch(changeRatting(id, obj.val()));
    });
  };
};

export const leaveRatting = (id): Function => {
  return (dispatch, getState) => {
    firebaseActions.leaveRatting(id);
  };
};

export const onRateChange = (post, rate): Function => {
  return (dispatch, getState) => {
    firebaseActions.ratePost(post, rate);
  };
};