/* @flow */

import * as types from '../actions/chat-room';
import produce from 'immer';
import chatRoom from './chat-room';

const data = (
  state = {
    list: {},
  },
  action,
) =>
  produce(state, draft => {
    let key;
    switch (action.type) {
      case types.CLEAR_CHAT_ROOM:
      case types.SET_LOADING_STATUS_TO_CHAT_ROOM:
      case types.SET_HAS_PREVIOUS_MESSAGES:
      case types.ADD_MESSAGE_TO_CHAT_ROOM:
      case types.REMOVE_MESSAGE_TO_CHAT_ROOM:
      case types.CHANGE_MESSAGE_TO_CHAT_ROOM:
      case types.CHANGE_VOTE_ON_CHAT_ROOM:
      case types.SET_MOST_RATED_MESSAGES_ON_CHAT_ROOM:
        key = action.chatRoomId;
        draft.list[key] = chatRoom(state.list[key], action);
        return;
    }
  });

export default data;
