/* @flow */
/*eslint-disable prefer-const */

import { Box, Container, Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import HorizontalFilterList from '../../../../components/horizontal-filter-list';
import HorizontalPostList from '../../../../components/horizontal-post-list';
import PostFeatured from '../../../../components/post-featured';
import I18n from '../../../../i18n';
import { getLinkForPathAndSearch } from '../../../../utils/link-maker';

const useShowMoreStyles = makeStyles((theme) => ({
  link: {
    '&:hover, &:focus': {
      '& $showMoreButton': {
        opacity: 1,
        transform: 'translate3d(0px, 0px, 0px)',
      },
    },
  },
  showMoreButton: {
    'opacity': 0,
    'transform': 'translate3d(-20px, 0px, 0px)',
    'transitionDuration': '300ms',
    'transitionProperty': 'transform, opacity',
    'transitionTimingFunction': 'ease-out',
    '&::after': {
      content: '">"',
      marginLeft: '5px',
      fontWeight: 'bold',
    },
  },
}));

const COMPONENT_TYPE = {
  'recipes-horizontal-list': function (props) {
    const posts = _.get(props, 'postsContainer.posts', []);
    if (posts.length === 0) {
      return null;
    }
    return <HorizontalPostList limit={props.limit} posts={posts} />;
  },
  'next-diet-plates': function (props) {
    return null;
  },
  'filter-list': function (props) {
    return (
      <HorizontalFilterList
        alwaysOpen={true}
        open={true}
        animate={false}
        filters={props.filters}
        limit={5}
      />
    );
  },
  'post-preview': function (props) {
    return null;
  },
  'post-featured': function (props) {
    return (
      <Grid container spacing={2}>
        <PostFeatured {...props.post} />
      </Grid>
    );
  },
  'filter-featured': function (props) {
    return null;
  },
  'banner-ad': function (props) {
    return null;
  },
  'view': function ({ style }) {
    return <Box style={style} />;
  },
  'embed': function (props) {
    return null;
  },
};

const TITLE_TYPE = {
  'recipes-horizontal-list': function ({ title, style, item }) {
    const classes = useShowMoreStyles();
    const showSeeMoreButton = _.get(item, 'props.showSeeMoreButton');
    if (showSeeMoreButton) {
      const queryTitle = item.props.header
        ? `${item.props.header} ${item.props.title}`
        : item.props.title;
      return (
        <Link
          component={RouterLink}
          to={getLinkForPathAndSearch({
            path: '',
            search:
              item.props.postFilters &&
              `title=${queryTitle}&s=query:${item.props.postFilters[1].searchParam}`,
          })}
          title={title}
          className={classes.link}
        >
          {TITLE_TYPE.default({ title, style })}
          <Box
            component="span"
            display="inline-block"
            style={style}
            fontSize="h6.fontSize"
            pl={2}
            className={classes.showMoreButton}
          >
            {I18n.t('detail.related.seeMore')}
          </Box>
        </Link>
      );
    } else {
      return TITLE_TYPE.default({ title, style });
    }
  },
  'default': function ({ title, style }) {
    return (
      <Box
        component="h4"
        display="inline-block"
        style={style}
        fontSize="h6.fontSize"
        pl={2}
      >
        {title}
      </Box>
    );
  },
};

function PortalDynamicElement({ title, titleStyle, item }) {
  const type = item.type;
  const ChildComponent = COMPONENT_TYPE[type];
  const defaultPropsForType = {};
  if (!ChildComponent) {
    return null;
  }
  const childComponent = ChildComponent({
    ...defaultPropsForType,
    ...item.props,
  });
  if (!childComponent) {
    return null;
  }
  const ChildTitle = TITLE_TYPE[type] || TITLE_TYPE.default;
  return (
    <Box
      paddingBottom={2}
      style={{ ...defaultPropsForType.style, ...item.style }}
    >
      <Container maxWidth="xl">
        <ChildTitle title={title} style={titleStyle} item={item} />
        {childComponent}
      </Container>
    </Box>
  );
}

PortalDynamicElement.propTypes = {};

PortalDynamicElement.defaultProps = {};

export default PortalDynamicElement;
