/* @flow */

import * as types from '../actions';
import _ from 'lodash';
import {firebase} from '../platform';
import {TICK} from '../actions/time';
import produce from 'immer';

const Analytics = firebase.analytics();

const data = (
  state = {
    loginStatus: {},
    info: {
      reward: {
        active: false,
        accept: false,
        visualized: false,
        date: 0,
        currentDate: new Date().getTime(),
      },
      banned: false,
    },
    admin: false,
    tick: 0,
  },
  action,
) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SET_USER:
        draft.displayName = action.data.displayName;
        draft.email = action.data.email;
        draft.providerData = action.data.providerData;
        draft.admin = action.data.admin;
        draft.uid = action.data.uid;
        firebase.analytics().setUserId(action.data.uid);
        return;
      case TICK:
        draft.info.reward.currentDate = action.data;
        return;
      case types.RECEIVE_USER_INFO:
        draft.info = _.merge({}, state.info, action.data);
        return;
      case types.SET_LOGIN_STATUS:
        draft.loginStatus = action.data;
        return;
    }
  });

export default data;
