/* @flow */

import * as types from '../actions'
import _ from 'lodash'
import produce from 'immer'

const data = (
  state = {
    meals: [],
    nutrients: {},
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case types.LOADED_DIET:
        draft.meals = action.data.meals || {}
        draft.nutrients = processNutrients(draft.meals)
        return
    }
  })

const processNutrients = function(meals) {
  let nutrients = {}
  _.each(meals, meal => {
    _.each(meal.plates, (plate, k) => {
      if (plate.nutrients) {
        nutrients = addNutrients(nutrients, plate.nutrients.all)
      }
    })
  })
  return _.keys(nutrients).length > 0 ? nutrients : null
}

const addNutrients = function(nutrients, values) {
  _.each(values, (value, k) => {
    if (_.isObject(value)) {
      nutrients[k] = addNutrients(nutrients[k] || {}, value)
    } else {
      nutrients[k] = nutrients[k] || 0
      nutrients[k] += value
    }
  })
  return nutrients
}

export default data
