import _ from 'lodash'
import posts from './maps/posts'
import alarms from './maps/alarms'
import calendar from './maps/calendar'
import diet from './maps/diet'
import favs from './maps/favs'
import searched from './maps/searched'
import cart from './maps/cart'

const MAPPINGS = {
  child: [posts, alarms, calendar, diet, favs, searched, cart],
}

const transform = (data, map = {}, obj = {}, originKey, destinationKey) => {
  let found, key
  _.each(data, (v, k) => {
    found =
      _.find(map, {
        [destinationKey]: k,
      }) ||
      _.find(map, {
        [destinationKey]: '*',
      })
    if (found) {
      key = found[originKey] === '*' ? k : found[originKey]
      if (found.type === 'object') {
        obj[key] = transform(v, found.child, {}, originKey, destinationKey)
      } else if (found.type === 'array') {
        obj[key] = transform(v, found.child, [], originKey, destinationKey)
      } else {
        obj[key] = v
      }
    } else {
      obj[k] = v
    }
  })
  return obj
}

const findMapAndPath = _.memoize(keyMap => {
  let path = []
  let map
  keyMap = keyMap.split('/')
  if (keyMap.length > 0 && keyMap[0] === '') {
    keyMap.shift()
    path.push('')
  }
  return _.reduce(
    keyMap,
    (result, key) => {
      if (!result) {
        return
      }

      map = _.find(result.map, {
        name: key,
      })

      if (!map) {
        map = _.find(result.map, {
          name: '*',
        })
      }

      if (map) {
        result.path.push(map.key === '*' ? key : map.key)
        result.map = map.child
        return result
      }
    },
    {
      map: MAPPINGS.child,
      path: path,
    }
  )
})

const findMap = _.memoize(keyMap => {
  let found = findMapAndPath(keyMap)
  return found && found.map
})

export const getCompressedPath = _.memoize(keyMap => {
  let found = findMapAndPath(keyMap)
  return found && found.path.join('/')
})

export const decompress = _.memoize(
  (data, keyMap, obj) => {
    return transform(data, findMap(keyMap), obj, 'name', 'key')
  },
  (data, keyMap, obj) => {
    return (
      JSON.stringify(data) + '-keyMap-' + keyMap + '-obj-' + JSON.stringify(obj)
    )
  }
)

export const compress = _.memoize(
  (data, keyMap, obj) => {
    return transform(data, findMap(keyMap), obj, 'key', 'name')
  },
  (data, keyMap, obj) => {
    return (
      JSON.stringify(data) + '-keyMap-' + keyMap + '-obj-' + JSON.stringify(obj)
    )
  }
)
