export default {
  "about": {
    "aboutUs": "Sobre nosotros",
    "account": "Cuenta",
    "adWithUs": "¿Tienes una empresa vegana?",
    "adWithUsText": "¡Anúnciate con nosotros!",
    "contactUs": "Contáctanos",
    "findUsOnNet": "Nos encontrarás como Vegamecum en las principales redes sociales",
    "followUs": "Síguenos",
    "licenses": {
      "icons": {
        "description": "Los iconos {icons} han sido diseñados por Freepik para Flaticons",
        "title": "Iconos Freepik"
      },
      "sound": {
        "keys": "El efecto de sonido de teclas es obra de Mark DiAngelo y puede ser adquirido en {link}",
        "success": "El efecto de sonido de \"receta acabada\" es obra de Mike Koenig y puede ser adquirido en {link}",
        "title": "Efectos de sonido"
      },
      "title": "Licencias"
    },
    "privacy": {
      "title": "Política de privacidad",
      "url": "https://vegamecum.com/app/privacy/"
    },
    "rate": "Valóranos",
    "rateOnPlatform": "Puntúanos en {{platform}}",
    "rateUs": "¿Te gusta la aplicación? Ayúdanos valorándonos",
    "subtitle": "Recetas 100% vegetales para los nuevos omnívoros",
    "suggestionMail": "¿Tienes alguna sugerencia o quieres comentarnos algo? Puedes contactar con nosotros a través del correo",
    "supportText": "Ayuda a que Vegamecum siga adelante",
    "supportUs": "Apóyanos",
    "terms": "Términos legales",
    "termsOfUs": {
      "ads": {
        "description": "La publicidad mostrada en la aplicación proviene de terceras empresas. Pese que Vegamecum pone todas las medidas disponibles para su selección, Vegamecum no se hace responsable del contenido mostrado por estas empresas.",
        "title": "Publicidad"
      },
      "advise": "POR FAVOR, LEA DETENIDAMENTE ESTE DOCUMENTO ANTES DE USAR LA APLICACIÓN. USANDO LA APLICACIÓN ESTÁ ACEPTANDO LOS TÉRMINOS Y CONDICIONES QUE SE MUESTRAN A CONTINUACIÓN. ESTAS CONDICIONES ESTÁN SUJETAS A CAMBIOS. USTED SE COMPROMETE A REVISAR PERIODICAMENTE PARA ESTAR AL CORRIENTE DE LAS MODIFICACIONES.",
      "conditions": {
        "description": "La información nutricional mostrada en esta aplicación se basa en un calculo aproximado a partir de los datos recogidos en la USDA (United States Department of Agriculture) y de las tablas nutricionales facilitadas por los propios productores.{linebreak}La información mostrada es meramente informativa y en ningún caso sustituye al consejo médico. Si tiene dudas sobre su dieta consulte con un nutricionista.",
        "title": "Condiciones de Uso"
      },
      "content": {
        "description": "Vegamecum es el propietario del logo {logo}, la aplicación y de todo el contenido que se muestra en ella. {linebreak} Todo el contenido mostrado en la aplicación (tanto recetas como fotografías) está bajo la licencia Creative Common by-sa.",
        "title": "El contenido"
      },
      "thanks": "¡Gracias por utilizar la aplicación de Vegamecum!",
      "title": "Términos de uso"
    },
    "title": "Sobre esta App"
  },
  "alarm": {
    "cancel": "Cancelar",
    "return": "Retomar"
  },
  "alarms": {
    "empty": "No hay alarmas programadas",
    "title": "Alarmas"
  },
  "calendar": {
    "add": "Añadir",
    "days": {
      "names": [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado"
      ],
      "shorts": [
        "Dom.",
        "Lun.",
        "Mar.",
        "Mié.",
        "Jue.",
        "Vie.",
        "Sáb."
      ]
    },
    "months": {
      "names": [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ],
      "shorts": [
        "Ene.",
        "Feb.",
        "Mar.",
        "Abr.",
        "Mayo",
        "Jun.",
        "Jul.",
        "Ago.",
        "Sep.",
        "Oct.",
        "Nov.",
        "Dic."
      ]
    },
    "planification": {
      "cancel": "CANCELAR",
      "date": "{{day}} {{number}} de {{month}}",
      "delete": "ELIMINAR",
      "end": "Fin",
      "eventName": "Nombre del evento",
      "format": {
        "hour": "{{hour}} h.",
        "join": " y ",
        "minute": "{{minute}} min.",
        "whole": "{{hour}} y {{minute}}"
      },
      "save": "GUARDAR",
      "select": {
        "duration": "Ajusta la duración",
        "end": "Selecciona hora de inicio",
        "start": "Selecciona hora de fin"
      },
      "start": "Inicio",
      "title": "¿Quieres añadir un evento a tu calendario?",
      "totalTime": "Tiempo total"
    },
    "planificationTitle": "Planificación",
    "title": "Mi calendario"
  },
  "cartlist": {
    "add": "Añadir elemento",
    "complete": "Lista completa",
    "elementsMarked": "{{count}} elementos adquirido",
    "empty": {
      "description": "Toca sobre los ingredientes de la receta para añadirlos o añádelos manualmente",
      "title": "Tu lista de la compra está vacía"
    },
    "general": "Compra general",
    "planification": {
      "date": "{{day}} {{number}} de {{month}}",
      "notPlanified": "No planificada"
    },
    "selectOptions": {
      "deleteAll": "Eliminar todos los elementos",
      "deleteMarked": "Eliminar elementos marcados",
      "markAll": "Marcar todos los elementos",
      "title": "Más opciones",
      "unMarkAll": "Desmarcar todos los elementos"
    },
    "share": {
      "title": "Lista de la compra"
    },
    "title": "Mi lista de la compra"
  },
  "chat": {
    "bannedMessage": "Lo sentimos, no puedes escribir comentarios. Para solucionar el problema, envía un correo a vegamecum@gmail.com.",
    "deletedMessage": "Mensaje borrado",
    "emptyMessageList": "Esta receta aún no tiene comentarios",
    "loadEarlier": "Cargar más",
    "loginMessage": "Identifícate para comentar y valorar",
    "loginSmallMessage": "Identifícate",
    "moreOptions": {
      "banUser": "Banear usuario {{id}}",
      "quoteMessage": "Citar mensaje",
      "removeMessage": "Eliminar mensaje",
      "removeMessageAndReplies": "Eliminar mensaje y respuestas",
      "title": "Más opciones"
    },
    "placeholder": "Introduce un texto...",
    "reply": "Responder",
    "sendText": "Enviar",
    "title": "Comentarios"
  },
  "commentsEditor": {
    "description": "Comparte fotos y consejos sobre esta receta",
    "pictures": {
      "title": "Fotos de la receta"
    },
    "save": "Enviar",
    "text": {
      "placeholder": "Introduce un texto...",
      "title": "Comentario"
    },
    "textReply": {
      "placeholder": "Introduce tu respuesta...",
      "title": "Respuesta"
    },
    "title": "Nuevo comentario",
    "titleReply": "Respondiendo a {{name}}"
  },
  "config": {
    "account": {
      "close": "Cerrar sesión",
      "config": "Configurar cuenta",
      "identify": "Idéntificate",
      "signInText": "Entra con tu cuenta de usuario o crea una nueva",
      "title": "Mi cuenta",
      "userAccount": "Cuenta de usuario"
    },
    "calendar": {
      "sync": {
        "description": "Al activar esta funcionalidad las recetas planificadas se añadirán al calendario del dispositivo",
        "mobile": "Sincronizar con el calendario del móvil",
        "tablet": "Sincronizar con el calendario del tablet"
      },
      "title": "Calendario"
    },
    "language": {
      "improveTranslations": "Mejorar las traducciones",
      "improveTranslationsAdvice": "Ayúdanos a mejorar las traducciones. Al activar esta funcionalidad podrás proponer textos alternativos aparecerán {icon}.",
      "title": "Idioma",
      "ui": "Idioma de la aplicación"
    },
    "link": {
      "description": "Introduce tu correo electrónico y contraseña para identificarte",
      "destroy": {
        "confirm": {
          "button": "ELIMINAR",
          "text": "¿Estás seguro que quieres eliminar tu cuenta?",
          "textWithPassword": "¿Estás seguro de que quieres eliminar tu cuenta? Introduce tu contraseña para eliminarla",
          "title": "Eliminar cuenta"
        },
        "description": "Desde aquí puedes eliminar tu cuenta personal de Vegamecum. Una vez eliminada no podrás recuperar tus datos.",
        "title": "Eliminar"
      },
      "email": "Correo electrónico",
      "error": {
        "accept": "Aceptar",
        "email-already-in-use": "El correo electrónico introducido ya está registrado",
        "facebook-login-error": "Ha habido un error en el registro con Facebook",
        "invalid-data": "Introduce tu correo electrónico y contraseña",
        "invalid-email": "Correo electrónico no válido",
        "invalid-modify-data": "Introduce tu actual contraseña para poder actualizar tus datos",
        "invalid-register-data": "Asegúrate que todos los campos estén rellenos",
        "user-not-found": "Correo electrónico o contraseña incorrecto",
        "weak-password": "La contraseña debe tener un mínimo de 6 caracteres",
        "wrong-email-password": "Correo electrónico o contraseña incorrecto",
        "wrong-password": "La contraseña introducida es incorrecta"
      },
      "login": {
        "successfully": " ¡Hola de nuevo, {{name}}!",
        "welcome": " ¡Bienvenido {{name}}!"
      },
      "modified": {
        "successfully": " Tus datos han sido actualizados con éxito"
      },
      "newPassword": "Nueva contraseña",
      "oldPassword": "Contraseña actual",
      "password": "Contraseña",
      "register": {
        "acceptConditionsDescription": "<p>Al identificarte aceptas nuestras condiciones de uso. Consulta nuestro <a href=\"private\">aviso de privacidad</a>.</p>",
        "apple": "O entra con tu cuenta de Apple",
        "description": "¿Aún no estás registrado? Registrándote, podrás compartir tu cuenta entre distintos dispositivos y conservar tus favoritos, calendario y todas tus preferencias",
        "done": "¡Hecho!",
        "facebook": "O entra con tu cuenta de Facebook",
        "google": "O entra con tu cuenta de Google",
        "instructions": "Rellena los siguientes campos para registrarte",
        "title": "Regístrate"
      },
      "signIn": "Entrar",
      "title": "Cuenta de usuario",
      "update": "Actualizar",
      "updateFields": "Actualiza tus datos editando los siguientes campos",
      "userName": "Nombre de usuario"
    },
    "notification": {
      "alarm": "Alarma receta planificada",
      "recipe": "Nueva receta disponible",
      "title": "Notificaciones"
    },
    "privacy": {
      "description": "Configura tus preferencias de privacidad",
      "detail": {
        "ads": {
          "basic": "Publicidad simple",
          "canDisable": "Puedes desactivar la publicidad adquiriendo los extras \"Desactivar anuncios\" o \"Acceso PREMIUM\"",
          "configure": "O puedes configurar tus preferencias de publicidad",
          "disable": "Desactivar publicidad",
          "disabled": "Al disponer de la versión libre de anuncios, no se reportará nada.",
          "personalized": "Publicidad personalizada",
          "title": "Publicidad"
        },
        "basicPurposes": {
          "analytics": "Analiticas para mejorar la aplicación",
          "personalization": "Personalización de la aplicación",
          "title": "Propósitos básicos"
        },
        "enableAll": "Habilitar todo",
        "title": "Privacidad"
      },
      "save": "Guardar",
      "text": "Preferencias de privacidad",
      "title": "Privacidad"
    },
    "sound": {
      "alarms": "Alarmas activadas",
      "fx": "Efectos activados",
      "title": "Sonido"
    },
    "title": "Configuración",
    "visualization": {
      "fontSize": {
        "description": "Ajusta el tamaño de letra para visualizar la receta con la máxima comodidad",
        "title": "Tamaño de letra"
      },
      "measurementSystem": {
        "description": "Al activar esta funcionalidad se mostrarán los ingredientes en unidades que te permitan prescindir de una báscula",
        "list": {
          "customary": "Sistema USA",
          "metric": "Sistema métrico",
          "scaleLess": "Sin báscula"
        },
        "title": "Sistema de medidas"
      },
      "rotation": "Rotación activada",
      "scale": {
        "description": "Al activar esta funcionalidad se mostrarán los ingredientes en unidades que te permitan prescindir de una báscula",
        "title": "Sin báscula (beta)"
      },
      "theme": {
        "list": {
          "dark": "oscuro",
          "light": "blanco (por defecto)"
        },
        "title": "Tema gráfico"
      },
      "title": "Opciones de visualización"
    }
  },
  "configureLanguage": {
    "continue": "CONTINUAR",
    "improveTranslationsAdvice": "Las recetas de Vegamecum son traducidas del castellano principalmente de manera automática, por lo que es posible que contengan algunos errores. Activa el modo revisor para poder mejorar las traducciones.",
    "language": "Idioma",
    "previous": "ANTERIOR",
    "reviewerMode": "Modo revisor",
    "selectLanguage": "Selecciona el idioma en el quieres ver el contenido de Vegamecum",
    "thanks": "¡Gracias por sumarte al grupo de revisores! Podrás mejorar la traducción tocando en el icono '{icon}' que aparecerá junto a los textos"
  },
  "currentRecipes": {
    "add": "Añadir",
    "empty": "Aún no has añadido el acceso rápido a ninguna receta {linebreak} Dale al icono {icon} que encontrarás en la parte superior de la receta para tenerla a mano",
    "title": "Accesos rápidos"
  },
  "detail": {
    "ads": {
      "close": "¡Ahora puedes desactivar la publicidad en toda la app!<br/><a href=\"https://vegamecum.com/app/extras\">Silenciar Publicidad</a>",
      "label": "Publicidad",
      "text": "La publicidad nos ayuda a cubrir una mínima parte de los costes operativos. Puedes ayudar a que Vegamecum siga adelante haciendo una donación <a href=\"https://vegamecum.com/app/donate\">Link</a>"
    },
    "comments": {
      "shareWithOthers": "Añadir comentario",
      "showMoreComments": "Ver más",
      "title": "Comentarios"
    },
    "continueReading": "Continuar leyendo",
    "engagement": {
      "share": "¿Nos acompañáis en las redes sociales? ¡Nos encontraréis en Facebook, Twitter, Instagram y Pinterest!",
      "shareTitle": "Comparte esto:",
      "text": "Si tenéis cualquier duda, nos la podéis dejar en los comentarios. Y si os animáis y queréis mostrar al mundo vuestras creaciones, no dudéis en etiquetarnos, ¡nos encantará verlas por las redes!"
    },
    "error": "No se ha podido cargar la receta\nPor favor, vuelve ha intentarlo más tarde",
    "finished": {
      "close": "Cerrar",
      "congratulation": "¡Enhorabuena!",
      "share": "Compartir",
      "text": "Has completado la receta"
    },
    "loading": "Cargando",
    "loadingStatus": {
      "0": "Buscando receta",
      "1": "Vamos a por tu receta",
      "2": "Vamos a por tu receta",
      "3": "Vamos a por tu receta",
      "4": "Vamos a revisar los textos",
      "5": "Vamos acabando",
      "6": "Receta cargada",
      "-999": "Estado desconocido",
      "-1": "Buscando receta"
    },
    "menu": {
      "delete": "Eliminar",
      "delete-shortcut": "Eliminar acceso rápido",
      "deleteConfirm": {
        "button": "ELIMINAR",
        "text": "¿Estás seguro de que quieres eliminar tu receta?",
        "title": "Eliminar receta"
      },
      "diet": "Añadir a mi menú semanal",
      "disableVO": "Traducir receta",
      "edit": "Editar receta",
      "enableVO": "Ver en V.O.",
      "listen": "Escuchar receta",
      "planify": "Planificar receta",
      "share": "Compartir",
      "shortcut": "Añadir acceso rápido"
    },
    "noTitle": " ",
    "rate": {
      "done": "Tu valoración",
      "msg": "Toca para valorar",
      "notActive": "Esta receta aún no tiene suficientes valoraciones",
      "thanksMsg": "¡Muchas gracias por tu valoración!",
      "title": "Valoraciones",
      "totalUsers": "{{number}} valoraciones"
    },
    "related": {
      "categories": "Categorias",
      "category": "En {{category}}",
      "categoryList": {
        "básicos": "Básicos",
        "carnes vegetales": "Carnes vegetales",
        "cocina italiana": "Cocina italiana",
        "dulces y postres": "Dulces y Postres",
        "ensalada": "Ensalada",
        "entrantes": "Entrantes",
        "fiestas": "Fiestas",
        "otros": "Otros",
        "patés y untables": "Patés y untables",
        "plato único": "Plato único",
        "primeros": "Primeros",
        "quesos vegetales": "Quesos vegetales",
        "salsas": "Salsas",
        "segundos": "Segundos",
        "sin gluten": "Sin gluten"
      },
      "emptyCategory": "No se han encontrado más recetas de esta misma categoría",
      "emptyTag": "No se han encontrado más recetas con esta misma etiqueta",
      "list": "Relacionado",
      "next": "Siguiente",
      "previous": "Anterior",
      "sameCategory": "Otras recetas de la categoría {name}",
      "sameTag": "Otras recetas con la etiqueta {name}",
      "seeMore": "Ver todas",
      "tags": "Etiquetas"
    },
    "sections": {
      "comments": {
        "title": "Comentarios"
      },
      "ingredients": {
        "empty": "Sin unidad",
        "emptyList": "Sin ingredientes",
        "extra": {
          "allergens": {
            "gluten": "Gluten",
            "nuts": "Frutos secos",
            "soy": "Soja",
            "title": "Identifica los posible alérgenos cuando aparezcan los siguientes iconos"
          },
          "cartlist": "Toca sobre los ingredientes para añadirlos o quitarlos de la lista de la compra",
          "notes": "Toca {logo} para ver notas o trucos",
          "servings": "Ajusta las cantidades con los botones {removeIcon} y {addIcon}"
        },
        "recipeSelected": {
          "addToCart": "Añadir a la lista de la compra",
          "doByYourself": "Hacerlo tu mismo",
          "title": "Para este ingrediente tienes dos opciones"
        },
        "title": "Ingredientes"
      },
      "introduction": {
        "edit": "Editar descripción",
        "empty": "Sin descripción",
        "emptyEdit": "Añadir descripción de la receta",
        "title": "Introducción"
      },
      "notes": {
        "title": "Notas"
      },
      "nutritional": {
        "amount": "Información nutricional {{text}}",
        "extra": {
          "CDR": "% de la cantidad diaria recomendada para una dieta de 2000 calorías"
        },
        "noInfo": "Sin información nutricional",
        "summaryButton": {
          "carbo": "Carbohidratos",
          "enery": "Energia",
          "fats": "Grasas",
          "noInfo": "Sin información",
          "proteins": "Proteínas"
        },
        "title": "Información Nutricional"
      },
      "preparation": {
        "emptyList": "Sin preparación",
        "extra": {
          "notes": "Toca {logo} para ver notas o trucos",
          "steps": "Toca sobre los pasos para marcarlos como hechos"
        },
        "summary": {
          "difficult": "Dificultad",
          "difficultRate": "Grado de dificultad",
          "portion": "Raciones",
          "preparationTime": "Tiempo de preparación",
          "totalTime": "Tiempo total"
        },
        "title": "Preparación"
      }
    }
  },
  "diet": {
    "cart": {
      "action": "Finalizar",
      "calendar": {
        "action": "Siguiente",
        "clue": "Selecciona los días para los que quieres comprar",
        "title": "Calendario compra"
      },
      "clue": "Selecciona los ingredientes que quieres incluir en tu lista de la compra",
      "empty": {
        "title": "No hay ingredientes que añadir a la lista de la compra"
      },
      "name": "Lista de la compra para mi menú semanal",
      "overwriteConflict": {
        "add": "Sólo añadir",
        "overwrite": "Reemplazar",
        "text": "Tienes elementos en tu lista de la compra, ¿Quieres reemplazarlos por los ingredientes seleccionados para tu menú?",
        "title": "Añadir o Sobreescribir"
      },
      "success": "Tu lista de la compra se ha actualizado con éxito",
      "title": "Compra menú semanal"
    },
    "custom": {
      "title": "Ingredientes"
    },
    "days": [
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
      "Domingo"
    ],
    "empty": "Este menú ya no está disponible",
    "emptyDay": "Aún no has añadido ningún plato {linebreak} para este día",
    "entry": {
      "added": " El plato se ha añadido con éxito",
      "changed": " El plato se ha actualizado con éxito",
      "day": {
        "description": "Selecciona los días de la semana para los que quieres planificar el plato",
        "title": "Día de la semana"
      },
      "description": "Selecciona el día de la semana, la comida del día y el plato exacto que quieres incluir en tu menú personalizado",
      "descriptionEdit": "Modifica o sustituye el plato que quieres incluir en tu menú personalizado",
      "edit": "Editar plato",
      "error": {
        "accept": "Aceptar",
        "not-day-selected": "Aún no has seleccionado ningún día",
        "not-meal-selected": "Aún no has seleccionado comida",
        "not-recipe-selected": "Aún no has añadido ningún plato"
      },
      "meal": {
        "description": "Selecciona la comida del día para la que quieres planificar el plato",
        "title": "Comida del día"
      },
      "recipe": {
        "amount": "Cantidad",
        "create": {
          "ingredient": {
            "add": "Añadir ingrediente",
            "save": "GUARDAR",
            "title": "Nombre del ingrediente"
          },
          "input": {
            "title": "Nombre del plato"
          },
          "more": {
            "clean": "Eliminar ingredientes",
            "delete": "Eliminar",
            "rename": "Renombrar plato"
          },
          "title": "Añadir manualmente"
        },
        "description": "Selecciona la receta de Vegamecum que quieras añadir a tu menú o añade un plato y sus ingredientes manualmente",
        "ingredients": "Ingredientes",
        "internet": {
          "input": {
            "cancel": "CANCELAR",
            "save": "IR"
          },
          "next": "Siguiente",
          "prev": "Anterior",
          "title": "Buscar en Internet",
          "url": "Introduce URL"
        },
        "nutrition": "Información nutricional",
        "selectedRecipe": "Esta es tu receta seleccionada",
        "title": "Plato",
        "vegamecum": {
          "headerIngredients": "Ingredientes",
          "headerRecipes": "Recetas",
          "placeholder": "Introduce el nombre",
          "title": "Buscar en Vegamecum"
        }
      },
      "title": "Añadir plato"
    },
    "import": {
      "button": "Guardar",
      "confirm": {
        "button": "GUARDAR"
      },
      "successfully": " Tu menú semanal se ha guardado con éxito",
      "text": "¿Seguro que quieres guardar este menú? Si lo guardas reemplazará el que ya hayas creado.",
      "title": "Guardar menú"
    },
    "meals": [
      "Desayuno",
      "Almuerzo",
      "Comida",
      "Merienda",
      "Cena"
    ],
    "menu": {
      "edit": "Editar plato",
      "remove": "Eliminar plato"
    },
    "more": {
      "addToCalendar": "Añadir a 'Mi calendario'",
      "addToCart": "Añadir a 'Mi lista de la compra'",
      "remove": "Vaciar menú",
      "share": "Compartir"
    },
    "nutritionalSummary": {
      "title": "Información Nutricional"
    },
    "planified": {
      "calendar": {
        "action": "Crear lista",
        "clue": "Selecciona los días para los que quieres diseñar tu lista de la compra",
        "title": "Planificar compra"
      },
      "empty": {
        "title": "Tu lista de la compra está vacía"
      },
      "success": "Se ha planificado correctamente"
    },
    "remove": {
      "successfully": " Tu menú semanal se ha vaciado con éxito"
    },
    "share": {
      "content": "Puedes encontrar mi menú semanal en https://vegamecum.com/app/share/diet/{{key}}",
      "title": "Menú semanal"
    },
    "title": "Mi menú semanal"
  },
  "donate": {
    "accept": "Me gustaría colaborar",
    "cancel": "Ahora no me es posible",
    "detail": {
      "button": "Donar vía PayPal",
      "link": "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=J9PGWKBAZ8WSJ&source=url",
      "text": "<h1>¡Muchas gracias por apoyar a Vegamecum!</h1><p>Con tu apoyo podemos seguir dando a conocer una manera de cocinar saludable y sostenible de forma gratuita y poniendo al alcance de todas las personas la tecnología que les permita realizar un cambio que proteja a los animales, al medio ambiente y su salud de la manera más fácil y cómoda.</p><p>La aplicación de Vegamecum es desarrollada y mantenida por sus creadores, con la única ayuda económica que aporta la publicidad, la cual es, lamentablemente, insuficiente para cubrir costes de servidor, dominio, desarrollo, materia prima, personal y los distintos gastos operativos derivados de la actividad.</p><p>Sólo con vuestra ayuda podemos mantener activa la aplicación de Vegamecum, desarrollar mejoras técnicas de manera continuada e innovar en funcionalidades y usabilidad para que la experiencia de usuario sea lo más completa, positiva y beneficiosa posible.</p><p>Toda ayuda, por pequeña que sea, es bienvenida. Si quieres apoyarnos, puedes hacernos llegar un donativo vía PayPal tocando el botón de donar.</p>"
    },
    "text": "Ayuda a que Vegamecum siga adelante"
  },
  "editRecipe": {
    "creating": "Creando receta...",
    "delete": "Eliminar",
    "imagePicker": {
      "errorPicking": "Error subiendo imagen",
      "fromCamera": "Hacer foto",
      "fromLibrary": "Elegir de mi biblioteca",
      "notYetUploaded": {
        "continue": "Interrumpir subida",
        "title": "Hay imágenes que aún no se han subido.",
        "wait": "Esperar a que se suban"
      },
      "title": "Elige una imagen"
    },
    "ingredients": {
      "add": {
        "title": "Añadir lista de ingredientes"
      },
      "block": {
        "placeholder": "Sin nombre",
        "title": "Nombre de la agrupación"
      },
      "description": "Añade, modifica o sustituye los ingredientes que quieres incluir en tu receta y, si quieres crear más de un grupo de ingredientes, dale un nombre a la agrupación",
      "list": {
        "title": "Ingredientes"
      },
      "title": "Editar ingredientes"
    },
    "introduction": {
      "categories": {
        "description": "Añade aquí la categoría o categorías a las que pertenece la receta",
        "empty": "Aún no has añadido ninguna categoría",
        "input": "Nombre de la categoría",
        "title": "Categorías"
      },
      "categoriesAndFilters": {
        "add": "Añadir",
        "description": "Indica a que categoria pertenece la receta y añade las etiquetas que quieras para que sea más fácil de encontrar",
        "title": "Editar categoria"
      },
      "description": "Describe las características principales de tu receta, como el sabor, la textura o su origen",
      "tags": {
        "description": "Añade aquí las etiquetas que mejor define tu receta",
        "empty": "Aún no has añadido ningún tag",
        "input": "Nombre de la etiqueta",
        "title": "Etiquetas"
      },
      "text": {
        "placeholder": "Descripción de la receta",
        "title": "Descripción de la receta"
      },
      "title": "Editar descripción"
    },
    "menu": {
      "delete": "Eliminar receta",
      "readMode": "Abrir en modo lectura"
    },
    "opening": "Abriendo modo edición...",
    "preparationStep": {
      "add": {
        "newTitle": "Paso {{number}}",
        "text": "",
        "title": "Añadir paso"
      },
      "block": {
        "placeholder": "Todos los ingredientes",
        "title": "Nombre del grupo"
      },
      "description": "Añade, modifica o sustituye los pasos necesarios para realizar tu receta. También puedes añadir temporizador, notas adicionales e incluso fotos para mostrar visualmente el paso a paso",
      "list": {
        "title": "Lista de ingredientes"
      },
      "name": {
        "title": "Nombre del paso"
      },
      "note": {
        "placeholder": "Nota adicional (opcional)",
        "title": "Nota adicional"
      },
      "pictures": {
        "description": "pictures description",
        "title": "Foto del paso"
      },
      "text": {
        "placeholder": "Descripción del paso",
        "title": "Descripción del paso"
      },
      "timer": {
        "switch": "Añadir temporizador",
        "text": "Tiempo",
        "title": "Temporizador"
      },
      "title": "Editar paso"
    },
    "recipeNumber": {
      "description": "Indica la cantidad resultante de tu receta en la unidad que mejor se adecúe al tipo de plato",
      "editor": {
        "number": {
          "placeholder": "Cantidad",
          "text": "Cantidad"
        },
        "title": "Cantidad",
        "unit": {
          "options": {
            "gr": "Gramos",
            "l": "Litros",
            "persona": "Personas",
            "porción": "Porciónes",
            "ración": "Raciones",
            "unidad": "Unidades"
          },
          "text": "Unidad"
        }
      },
      "title": "Editar raciones"
    },
    "recipeSummary": {
      "description": "Indica el grado de dificultad de tu receta y el tiempo medio de preparación",
      "difficult": {
        "options": {
          "average": "Media",
          "easy": "Fácil",
          "hard": "Difícil",
          "veryEasy": "Muy fácil"
        },
        "text": "Dificultad",
        "title": "Grado de dificultad"
      },
      "time": {
        "cooking": "Tiempo de preparación",
        "extra": {
          "motive": "Motivo",
          "switch": "Tiempo adicional",
          "text": "Tiempo adicional"
        },
        "title": "Tiempo",
        "total": {
          "text": "Tiempo total"
        }
      },
      "title": "Editar resumen"
    },
    "save": "Guardar",
    "title": {
      "description": "Da nombre a tu receta y añade las fotos que quieres mostrar",
      "name": {
        "placeholder": "Nombre de la receta",
        "title": "Nombre de la receta"
      },
      "pictures": {
        "title": "Fotos de la receta"
      },
      "title": "Editar nombre"
    }
  },
  "extras": {
    "description": "Aquí podrás obtener todos los extras que te permitirán ampliar tu experiencia en Vegamecum.",
    "purchaseButtonText": "{{price}} / {{months}} meses",
    "purchaseButtonYearText": "{{price}} / año",
    "restoreButtonText": "Restaurar compras",
    "rewardButtonText": "Prueba gratuita",
    "title": "Extras Vegamecum"
  },
  "favorites": {
    "empty": "Aún no tienes ninguna receta favorita {linebreak} Dale al icono {icon} que encontrarás en la parte superior de la receta para tenerla a mano",
    "search": {
      "headerIngredients": "Ingredientes",
      "headerRecipes": "Recetas",
      "placeholder": "Introduce el nombre"
    },
    "title": "Mis favoritas"
  },
  "filter": {
    "add": "+ Crear filtro",
    "create": {
      "categories": {
        "allergens": {
          "description": "¿Tienes alguna alergia o intoleráncia? Marca los alérgenos que quieres evitar",
          "gluten": "Gluten",
          "nuts": "Frutos secos",
          "soy": "Soja",
          "title": "Alérgenos"
        },
        "category": {
          "description": "Elige las categorías que te interesan. Si no eliges ninguna, se mostrarán todas",
          "title": "Categoría"
        },
        "difficult": {
          "description": "Desplaza la barra para ajustar la dificultad máxima de las recetas",
          "levels": {
            "0": "Sólo las más fáciles",
            "33": "Fáciles y dificultad media",
            "66": "Todas menos las más dificiles",
            "100": "Todas las recetas",
            "max": "Todas",
            "min": "Muy fáciles"
          },
          "title": "Dificultad"
        },
        "ingredients": {
          "add": "Añadir",
          "description": "Encuentra recetas según los ingredientes que quieres que contengan. También puedes indicar aquellos que quieres evitar",
          "excluded": {
            "description": "Añade aquí los ingredientes que quieres evitar en las recetas",
            "title": "Excluidos"
          },
          "included": {
            "description": "Añade aquí los ingredientes que quieres que contengan las recetas",
            "title": "Incluidos"
          },
          "placeholder": "Nombre ingrediente",
          "title": "Ingredientes"
        },
        "time": {
          "description": "Desplaza la barra para ajustar el tiempo máximo de preparación de las recetas",
          "levels": {
            "0": "< 5 min.",
            "10": "< 10 min.",
            "20": "< 15 min.",
            "30": "< 20 min.",
            "40": "< 30 min.",
            "50": "< 45 min.",
            "60": "< 1 h. ",
            "70": "< 1 h. y 30 min.",
            "80": "< 2 h.",
            "90": "< 2 h. y 30 min.",
            "100": "Todas las recetas",
            "max": "Todas",
            "min": "< 5 min."
          },
          "title": "Tiempo de preparación"
        }
      },
      "description": "Activa los siguientes filtros para encontrar tu receta perfecta",
      "save": {
        "button": "Guardar",
        "defaultName": "Filtro personalizado",
        "title": "Nombre del filtro"
      },
      "title": "Crea tu filtro"
    },
    "custom": "FILTRO PERSONALIZADO",
    "delete": "ELIMINAR",
    "edit": {
      "button": "EDITAR",
      "title": "Edita tu filtro"
    },
    "new": "NUEVO FILTRO",
    "special": "ESPECIAL",
    "title": "¿Qué te apetece?"
  },
  "ingredient": {
    "fraction": "{{whole}} y {{fraction}}"
  },
  "ingredientPicker": {
    "add": "AÑADIR",
    "addTitle": "Añadir ingrediente",
    "amount": {
      "text": "Cantidad",
      "title": "Cantidad",
      "unit": {
        "title": "Selecciona unidad",
        "withoutUnit": "sin unidad"
      }
    },
    "cancel": "CANCELAR",
    "delete": "ELIMINAR",
    "description": "Seleccina un ingrediente y la cantidad",
    "editTitle": "Editar ingrediente",
    "ingredient": {
      "placeholder": "Nombre del ingrediente",
      "title": "Ingrediente"
    },
    "name": "Nombre",
    "save": "GUARDAR",
    "searcher": {
      "ingredient": "Sugerencias",
      "newEntry": "Nuevo ingrediente",
      "placeholder": "Nombre del ingrediente"
    },
    "unit": "Unidad"
  },
  "input": {
    "cancel": "CANCELAR",
    "save": "GUARDAR"
  },
  "loading": {
    "title": "Toca para empezar"
  },
  "myRecipes": {
    "add": "Crear nueva",
    "empty": "Aún no tienes ninguna receta creada",
    "title": "Mis recetas"
  },
  "newRecipeEditor": {
    "title": "Crear nueva receta"
  },
  "notFound": {
    "page": {
      "subtitle": "Parece que no hay nada en esa ubicación ¿quieres probar a buscar?",
      "title": "¡Vaya! Esta página no se encuentra."
    },
    "searcher": {
      "subtitle": "Lo sentimos, pero no encaja nada con los términos de la búsqueda. Inténtalo de nuevo con algunas palabras clave diferentes.",
      "title": "Nada encontrado"
    }
  },
  "notifications": {
    "alamFinished": "¡Ya puedes retomar tu receta!",
    "alarmPlanified": "La alarma sonará a las {{date}}",
    "cartlistUpdate": "Tu lista de la compra se ha actualizado con éxito",
    "filterCreated": "Tu filtro se ha creado con éxito",
    "filterDeleted": "Tu filtro se ha eliminado con éxito",
    "filterUpdated": "Tu filtro se ha actualizado con éxito",
    "noNetwork": "No tienes conexión a internet",
    "recipePlanified": "Se ha planificado correctamente",
    "translationProposal": "Se ha enviado correctamente"
  },
  "nutrition": {
    "energy": "{{value}} kcal."
  },
  "playlist": {
    "controls": {
      "alarm": {
        "regex": "activar alarma",
        "text": "Activar alarma"
      },
      "finish": {
        "regex": "finalizar",
        "text": "Finalizar"
      },
      "next": {
        "regex": "siguiente",
        "text": "Siguiente paso"
      },
      "prev": {
        "regex": "anterior",
        "text": "Anterior paso"
      },
      "repeat": {
        "regex": "repetir",
        "text": "Repetir paso"
      }
    },
    "menu": {
      "close": "Cerrar",
      "disableVoiceControl": "Deshabilitar control por voz",
      "enableVoiceControl": "Habilitar control por voz",
      "openRecipe": "Ir a la receta"
    },
    "tip": "Un consejo Vegamecum."
  },
  "popover": {
    "moreOptions": "Más opciones"
  },
  "portal": {
    "bigTitle": "Inicio",
    "components": {
      "filter-list": {
        "seeMore": "Ver todos"
      },
      "next-diet-plates": {
        "seeMore": "Ver menú"
      }
    },
    "menu": "Menú",
    "results": "Resultados",
    "title": "Recetas"
  },
  "privacy": {
    "options": {
      "config": "Configurar",
      "yes": "Aceptar y cerrar"
    },
    "question": "Con el fin de proporcionar una experiencia de usuario más personalizada, hacemos uso, al igual que nuestros socios, de tecnologías como cookies para almacenar información o acceder a la información del dispositivo.",
    "showDetail": "Descubra cómo Vegamecum y nuestros socios de {{totalPartners}} recopilan y utilizan los datos",
    "showPrivacySection": "Cómo Vegamecum usa sus datos",
    "subtitle": "Valoramos tu privacidad y la seguridad de tus datos.",
    "title": "Al hacer clic en \"Aceptar\", aceptas estas tecnologías, que nos permitirán a nosotros y a nuestros socios procesar datos no confidenciales como la dirección IP, el identificador único y los datos de navegación con el fin de mostrarle contenido y anuncios personalizados, así como desarrollar y mejorar nuestros servicios."
  },
  "publish": {
    "actions": {
      "cancel": "Cancelar",
      "publish": "Publicar",
      "reclaim": "Reclamar",
      "unpublish": "Despublicar",
      "update": "Actualizar"
    },
    "status": {
      "published": {
        "description": "Publicada",
        "text": "Publicada"
      },
      "refused": {
        "description": "Rechazada",
        "text": "Rechazada"
      },
      "request": {
        "description": "Petición de revisión recibida",
        "text": "Pendiente de revision"
      },
      "revision": {
        "description": "La receta se está revisando",
        "text": "Bajo revisión"
      },
      "unpublished": {
        "description": "La receta aún no se ha publicado, dale para publicar",
        "text": "No publicada"
      }
    },
    "title": "Publicar"
  },
  "releaseNotes": {
    "close": "Cerrar",
    "next": "Siguiente",
    "previous": "Anterior"
  },
  "rewardedVideo": {
    "accept": "Ver ahora",
    "active": "¡Ahora puedes disfrutar de todos los extras durante 60 minutos!",
    "cancel": "No, gracias",
    "cancelForever": "No volver a mostrar",
    "remainingTime": "Quedan {{number}} minutos",
    "text": "Consigue acceso a todos los extras durante 60 minutos con sólo mirar este breve vídeo publicitario"
  },
  "searcher": {
    "boldText": "Resultados para {text}",
    "category": "Categoría: {{searchValue}}",
    "contains": "Buscar recetas con",
    "dynamicHeader": "{{header}} {text}",
    "empty": "No se han encontrado resultados",
    "entry": "Buscar recetas que empiecen por",
    "filter": "Filtro: {{searchValue}}",
    "last": "Últimas búsquedas",
    "results": "Resultados",
    "saytTitle": "Buscar con sugerencias",
    "suggested": "Sugerencias",
    "tags": "Etiqueta: {{searchValue}}",
    "text": "Resultados para {{searchValue}}",
    "title": "Busca por receta o ingrediente"
  },
  "sideBar": {
    "categories": "Categorías",
    "filters": "Filtros",
    "last": "Últimas recetas",
    "search": "Buscar ..."
  },
  "slowLoading": {
    "cancel": "CANCELAR",
    "description": "Parece que está tardando mucho en cargar, ¿quieres enviarnos un informe para que investiguemos qué está pasando?",
    "done": "¡Mensaje recibido! Nos ponemos a mirar qué puede estar pasando. Tambíén puedes contactar con nosotros vía mail a vegamecum@gmail.com para que nos cuentes tu caso.",
    "ok": "VALE",
    "send": "ENVIAR",
    "title": "¿Problemas de carga?"
  },
  "translate": {
    "original": {
      "title": "Texto original"
    },
    "save": "Guardar",
    "title": "Mejorar traducción",
    "translated": {
      "placeholder": "Introduce tu propuesta de traducción alternativa",
      "proposalSent": "",
      "seeOriginal": "Ver texto original",
      "title": "Traducción alternativa"
    }
  }
};