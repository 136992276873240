import { loadableReady } from '@loadable/component';
import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'regenerator-runtime/runtime';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Root from './containers/root';
import './index.scss';
import { unregister } from './registerServiceWorker';

if (process.env.NODE_ENV !== 'development') {
  function noop() {}
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

loadableReady(() => {
  ReactDOM.hydrate(<Root />, document.getElementById('page'));
});

unregister();
