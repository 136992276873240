/* @flow */

import produce from 'immer';
import { SETTINGS } from '../actions/settings';
import { changeTheme } from '../constants/colors';
import { setFontSizeFactor } from '../constants/styles';
import * as values from '../constants/values';
import I18n from '../i18n';
import { Notification, Orientation, Platform } from '../platform';

const data = (
  state: Object = {
    language: null,
    enableImproveTranslations: false,
    groupByRecipe: true,
    disableSoundTouchEffect: false,
    disableSoundAlarm: false,
    disableNotificationsNewRecipe: false,
    disableNotificationsAlarm: false,
    enableRotation: true,
    fontSize: 1,
    enableVoiceControl: false,
    measurementSystem: 'metric',
    theme: 'vegamecum.light',
    adsConsentStatus: null,
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case SETTINGS:
        if (Platform.OS === 'android') {
          if (action.data.disableNotificationsNewRecipe === true) {
            Notification.unsubscribeFromTopic(values.TOPIC_NEW_RECIPE);
          } else {
            Notification.subscribeToTopic(values.TOPIC_NEW_RECIPE);
          }
        }
        if (action.data.enableRotation === false) {
          Orientation.lockToPortrait();
        } else {
          Orientation.unlockAllOrientations();
        }
        if (action.data.language) {
          I18n.setLanguage(action.data.language);
        }
        if (action.data.fontSize) {
          setFontSizeFactor(action.data.fontSize);
        }
        if (action.data.theme) {
          changeTheme(action.data.theme);
        }
        return Object.assign({}, state, action.data);
    }
  });

export default data;
