/* @flow */

import * as types from '../actions/chat-room';
import _ from 'lodash';
import produce from 'immer';

const data = (
  state = {
    isLoading: false,
    hasPreviousMessages: false,
    messages: [],
    mostRated: [],
    votes: {}
  },
  action,
) =>
  produce(state, draft => {
    let messageIndex;
    switch (action.type) {
      case types.CLEAR_CHAT_ROOM:
        draft.isLoading = false;
        draft.hasPreviousMessages = [];
        draft.messages = [];
        draft.votes = {};
        return;
      case types.ADD_MESSAGE_TO_CHAT_ROOM:
        let index;
        let getLastIndex = () => {
          for (let index = draft.messages.length - 1; index >= 0; index--) {
            const message = draft.messages[index];
            if (action.data.message.priorityKey > message.priorityKey) {
              return index;
            }
          }
          return index;
        };
        let messageExistsIndex = draft.messages.findIndex(
          message => message._id === action.data.message._id,
        );
        index = getLastIndex();
        if (messageExistsIndex === -1) {
          draft.messages.splice(index + 1, 0, action.data.message);
        } else {
          draft.messages.splice(messageExistsIndex, 1, action.data.message);
        }
        return;
      case types.REMOVE_MESSAGE_TO_CHAT_ROOM:
        messageIndex = draft.messages.findIndex(
          message => message._id === action.data.message._id,
        );
        if (messageIndex >= 0) {
          draft.messages.splice(messageIndex, 1);
        }
        return;
      case types.CHANGE_MESSAGE_TO_CHAT_ROOM:
        messageIndex = draft.messages.findIndex(
          message => message._id === action.data.message._id,
        );
        if (messageIndex >= 0) {
          draft.messages.splice(messageIndex, 1, action.data.message);
        }  
        return;
      case types.CHANGE_VOTE_ON_CHAT_ROOM:
        draft.votes = action.data.message;
        return;
      case types.SET_MOST_RATED_MESSAGES_ON_CHAT_ROOM:
        draft.mostRated = action.data;
        return 
      case types.SET_LOADING_STATUS_TO_CHAT_ROOM:
        draft.isLoading = action.data;
        return;
      case types.SET_HAS_PREVIOUS_MESSAGES:
        draft.hasPreviousMessages = action.data;
        return;
    }
  });

export default data;
