import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Component from './component';
import { getItem, getWrapperPropsForParameters } from '../../selectors';

const makeMapStateToProps = () => {
  const getWrapperProps = getWrapperPropsForParameters();
  const mapStateToProps = (state, props) => {
    return {
      ...getWrapperProps(state, props),
      item: getItem(state, props),
    };
  };
  return mapStateToProps;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(makeMapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Component);
