/* @flow */

import * as types from '../actions/ratting';
import _ from 'lodash';
import produce from 'immer';

const data = (
  state = {
    isLoading: true,
    score: 0,
    users: 0,
    values: [0, 0, 0, 0, 0]
  },
  action,
) =>
  produce(state, draft => {
    let messageIndex;
    switch (action.type) {
      case types.CHANGE_RATTING:
        draft.isLoading = false;
        if(action.data) {
          draft.score = action.data.score;
          draft.users = action.data.users;
          draft.values = action.data.values;
        }
        return;
    }
  });

export default data;
