import firebaseAPI from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/remote-config';

export const AsyncStorage = {
  getItem: (key, cb) => {
    let item = localStorage.getItem(key);
    //let item = null;
    cb(false, item);
  },
  setItem: (key, value) => {
    localStorage.setItem(key, value);
  },
};
export const FCM = {
  unsubscribeFromTopic: () => {},
  subscribeToTopic: () => {},
};
export const CalendarEvents = {};
export const Orientation = {
  lockToPortrait: () => {},
  unlockAllOrientations: () => {},
};
export const DeviceInfo = {
  isTablet: () => {
    return false;
  },
  getVersion: () => {
    return 1;
  },
};
export const Dimensions = {
  get: (param) => {
    if (param === 'window') {
      return {
        width:
          typeof window !== 'undefined'
            ? window.innerWidth || document.body.clientWidth
            : 1280,
        height:
          typeof window !== 'undefined'
            ? window.innerHeight || document.body.clientHeight
            : 720,
      };
    }
  },
};

export { default as I18n } from 'i18n-js';

export const getLanguages = () => {
  return new Promise((resolve) => {
    resolve(['es', 'en']);
  });
};

export { default as View } from './components/view';
export { default as Text } from './components/text';
export { default as Image } from './components/image';

const firebaseConfig = {
  apiKey: 'AIzaSyB1_bf7EeTvCKY1NDPoq4d4_B0vtXdNYMg',
  authDomain: 'vegamecum-c057a.firebaseapp.com',
  databaseURL: 'https://vegamecum-c057a.firebaseio.com',
  projectId: 'vegamecum-c057a',
  storageBucket: 'vegamecum-c057a.appspot.com',
  messagingSenderId: '974649522138',
  appId: '1:974649522138:web:3bd51477f5ce4dd5e832e3',
  measurementId: 'G-7LZG4WE324',
};

firebaseAPI.initializeApp(firebaseConfig);
let Analytics;
if (typeof window !== 'undefined') {
  Analytics = firebaseAPI.analytics();
} else {
  Analytics = {
    setUserProperties: () => {},
    setCurrentScreen: () => {},
    logEvent: () => {},
  };
}

firebaseAPI.analytics = () => ({
  setUserId: () => {},
  setUserProperty: (k, v) => {
    Analytics.setUserProperties({ [k]: v });
  },
  setCurrentScreen: (screenName, options) => {
    Analytics.setCurrentScreen(screenName, options);
  },
  logEvent: (eventName, eventParams, options) => {
    Analytics.logEvent(eventName, eventParams, options);
  },
});

let remoteConfig;
if (typeof window !== 'undefined') {
  remoteConfig = firebaseAPI.remoteConfig();
  remoteConfig.settings = {
    fetchTimeoutMillis: 48000,
    minimumFetchIntervalMillis: 48000,
  };
} else {
  remoteConfig = {
    fetchAndActivate: () => {},
    getValue: () => {},
  };
}
firebaseAPI.config = () => ({
  setDefaults: (values) => {
    remoteConfig.defaultConfig = values;
  },
  fetch: (time) => {
    return remoteConfig.fetchAndActivate();
  },
  fetchAndActivate: () => {
    return remoteConfig.fetchAndActivate();
  },
  activateFetched: () => {},
  getValue: async (key) => {
    let value = await remoteConfig.getValue(key);
    return {
      val: () => value.asString(),
      asString: () => value.asString(),
    };
  },
});

firebaseAPI.admob = {};

export const firebase = firebaseAPI;

export const Notifications = {};

export const Notification = {
  subscribeToTopic: () => {}
};

export const Platform = { OS: 'web' };

export const LogError = (data) => {
  //console.log(data);
};
