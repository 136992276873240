/* @flow */

import {createStore, applyMiddleware} from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from '../reducers'

let middlewares = [thunkMiddleware]
/*
if(__DEV__){
	const createLogger = require("redux-logger");
	const loggerMiddleware = createLogger();
	middlewares.push(loggerMiddleware);
}
*/

const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore)

const configureStore = function(initialState: Object = {}): Function {
  return createStoreWithMiddleware(rootReducer, initialState)
}

export default configureStore
