/* @flow */

import * as types from '../actions'
import produce from 'immer'

const data = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.LOADED_PLANIFIED:
        return action.data
    }
  })

export default data
