/* @flow */

import * as types from '../actions'
import produce from 'immer'

const data = (
  state = {
    show: false,
    origin: {x: 0, y: 0, w: 0, h: 0},
    value: {},
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SHOW_POPOVER:
        draft.show = true
        draft.value = action.data.text
        draft.origin = action.data.origin
        return
      case types.HIDE_POPOVER:
        draft.show = false
        return
    }
  })

export default data
