/* @flow */

import * as types from '../actions/extras'
import produce from 'immer'
import {Platform} from '../platform';

const data = (
  state: Object = {
    list: {},
    loaded: false,
    enabled: true,
    restorationEnabled: (Platform.OS === 'android')
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case types.LOADED_EXTRAS:
        Object.keys(action.data).forEach(key => {
          draft[key] = action.data[key]
        })
        draft.loaded = true
        return;
      case types.CHANGE_ENABLE_RESTORATION:
        draft.restorationEnabled = action.data
        return;
    }
  })

export default data
