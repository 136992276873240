import {createSelector} from 'reselect';
import _ from 'lodash';

const REWARD_DURATION_IN_MS = 3600000;

const getUserInfo = (state, props) => _.get(state, 'user.info');

const getInfoReward = createSelector([getUserInfo], userInfo => {
  return _.get(userInfo, 'reward');
});

export const getRewardRemainingTime = createSelector([getInfoReward], infoReward => {
  return REWARD_DURATION_IN_MS - (infoReward.currentDate - infoReward.date);
});

export const isRewardExpired = createSelector([getRewardRemainingTime], remainingTime => {
  return remainingTime <= 0;
});

export const isRewardActive = createSelector(
  [isRewardExpired, getInfoReward],
  (isExpired, infoReward) => {
    if (!isExpired) {
      return infoReward.visualized;
    }
    return false;
  },
);

export const isNoShowActive = createSelector(
  [getInfoReward],
  (infoReward) => (infoReward && infoReward.noShow)
);


export const shouldShowRewardMessage = createSelector(
  [isRewardExpired, isNoShowActive],
  (isExpired, isNoShowActive) => {
    if(isNoShowActive) {
      return false;
    }
    return isExpired;
  },
);
