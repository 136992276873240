/* @flow */

import * as types from '../actions';
import _ from 'lodash';
import produce from 'immer';
import {AsyncStorage} from '../platform';

const AUTO_START_ACTIVE_MAX_TIME_MS = 28800000;

const data = (
  state = {
    quotes: [],
    active: null,
    isAutoStartActive: false,
    index: 0,
  },
  action,
) =>
  produce(state, draft => {
    switch (action.type) {
      case types.RECEIVE_QUOTES:
        draft.quotes = action.data;
        saveOnAsyncStorage(draft);
        return;
      case types.UPDATE_QUOTE_LAST_VISUALIZATION:
        let lastVisualizationDate = action.data || new Date().getTime();
        let isExpired =
          new Date().getTime() - lastVisualizationDate >
          AUTO_START_ACTIVE_MAX_TIME_MS;
        draft.isAutoStartActive = !isExpired;
        console.log('QUOTE', new Date().getTime() - lastVisualizationDate, AUTO_START_ACTIVE_MAX_TIME_MS);
        return;
      case types.UPDATE_QUOTE_INDEX:
        if (state.isAutoStartActive) {
          return;
        }
        let index;
        if (_.isNumber(action.data)) {
          index = action.data;
        } else {
          index = state.index < state.quotes.length - 1 ? state.index + 1 : 0;
        }
        let active = _.isArray(state.quotes)
          ? state.quotes[index] || state.quotes[0]
          : null;
        draft.index = index;
        draft.active = active;
        saveOnAsyncStorage(draft);
    }
  });

const saveOnAsyncStorage = data => {
  data.index = data.index < data.quotes.length - 1 ? data.index + 1 : 0;
  data.ts = new Date().getTime();
  AsyncStorage.setItem('VEGAN_QUOTES', JSON.stringify(data));
};

export default data;
