/* @flow */

import * as types from '../actions';
import produce from 'immer';
import _ from 'lodash';
import postList from './post-list';
import post from './post';

const data = (
  state = {
    lists: {
      favs: postList({}, {}),
      drafts: postList({}, {}),
    },
    all: {},
    measurementSystem: 'metric',
    allIngredientsUnits: {},
  },
  action,
) =>
  produce(state, draft => {
    let key;
    let all;
    let list;
    switch (action.type) {
      case types.CLEAR_POST_LIST:
        if (action.data.key === '*') {
          draft.lists = {
            favs: postList({}, {}),
            drafts: postList({}, {}),
          };
          draft.all = {};
          return;
        }
      case types.REMOVE_POST_FROM_LIST:
      case types.REQUEST_POST_LIST:
      case types.RECEIVE_POST_LIST:
        all = {};
        key = action.data.key;
        list = postList(state.lists[key], action);
        if (action.data.globalSave) {
          list.posts.forEach(p => {
            draft.all[p.slug] = post(
              produce(state.all[p.slug], nextState => {
                if (nextState) {
                  nextState.measurementSystem = state.measurementSystem;
                } else {
                  return {
                    measurementSystem: state.measurementSystem,
                  };
                }
              }),
              {
                type: types.LOADED_POST,
                data: {
                  post: p,
                },
              },
            );
          });
        }
        draft.lists[key] = list;
        return;
      case types.UPDATE_ALL_POSTS:
        Object.keys(state.all).forEach(k => {
          draft.all[k] = post(state.all[k], {
            type: action.childActionType,
            data: action.data,
          });
        });
        return;
      case types.RECEIVE_FIREBASE_DATA:
        state = Object.assign({}, state, {
          allIngredientsUnits: _.get(action.data, 'units.ingredients'),
        });
        return data(state, {
          type: types.UPDATE_ALL_POSTS,
          data: state.allIngredientsUnits,
          childActionType: types.RECEIVE_FIREBASE_DATA,
        });
      case types.SET_MEASUREMENT_SYSTEM:
        state = Object.assign({}, state, {
          measurementSystem: action.data,
        });
        return data(state, {
          type: types.UPDATE_ALL_POSTS,
          data: state.measurementSystem,
          childActionType: types.SET_MEASUREMENT_SYSTEM,
        });
      case types.RECEIVE_WORKING_POST:
      case types.UPDATE_FAV_STATE:
      case types.UPDATE_VO_POST_STATE:
      case types.UPDATE_FETCHING_STATE:
      case types.LOADED_POST:
        key = action.data.slug;
        draft.all[key] = post(
          produce(state.all[key], nextState => {
            if (nextState) {
              nextState.measurementSystem = state.measurementSystem;
              nextState.allIngredientsUnits = state.allIngredientsUnits;
            } else {
              return {
                measurementSystem: state.measurementSystem,
                allIngredientsUnits: state.allIngredientsUnits,
              };
            }
          }),
          action,
        );
        return;
    }
  });

export default data;
