/* @flow */

import * as types from '../actions'
import produce from 'immer'

const data = (
  state: Object = {
    list: {},
    show: false,
    active: {},
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case types.LOADED_ALARMS:
        draft.list = action.data
        return
      case types.SHOW_ALARM:
        draft.show = true
        draft.active = action.data
        return
      case types.HIDE_ALARM:
        draft.show = false
        return
    }
  })

export default data
