import * as firebaseActions from './firebase-actions';
import { firebase } from '../platform';
import { ADMOB_ID } from '../constants/values';

const { AdsConsent, AdsConsentStatus } = firebase.admob;
export const SETTINGS = 'SETTINGS';

export const setSettings = (data: Object): Object => {
  return {
    type: SETTINGS,
    data,
  };
};

export const setSettingProp = (prop: string, value: Object): Function => {
  return dispatch => {
    firebaseActions.setSettingProp(prop, value);
  };
};

export const updateAdsConsentStatus = status => {
  return async dispatch => {
    await AdsConsent.setStatus(status);
    //await AdsConsent.setStatus(AdsConsentStatus.UNKNOWN);
    dispatch(getAdsConsentStatus());
  };
};

export const getAdsConsentStatus = () => {
  return async dispatch => {
    const consentInfo = await AdsConsent.requestInfoUpdate([ADMOB_ID]);
    let status = consentInfo.isRequestLocationInEeaOrUnknown
      ? consentInfo.status
      : AdsConsentStatus.PERSONALIZED;
    //dispatch(setSettingProp('adsConsentStatus', AdsConsentStatus.UNKNOWN));
    dispatch(
      setSettings({
        adsConsentStatus: status,
      })
    );
  };
};
