/* @flow */

import * as types from '../actions'
import _ from 'lodash'
import produce from 'immer'
import dietDay from './diet-day'

const data = (
  state = {
    name: 'default',
    loaded: true,
    days: {},
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case types.LOADED_DIET:
        draft.days = processDays(action.data)
        return
    }
  })

const processDays = function(data) {
  let days = {}
  if (data && data.days) {
    _.each(data.days, (day, k) => {
      days[k] = dietDay(
        {},
        {
          type: types.LOADED_DIET,
          data: day,
        }
      )
    })
  }
  return days
}

export default data
