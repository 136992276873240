import { createSelector } from 'reselect';
import { isPremiumUser, isAdActive } from './extras';
import _ from 'lodash';
//const example = require('../../private/data-examples/portal/xmas.json');

const getPortal = (state, props) => state.portal;

const getOrderedList = createSelector(
  [getPortal, isAdActive, isPremiumUser],
  (portal, adActive, premiumUser) => {
    let list = portal.list;
    //let list = example;
    list = _.reduce(
      list,
      (memo, element) => {
        if (element.ad && !adActive) {
          return memo;
        }
        if (element.premium && !premiumUser) {
          return memo;
        }
        memo.push(element);
        return memo;
      },
      []
    );
    list = _.orderBy(list, 'priority', 'desc');
    return list;
  }
);

const getItemIndex = (state, props) => props.index;

export const getList = createSelector(
  [getOrderedList],
  list => {
    return _.map(list, ({ type }) => ({ type }));
  }
);

export const isLoading = createSelector(
  [getPortal],
  (portal) => {
    return portal.loaded === false;
  }
);

export const getItem = createSelector(
  [getOrderedList, getItemIndex],
  (list, index) => {
    return list[index];
  }
);

export const getWrapperPropsForParameters = () =>
  createSelector(
    [getItem],
    item => {
      if (item.type === 'next-diet-plates') {
        item.waitForReady === undefined ? true : item.waitForReady;
      }
      return {
        style: item.style,
        titleStyle: item.titleStyle,
        title: item.title,
        waitForReady: item.waitForReady,
        spaceBetweenProperty: item.spaceBetweenProperty,
        spaceBetweenSize: item.spaceBetweenSize,
      };
    }
  );
