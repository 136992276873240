import _ from 'lodash'

export const getFlattenIngredients = function(ingredients) {
  let flatten = {},
    ingredient,
    key
  _.each(ingredients, ingredient => {
    ingredient.selected = ingredient.selected || false
    key = ingredient.name + ingredient.selected
    ingredient.recipe = ingredient.recipe || {}
    if (!_.has(flatten, key)) {
      flatten[key] = _.clone(ingredient)
      flatten[key].recipeLinks = []
    } else {
      flatten[key].gramsValue += ingredient.gramsValue
    }
    flatten[key].selected = !!flatten[key].selected
    if (ingredient.recipe.slug) {
      flatten[key].recipeLinks.push({
        key: ingredient.key,
        slug: ingredient.recipe.slug,
        title: ingredient.recipe.title,
        blockIndex: ingredient.recipe.blockIndex,
        index: ingredient.recipe.index,
      })
    }
  })
  return _.values(flatten)
}
