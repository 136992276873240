/* @flow */

import * as types from '../actions/categories'

const data = (
  state = {
    list: [],
  },
  action
) => {
  switch (action.type) {
    case types.RECEIVE_CATEGORIES:
      return Object.assign({}, state, {
        list: action.data,
      })
    default:
      return state
  }
}

export default data
