/* @flow */

import * as types from '../actions'
import _ from 'lodash'
import produce from 'immer'

const data = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.RECEIVE_POST_LIST:
        let posts = produce(state, nextState => {
          let postIndex = -1
          action.data.posts.forEach(post => {
            postIndex = _.findIndex(state, {
              slug: post.slug,
            })
            if (postIndex !== -1) {
              nextState[postIndex] = post
            } else {
              nextState.push(post)
            }
          })
        })
        if (action.data.orderBy) {
          posts = _.orderBy(posts, action.data.orderBy)
          if (action.data.removeOld) {
            let last = action.data.posts[action.data.posts.length - 1]
            let lastIndex = _.indexOf(posts, last)
            if (lastIndex > -1) {
              posts = posts.slice(0, lastIndex + 1)
            }
          }
        }
        return _.uniqBy(posts, function(post) {
          return post.slug
        })
      case types.REMOVE_POST_FROM_LIST:
        let index = state.findIndex(post => {
          return post.slug === action.data.post.slug
        })
        if (index !== -1) {
          draft.splice(index, 1)
        }
        return
    }
  })

export default data
