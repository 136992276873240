/* @flow */

import * as types from '../actions/ratting';
import produce from 'immer';
import ratting from './ratting';

const data = (
  state = {
    list: {},
  },
  action,
) =>
  produce(state, draft => {
    let key;
    switch (action.type) {
      case types.CHANGE_RATTING:
        key = action.id;
        draft.list[key] = ratting(state.list[key], action);
        return;
    }
  });

export default data;
