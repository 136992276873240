/* @flow */

import * as types from '../actions';
import _ from 'lodash';
import produce from 'immer';

const data = (
  state = {
    show: false,
    text: '',
    searchingText: '',
    lastSearched: [],
    allSuggestions: {
      ingredients: [],
      lists: {
        portal: [],
      },
    },
    suggestions: {
      ingredients: {},
      lists: {
        portal: {},
      },
    },
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SET_SEARCHING_TEXT:
        draft.text = action.data;
        draft.searchingText = action.data;
        return;
      case types.FILTER_SEARCH_SUGGESTION:
        draft.searchingText = action.data;
        return;
      case types.CLEAR_SEARCHED:
        draft.lastSearched = [];
        return;
      case types.ADD_SEARCHED:
        draft.lastSearched = _.orderBy(
          [...state.lastSearched, action.data],
          ['time'],
          ['asc']
        );
        return;
      case types.REMOVE_SEARCHED:
        draft.lastSearched = _.filter(state.lastSearched, searched => {
          return searched.text !== action.data.text;
        });
        return;
      case types.SET_SEARCH_SUGGESTIONS:
        draft.allSuggestions = action.data;
        return;
    }
  });

export default data;
