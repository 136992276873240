/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import I18n from '../../i18n';

class RelatedPostsList extends Component {
  render() {
    return (
      <div
        id="jp-relatedposts"
        className="jp-relatedposts"
        style={{ display: 'block' }}
      >
        <h3 className="jp-relatedposts-headline">
          <em>{I18n.t('detail.related.list')}</em>
        </h3>
        {this._renderList()}
      </div>
    );
  }
  _renderList() {
    if (this.props.items.length === 0) {
      return this._renderSkeleton();
    }
    return (
      <div className="jp-relatedposts-items jp-relatedposts-items-minimal jp-relatedposts-grid ">
        {this.props.items.map(this._renderItem)}
      </div>
    );
  }
  _renderItem(item) {
    return (
      <p
        key={item.link}
        className="jp-relatedposts-post jp-relatedposts-post0"
        data-post-id="114"
        data-post-format="false"
      >
        <span className="jp-relatedposts-post-title">
          <Link
            className="jp-relatedposts-post-a"
            to={item.link}
            title={item.name}
            rel="nofollow"
            data-position="0"
          >
            {item.name}
          </Link>
        </span>
        <span className="jp-relatedposts-post-date">{item.date}</span>
        <span className="jp-relatedposts-post-context">{item.category}</span>
      </p>
    );
  }
  _renderSkeleton() {
    return (
      <ContentLoader width={840} height={125}>
        <rect width={840} height={125} y={5} />
      </ContentLoader>
    );
  }
}

RelatedPostsList.propTypes = {
  dispatch: PropTypes.func,
  items: PropTypes.array,
};

RelatedPostsList.defaultProps = {
  dispatch: () => {},
  items: [],
};

export default RelatedPostsList;
