/* @flow */

import * as types from '../actions'
import produce from 'immer'

const data = (
  state = {
    loading: false,
    list: {},
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case types.REQUEST_INGREDIENTS:
        draft.loading = true
        return
      case types.LOADED_INGREDIENTS:
        draft.loading = false
        draft.list = action.data
        return
    }
  })

export default data
