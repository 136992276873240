/* @flow */
/*eslint-disable prefer-const */

import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Slider from 'react-slick';
import './style.scss';
const useContainerStyles = makeStyles((theme) => ({
  root: (props) => ({
    transition: theme.transitions.create(['height'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    //height: props.open ? theme.spacing(66) : 0,
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: (props) => ({
      //height: props.open ? theme.spacing(40) : 0,
    }),
  }),
}));

function HorizontalList(props) {
  const classes = useContainerStyles(props);
  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    className: classes.slide,
  };
  const small = useMediaQuery('(max-width:800px)');
  const medium = useMediaQuery('(max-width:1600px)');
  if (small) {
    settings = {
      ...settings,
      slidesToShow: 2.5,
      slidesToScroll: 2,
    };
  } else if (medium) {
    settings = {
      ...settings,
      slidesToShow: 4,
      slidesToScroll: 4,
    };
  }
  return (
    <Grid
      container
      spacing={1}
      style={{ ...props.style, display: 'block', backgroundColor: 'blue' }}
    >
      <Slider {...settings}>{props.children}</Slider>
    </Grid>
  );
}

HorizontalList.propTypes = {};

HorizontalList.defaultProps = {};

export default HorizontalList;
