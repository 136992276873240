/* @flow */

import * as types from '../actions/bubbles'
import produce from 'immer'

const data = (
  state: Object = {
    list: {},
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case types.LOADED_BUBBLES:
        draft.list = action.data
        return
    }
  })

export default data
