/* @flow */
/*eslint-disable prefer-const */

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
  'overlayedContainer': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  'titleContainer': {
    animation: '$titleContainer 5s alternate',
    animationTimingFunction: 'linear',
    animationIterationCount: 2,
    animationFillMode: 'forwards',
    backgroundColor: 'rgba(255,255,255, 0.0)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  'title': {
    animation: '$title 5s alternate',
    animationIterationCount: 2,
    animationFillMode: 'forwards',
    transform: 'translate3d(0px, 100%, 0px)',
  },
  'titleWrapper': {
    overflow: 'hidden',
    paddingBottom: 5,
  },
  'divider': {
    animation: '$divider 5s alternate',
    animationIterationCount: 2,
    animationFillMode: 'forwards',
    backgroundColor: 'black',
    width: '0px',
    height: '5px',
  },
  'image': {
    width: '100%',
  },
  'imageContainer': {
    '&::after': {
      content: '""',
      boxShadow:
        'inset -50px -50px 75px 0 #FFFFFF, inset -50px -50px 75px 0 #FFFFFF, inset -50px -50px 75px 0 #FFFFFF, inset -50px -50px 75px 0 #FFFFFF',
      display: 'block',
      height: '100%',
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  },
  '@keyframes titleContainer': {
    '0%': { backgroundColor: 'rgba(255,255,255, 0.0)' },
    '30%': { backgroundColor: 'rgba(255,255,255, 0.0)' },
    '40%': { backgroundColor: 'rgba(255,255,255, 0.6)' },
    '80%': { backgroundColor: 'rgba(255,255,255, 0.6)' },
    '100%': { backgroundColor: 'rgba(255,255,255, 0.6)' },
  },
  '@keyframes title': {
    '0%': { transform: 'translate3d(0px, 100%, 0px)' },
    '60%': { transform: 'translate3d(0px, 100%, 0px)' },
    '80%': { transform: 'translate3d(0px, 0px, 0px)' },
    '100%': { transform: 'translate3d(0px, 0px, 0px)' },
  },
  '@keyframes divider': {
    '0%': { width: '0px' },
    '40%': { width: '0px' },
    '60%': { width: '100%' },
    '100%': { width: '100%' },
  },
});

function PostAnimatedTitle(props) {
  const classes = useStyles();
  return (
    <Box
      component={'header'}
      className={clsx(classes.overlayedContainer, classes.titleContainer)}
    >
      <Box component={'div'} className={classes.titleWrapper}>
        <Typography variant="h1" className={classes.title}>
          {props.title}
        </Typography>
      </Box>
      <Box component={'div'} className={classes.divider}></Box>
    </Box>
  );
}

function PostAnimatedImage(props) {
  const classes = useStyles();
  const _getMainImage = () => {
    let figure;
    if (props.figures) {
      let figureIndex = props.figures.findIndex((figure) => {
        return figure.main;
      });
      figureIndex = figureIndex !== -1 ? figureIndex : 0;
      figure = {
        ...props.figures[figureIndex],
        index: figureIndex,
      };
    }
    return figure
      ? figure
      : {
          path: props.mainImage || '',
          caption: '',
        };
  };
  let figure = _getMainImage();
  if (figure) {
    return (
      <Box component={'div'} className={classes.imageContainer}>
        <img className={classes.image} src={figure.path} />
      </Box>
    );
  }
}

function PostFeatured(props) {
  return (
    <>
      <Grid item xs={8}>
        <Box component={'main'} display={'flex'}>
          <Box component={'article'} position={'relative'} flex={1}>
            <PostAnimatedImage {...props} />
            <PostAnimatedTitle {...props} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h2">{props.title}</Typography>
        <Typography variant="body1">{props.highlightedText}</Typography>
      </Grid>
    </>
  );
}

PostFeatured.propTypes = {};

PostFeatured.defaultProps = {};

export default PostFeatured;
