import { AsyncStorage, firebase, LogError } from '../platform';
import _ from 'lodash';
import hash from '../utils/hash';

const firebaseConfig = {
  databaseURL: 'https://vegamecum-c057a.firebaseio.com',
};

var dataByPath = {};

var listenCallbacks = {};

const firebaseLogError = text => {
  LogError(text);
};

export const getValueOnceFromPath = (
  path,
  cb,
  key,
  times,
  consolidationTimeout,
  cache
) => {
  let ref = firebase.database().ref(path);
  getValueOnceFromRef(ref, cb, key, times, consolidationTimeout, cache);
};

export const getValueOnceFromRef = (
  ref,
  cb,
  key,
  times,
  consolidationTimeout,
  cache
) => {
  var key = key || getKeyFromRef(ref);
  //console.log('getValueOnceFromRef: '+key+ ' ---- times: '+times + '-----> '+getKeyFromRef(ref));
  if (dataByPath[key]) {
    cb(dataByPath[key]);
  } else {
    listenToKey(ref, key, cb, times, consolidationTimeout, cache);
  }
};

export const listenValueFromPath = async (path, cb) => {
  let previousCacheKey = null;
  getCacheMap(async cacheMap => {
    let newCacheKey = cacheMap['/cache' + path];
    if (previousCacheKey !== newCacheKey) {
      previousCacheKey = newCacheKey;
      let value = await getValueFromPath(path);
      cb(value);
    }
  });
};

export const getValueFromPath = async (
  path,
  cb = null,
  progress = () => {}
) => {
  return new Promise(async (resolve, reject) => {
    cb = cb || resolve;
    console.log('getValueFromPath--> ' + path);
    progress({
      status: '0',
      percent: 10,
    });
    let result = await getPathValueFromCache(path);
    progress({
      status: '1',
      percent: 30,
    });
    if (result) {
      result = result.data;
      console.log('FROM CACHE ' + path);
      progress({
        status: '2',
        percent: 100,
      });
      cb({
        val: () => {
          return result;
        },
      });
    } else {
      console.log('NOT CACHE ' + path);
      progress({
        status: '3 ' + path,
        percent: 50,
      });
      let fetchTimeout = setTimeout(() => {
        firebaseLogError('timeout fetching path: ' + path);
      }, 10000);
      getValueOnceFromPath(
        path,
        value => {
          clearTimeout(fetchTimeout);
          progress({
            status: '4',
            percent: 100,
          });
          cb(value);
        },
        null,
        null,
        null,
        true
      );
    }
  });
};

export const getValueFromRef = async (ref, progress = () => {}) => {
  return new Promise(async (resolve, reject) => {
    const key = getKeyFromRef(ref);
    console.log('getValueFromPath--> ' + key);
    progress({
      status: '0',
      percent: 10,
    });
    let result = await getPathValueFromCache(key);
    progress({
      status: '1',
      percent: 30,
    });
    if (result) {
      result = result.data;
      console.log('FROM CACHE ' + key);
      progress({
        status: '2',
        percent: 100,
      });
      resolve({
        val: () => {
          return result;
        },
      });
    } else {
      console.log('NOT CACHE ' + key);
      progress({
        status: '3 ' + key,
        percent: 50,
      });
      let fetchTimeout = setTimeout(() => {
        firebaseLogError('timeout fetching path: ' + key);
      }, 10000);
      getValueOnceFromRef(
        ref,
        value => {
          clearTimeout(fetchTimeout);
          progress({
            status: '4',
            percent: 100,
          });
          resolve(value);
        },
        null,
        null,
        null,
        true
      );
    }
  });
};

export const stopListeningKey = key => {
  if (listenCallbacks[key]) {
    let listener = listenCallbacks[key];
    //console.log('stoplistenToKey: '+key);
    listener.ref.off('value', listener.callback);
    listenCallbacks[key] = null;
    dataByPath[key] = null;
  }
};

const listenToKey = (
  ref,
  key,
  cb,
  times = 1,
  consolidationTimeout = 0,
  cache = false
) => {
  let firstTime = true;
  let callbackTimeout;
  let callback = snapshot => {
    //console.log('callback listenToKey: '+key);
    //ref.off('value', callback);
    dataByPath[key] = snapshot;
    if (cache) {
      saveItemForPath(key, snapshot.val());
    }
    clearTimeout(callbackTimeout);
    if (firstTime && consolidationTimeout) {
      firstTime = false;
      callbackTimeout = setTimeout(() => {
        callback(snapshot);
      }, consolidationTimeout);
      return;
    }
    if (cb) {
      cb(dataByPath[key]);
      times--;
      //console.log('listenToKey: '+key + ' times: '+times);
      if (times <= 0) {
        cb = null;
      }
    }
  };
  //console.log('listening '+_.keys(listenCallbacks).length);
  listenCallbacks[key] = {
    ref,
    callback,
  };
  ref.on('value', callback);
};

const getKeyFromRef = ref => {
  if (ref._query) {
    return _.reduce(
      ref._query.modifiers,
      (nemo, filter) => {
        return nemo + '|' + filter.id;
      },
      ref.path
    );
  } else {
    return ref.path.toString();
  }
};

let cacheMap = null;
let cacheLoading = false;
let cachePromises = [];

const getFlattenCacheMap = (obj, map, path, validKeysRegex) => {
  map = map || {};
  path = path || [];
  _.each(obj, (v, k) => {
    k = path.concat([k]);
    if (_.isObject(v) || _.isArray(v)) {
      getFlattenCacheMap(v, map, k, validKeysRegex);
    } else {
      k = k.join('/');
      if (!validKeysRegex || validKeysRegex.test(k)) {
        map[k] = v;
      }
    }
  });
  return map;
};

const getCacheMap = listen => {
  let promise = new Promise((resolve, reject) => {
    let cb = listen || resolve;
    if (!cacheMap || listen) {
      cachePromises.push({ callback: cb, listen });
    }
    if (!cacheMap) {
      if (!cacheLoading) {
        cacheLoading = true;
        let ref = firebase.database().ref('/cache');
        let callback = snapshot => {
          cacheMap = getFlattenCacheMap({
            '/cache': snapshot.val(),
          });
          cachePromises = _.filter(cachePromises, ({ callback, listen }) => {
            console.log('update');
            callback(cacheMap);
            return listen;
          });
        };
        ref.on('value', callback);
      }
    } else {
      cb(cacheMap);
    }
  });
  return promise;
};

const getCacheKeyForPath = async path => {
  let cacheMap = await getCacheMap();
  return cacheMap['/cache' + path];
};

const getPathValueFromCache = path => {
  var promise = new Promise((resolve, reject) => {
    let timeout = setTimeout(() => {
      firebaseLogError('error getPathValueFromCache from path: ' + path);
      resolve(null);
    }, 5000);
    getCacheKeyForPath(path).then(cacheKey => {
      clearTimeout(timeout);
      console.log('cacheKey: ', cacheKey, path);
      if (cacheKey) {
        AsyncStorage.getItem(path, (err, result) => {
          if (result) {
            result = JSON.parse(result);
            console.log(path, result.cacheKey, '----cacheKey---', cacheKey);
            let hasBeenRead = cacheKey === '___empty___' || result.data;
            if (result.cacheKey === cacheKey && hasBeenRead) {
              return resolve(result);
            }
          }
          resolve(null);
        });
      } else {
        resolve(null);
      }
    });
  });
  return promise;
};

const saveItemForPath = (path, val) => {
  console.log('saveItemForPath', path, hash.digest(val));
  if (path.indexOf('/') !== 0) {
    path = `/${path}`;
  }
  AsyncStorage.setItem(
    path,
    JSON.stringify({
      data: val,
      cacheKey: hash.digest(val),
    }),
    error => {
      if (error) {
        //console.log('error---> '+error);
      }
    }
  );
};
