/* @flow */

import * as types from '../actions';
import _ from 'lodash';
import produce from 'immer';
import {mergeWorkingWithRaw} from '../utils/post-merger';

const data = (
  state = {
    working: {},
    raw: null,
    merged: null,
    loading: false,
    loadingStatus: 1,
    loadingPercent: 1,
    error: false,
    fav: false,
    isVOEnabled: false,
    hasVO: false,
    measurementSystem: 'metric',
    allIngredientsUnits: {},
  },
  action,
) =>
  produce(state, draft => {
    switch (action.type) {
      case types.UPDATE_VO_POST_STATE:
        draft.merged = mergeWorkingWithRaw(
          state.working,
          state.raw,
          state.measurementSystem,
          action.data.state,
          state.allIngredientsUnits,
        );
        draft.isVOEnabled = action.data.state;
        return;
      case types.UPDATE_FETCHING_STATE:
        draft.error = action.data.state === 'error';
        draft.loading = action.data.state === 'loading';
        draft.loadingStatus = action.data.loadingStatus;
        draft.loadingPercent = action.data.loadingPercent;
        return;
      case types.UPDATE_FAV_STATE:
        draft.fav = action.data.state;
        return;
      case types.LOADED_POST:
        if (action.data.post && action.data.post.summary) {
          draft.raw = action.data.post;
          draft.hasVO = action.data.post.__vo;
          draft.merged = mergeWorkingWithRaw(
            state.working,
            action.data.post,
            state.measurementSystem,
            state.isVOEnabled,
            state.allIngredientsUnits,
          );
          draft.loading = false;
          draft.error = false;
        } else {
          return undefined;
        }
        return;
      case types.RECEIVE_WORKING_POST:
        draft.working = action.data.post;
        draft.merged = mergeWorkingWithRaw(
          action.data.post,
          state.raw,
          state.measurementSystem,
          state.isVOEnabled,
          state.allIngredientsUnits,
        );
        return;
      case types.SET_MEASUREMENT_SYSTEM:
        draft.measurementSystem = action.data;
        draft.merged = mergeWorkingWithRaw(
          state.working,
          state.raw,
          action.data,
          state.isVOEnabled,
          state.allIngredientsUnits,
        );
        return;
      case types.RECEIVE_FIREBASE_DATA:
        return;
    }
  });

export default data;
