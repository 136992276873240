/* @flow */
/*global setTimeout*/

import _ from 'lodash';
import { PostParser } from '../utils/post-parser';
import { USDAParser } from '../utils/usda-parser';
import * as firebaseActions from './firebase-actions';
import * as Sounds from '../constants/sounds';
import Quotes from '../constants/quotes';
import I18n from '../i18n';
import * as Extras from './extras';
import * as ReleaseNotes from './release-notes';
import { setSettingProp, setSettings } from './settings';
import * as Bubbles from './bubbles';
import * as CurrentRecipes from './current-recipes';
import { MEASUREMENT_SYSTEM } from '../utils/ingredient-parser';

import { AsyncStorage, LogError } from '../platform';

export const ACTIVE_APP = 'ACTIVE_APP';
export const UPDATE_APP_LOADING = 'UPDATE_APP_LOADING';
export const CAN_PLAY_AD = 'CAN_PLAY_AD';
export const CHANGE_ORIENTATION = 'CHANGE_ORIENTATION';
export const CHANGE_ONLINE_STATUS = 'CHANGE_ONLINE_STATUS';
export const CHANGE_SIZE = 'CHANGE_SIZE';
export const CHANGE_HEADER_SIZE = 'CHANGE_HEADER_SIZE';
export const HIDE_ALARM = 'HIDE_ALARM';
export const HIDE_MENU = 'HIDE_MENU';
export const HIDE_POPOVER = 'HIDE_POPOVER';
export const HIDE_TOAST = 'HIDE_TOAST';
export const INITIALIZED = 'INITIALIZED';
export const LOADED_ALARMS = 'LOADED_ALARMS';
export const CLEAR_CART = 'CLEAR_CART';
export const ADD_ELEMENT_TO_CART = 'ADD_ELEMENT_TO_CART';
export const REMOVE_ELEMENT_FROM_CART = 'REMOVE_ELEMENT_FROM_CART';
export const LOADED_INGREDIENTS = 'LOADED_INGREDIENTS';
export const LOADED_PLANIFIED = 'LOADED_PLANIFIED';
export const LOADED_DIET = 'LOADED_DIET';
export const RECEIVE_DIETS_INFO = 'RECEIVE_DIETS_INFO';
export const TOGGLE_CART_ELEMENTS_SELECTED_VISIBILITY =
  'TOGGLE_CART_ELEMENTS_SELECTED_VISIBILITY';
export const ADD_SEARCHED = 'ADD_SEARCHED';
export const REMOVE_SEARCHED = 'REMOVE_SEARCHED';
export const CLEAR_SEARCHED = 'CLEAR_SEARCHED';
export const LOADED_POST = 'LOADED_POST';
export const CLEAR_POST_LIST = 'CLEAR_POST_LIST';
export const REQUEST_POST_LIST = 'REQUEST_POST_LIST';
export const RECEIVE_POST_LIST = 'RECEIVE_POST_LIST';
export const REMOVE_POST_FROM_LIST = 'REMOVE_POST_FROM_LIST';
export const RECEIVE_FILTERS = 'RECEIVE_FILTERS';
export const RECEIVE_FIREBASE_DATA = 'RECEIVE_FIREBASE_DATA';
export const RECEIVE_NUTRIENT_LIST = 'RECEIVE_NUTRIENT_LIST';
export const RECEIVE_PLAYLIST = 'RECEIVE_PLAYLIST';
export const RECEIVE_QUOTES = 'RECEIVE_QUOTES';
export const UPDATE_QUOTE_INDEX = 'UPDATE_QUOTE_INDEX';
export const UPDATE_QUOTE_LAST_VISUALIZATION =
  'UPDATE_QUOTE_LAST_VISUALIZATION';
export const RECEIVE_TEXT_SPEECH_BEAUTIFIERS =
  'RECEIVE_TEXT_SPEECH_BEAUTIFIERS';
export const RECEIVE_USER_INFO = 'RECEIVE_USER_INFO';
export const RECEIVE_WORKING_POSTS = 'RECEIVE_WORKING_POSTS';
export const RECEIVE_WORKING_POST = 'RECEIVE_WORKING_POST';
export const REQUEST_INGREDIENTS = 'REQUEST_INGREDIENTS';
export const UPDATE_ALL_POSTS = 'UPDATE_ALL_POSTS';
export const REQUEST_DATA = 'REQUEST_DATA';
export const REQUEST_DATA_FINISHED = 'REQUEST_DATA_FINISHED';
export const RESET_STORE = 'RESET_STORE';
export const UPDATE_FETCHING_STATE = 'UPDATE_FETCHING_STATE';
export const UPDATE_FAV_STATE = 'UPDATE_FAV_STATE';
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';
export const SET_PLAYLIST_AUTOPLAY = 'SET_PLAYLIST_AUTOPLAY';
export const SET_SEARCHING_TEXT = 'SET_SEARCHING_TEXT';
export const SET_SEARCH_SUGGESTIONS = 'SET_SEARCH_SUGGESTIONS';
export const SET_CART_UNITS = 'SET_CART_UNITS';
export const FILTER_SEARCH_SUGGESTION = 'FILTER_SEARCH_SUGGESTION';
export const SET_USER = 'SET_USER';
export const UPDATE_VO_POST_STATE = 'UPDATE_VO_POST_STATE';
export const SET_MEASUREMENT_SYSTEM = 'SET_MEASUREMENT_SYSTEM';
export const SHOW_ALARM = 'SHOW_ALARM';
export const SHOW_MENU = 'SHOW_MENU';
export const SHOW_POPOVER = 'SHOW_POPOVER';
export const SHOW_TOAST = 'SHOW_TOAST';
export const SET_ROUTER_STATE = 'SET_ROUTER_STATE';
export const SET_APP_NAVIGATOR = 'SET_APP_NAVIGATOR';
export const NAVIGATE = 'Navigation/NAVIGATE';
export const NAVIGATE_BACK = 'Navigation/BACK';
export const NAVIGATE_RESET = 'Navigation/RESET';
export const NAVIGATE_SET_PARAMS = 'Navigation/SET_PARAMS';

export const initialized = (): Object => {
  return {
    type: INITIALIZED,
  };
};

export const resetStore = (): Object => {
  return {
    type: RESET_STORE,
  };
};

export const receiveFirebaseData = (data: Object): Object => {
  return {
    type: RECEIVE_FIREBASE_DATA,
    data,
  };
};

export const receiveNutrientList = (data: Object): Object => {
  return {
    type: RECEIVE_NUTRIENT_LIST,
    data,
  };
};

export const receiveDietsInfo = (data: Object): Object => {
  return {
    type: RECEIVE_DIETS_INFO,
    data,
  };
};

export const receiveQuotes = (data: Object): Object => {
  return {
    type: RECEIVE_QUOTES,
    data,
  };
};

export const updateQuoteIndex = (data: Object): Object => {
  return {
    type: UPDATE_QUOTE_INDEX,
    data,
  };
};

export const updateQuotesLastVisualization = (data: Object): Object => {
  return {
    type: UPDATE_QUOTE_LAST_VISUALIZATION,
    data,
  };
};

export const changeOnlineStatus = (data: boolean): Object => {
  return {
    type: CHANGE_ONLINE_STATUS,
    data,
  };
};

export const receiveFilters = (data: Object): Object => {
  return {
    type: RECEIVE_FILTERS,
    data,
  };
};

export const receiveUserInfo = (data: Object): Object => {
  return {
    type: RECEIVE_USER_INFO,
    data,
  };
};

export const setLoginStatus = (data: Object): Object => {
  return {
    type: SET_LOGIN_STATUS,
    data,
  };
};

export const setUser = (data: Object): Object => {
  return {
    type: SET_USER,
    data,
  };
};

export const changeOrientation = (data: Object): Object => {
  return {
    type: CHANGE_ORIENTATION,
    data,
  };
};

export const changeSize = (data: Object): Object => {
  return {
    type: CHANGE_SIZE,
    data,
  };
};

export const changeHeaderSize = (data: Object): Object => {
  return {
    type: CHANGE_HEADER_SIZE,
    data,
  };
};

let visualizedDetails = 0;
export const detailVisualized = (data: Object): Object => {
  let canPlayAds = false;
  if (visualizedDetails === 0) {
    visualizedDetails = -3;
    canPlayAds = true;
  }
  visualizedDetails++;
  return {
    type: CAN_PLAY_AD,
    data: canPlayAds,
  };
};

export const updateFetchingState = (data: Object): Object => {
  return {
    type: UPDATE_FETCHING_STATE,
    data,
  };
};

export const updateFavState = (data: Object): Object => {
  return {
    type: UPDATE_FAV_STATE,
    data,
  };
};

export const requestData = (): Object => {
  return {
    type: REQUEST_DATA,
  };
};

export const requestDataFinished = (): Object => {
  return {
    type: REQUEST_DATA_FINISHED,
  };
};

export const clearPostList = (data: Object): Object => {
  return {
    type: CLEAR_POST_LIST,
    data,
  };
};

export const clearSearched = (data: Object): Object => {
  return {
    type: CLEAR_SEARCHED,
    data,
  };
};

export const requestPostList = (data: Object): Object => {
  return {
    type: REQUEST_POST_LIST,
    data,
  };
};

export const receivePostList = (data: Object): Object => {
  return {
    type: RECEIVE_POST_LIST,
    data,
  };
};

export const removePostFromList = (data: Object): Object => {
  return {
    type: REMOVE_POST_FROM_LIST,
    data,
  };
};

export const receiveWorkingPosts = (data: Object): Object => {
  return {
    type: RECEIVE_WORKING_POSTS,
    data,
  };
};

export const receiveWorkingPost = (data: Object): Object => {
  return {
    type: RECEIVE_WORKING_POST,
    data,
  };
};

export const receiveTextSpeechBeautifiers = (data: Object): Object => {
  return {
    type: RECEIVE_TEXT_SPEECH_BEAUTIFIERS,
    data,
  };
};

export const receivePlaylist = (data: Object): Object => {
  return {
    type: RECEIVE_PLAYLIST,
    data,
  };
};

export const loadedPost = (data: Object): Object => {
  return {
    type: LOADED_POST,
    data,
  };
};

export const requestIngredients = (): Object => {
  return {
    type: REQUEST_INGREDIENTS,
  };
};

export const loadedIngredients = (data: Object): Object => {
  return {
    type: LOADED_INGREDIENTS,
    data,
  };
};

export const loadedAlarms = (data: Object): Object => {
  return {
    type: LOADED_ALARMS,
    data,
  };
};

export const loadedPlanified = (data: Object): Object => {
  return {
    type: LOADED_PLANIFIED,
    data,
  };
};

export const addSearched = (data: Object): Object => {
  return {
    type: ADD_SEARCHED,
    data,
  };
};

export const removeSearched = (data: Object): Object => {
  return {
    type: REMOVE_SEARCHED,
    data,
  };
};

export const setSearchSuggestions = (data: Object): Object => {
  return {
    type: SET_SEARCH_SUGGESTIONS,
    data,
  };
};

export const filterSearchSuggestions = (data: Object): Object => {
  return {
    type: FILTER_SEARCH_SUGGESTION,
    data,
  };
};

export const setPlaylistAutoplay = (data: Object): Object => {
  return {
    type: SET_PLAYLIST_AUTOPLAY,
    data,
  };
};

export const setMeasurementSystem = (data: Object): Object => {
  return {
    type: SET_MEASUREMENT_SYSTEM,
    data,
  };
};

export const clearCart = (cartId: string): Object => {
  return {
    type: CLEAR_CART,
    cartId,
  };
};

export const addElementToCart = (data: Object, cartId: string): Object => {
  return {
    type: ADD_ELEMENT_TO_CART,
    cartId,
    data,
  };
};

export const removeElementFromCart = (data: Object, cartId: string): Object => {
  return {
    type: REMOVE_ELEMENT_FROM_CART,
    cartId,
    data,
  };
};

export const toggleCheckStepOnPost = (
  post: Object,
  stepIndex: number,
  state: boolean
): Object => {
  return (dispatch) => {
    firebaseActions.toggleCheckStepOnPost(post, stepIndex, state);
  };
};

export const setPersonToRecipe = (postSlug: string, number: number): Object => {
  return (dispatch) => {
    firebaseActions.setPersonToRecipe(postSlug, number);
  };
};

export const showAlarm = (data: Object): Object => {
  return {
    type: SHOW_ALARM,
    data,
  };
};

export const hideAlarm = (data: Object): Object => {
  return {
    type: HIDE_ALARM,
    data,
  };
};

export const loadedDiet = (data: Object): Object => {
  return {
    type: LOADED_DIET,
    data,
  };
};

export const setSearchingText = (data: Object): Object => {
  return {
    type: SET_SEARCHING_TEXT,
    data,
  };
};

export const activeApp = (data: Object): Object => {
  return {
    type: ACTIVE_APP,
  };
};

export const updateAppLoading = (data: Object): Object => {
  return {
    type: UPDATE_APP_LOADING,
    data,
  };
};

export const showToast = (data: Object): Object => {
  return {
    type: SHOW_TOAST,
    data,
  };
};

export const hideToast = (text: string): Object => {
  return {
    type: HIDE_TOAST,
  };
};

export const showPopOver = (origin: Object, text: Object): Object => {
  return {
    type: SHOW_POPOVER,
    data: {
      origin,
      text,
    },
  };
};

export const hidePopOver = (): Object => {
  return {
    type: HIDE_POPOVER,
  };
};

export const toggleCartElementsSelectedVisibility = (
  data: Object,
  cartId: string
): Object => {
  return {
    type: TOGGLE_CART_ELEMENTS_SELECTED_VISIBILITY,
    cartId,
    data,
  };
};

export const updateVOPostState = (data: Object): Object => {
  return {
    type: UPDATE_VO_POST_STATE,
    data,
  };
};

export const showMenu = (): Object => {
  return {
    type: SHOW_MENU,
  };
};

export const hideMenu = (): Object => {
  return {
    type: HIDE_MENU,
  };
};

export const setOnlineStatus = (connected: boolean): Function => {
  return (dispatch) => {
    dispatch(changeOnlineStatus(connected));
  };
};

export const refreshRecipeFavs = (): Function => {
  return (dispatch) => {
    dispatch(requestData());
    firebaseActions.refreshRecipeFavs(() => {
      dispatch(requestDataFinished());
    });
  };
};

export const refreshAllPostOnPortal = (): Function => {
  return (dispatch) => {
    dispatch(requestData());
    firebaseActions.refreshAllPostOnPortal(() => {
      dispatch(requestDataFinished());
    });
  };
};

export const refreshSuggestions = (): Function => {
  return (dispatch) => {
    dispatch(requestData());
    firebaseActions.refreshSuggestions(() => {
      dispatch(requestDataFinished());
    });
  };
};

export const searchByText = (text) => {
  return (dispatch) => {
    let filter = _.compact(
      _.map(text.split(' '), (word) => {
        word = word.replace(/á|é|í|ó|ú/gi, '?').replace(/:/g, '');
        return word;
      })
    ).join(' AND ');
    filter =
      '(ingredients.list.name:' + filter + '*) OR (title:' + filter + '*)';
    dispatch(fetchData(['search', { searchParam: filter }], 'portal', true));
    dispatch(setSearchingText(text));
    dispatch(
      saveSearch({
        text: text,
        time: -Date.now(),
      })
    );
  };
};

export const sendPush = (data: Object): Function => {
  return (dispatch) => {
    firebaseActions.sendPush(data);
  };
};

export const importRecipe = (
  recipe: string,
  asIngredient: boolean
): Function => {
  return (dispatch) => {
    dispatch(requestData());
    let action = asIngredient
      ? firebaseActions.importRecipeAsIngredient
      : firebaseActions.importRecipe;
    action(recipe).then(() => {
      dispatch(requestDataFinished());
    });
  };
};

export const addRecipeToPortal = (slug: string): Function => {
  return (dispatch) => {
    dispatch(requestData());
    firebaseActions.addRecipeToPortal(slug, () => {
      dispatch(requestDataFinished());
    });
  };
};

export const migratePostImage = (slug: string): Function => {
  return (dispatch) => {
    dispatch(requestData());
    firebaseActions.migratePostImage(slug, () => {
      dispatch(requestDataFinished());
    });
  };
};

export const translatePost = (language: string, slug: string): Function => {
  return (dispatch) => {
    dispatch(requestData());
    firebaseActions.translatePost(language, slug, () => {
      dispatch(requestDataFinished());
    });
  };
};

let firebaseData = {};
let alarms = {};
let planified;
let playlist = [];
let settings = {};
let isInitialized = false;

const initializeFirebase = (dispatch): Function => {
  firebaseActions.initialize(async (user) => {
    let mustLoadPieces = {
      initialize: false, //
      quotes: false, //
      userInfo: false, //
      filters: false, //
      playlist: false, //
      alarms: false, //
      nutrientList: false, //
      planified: false, //
      dietInfo: false, //
      searchSuggestions: false, //
      settings: false,
    };
    let yetLoaded = false;
    let loadedPiece = (piece) => {
      mustLoadPieces = {
        ...mustLoadPieces,
        [piece]: true,
      };
      let values = _.values(mustLoadPieces);
      let progress = _.reduce(
        values,
        (prev, value) => {
          return prev + (value ? 1 / values.length : 0);
        },
        0
      );
      console.log(
        _.indexOf(values, false) + ' --- ' + String(yetLoaded) + ' --- ' + piece
      );
      let allLoaded = _.indexOf(values, false) === -1;
      dispatch(
        updateAppLoading({
          loadingPercent: Math.min(progress * 100, 100),
          loadingMessage: mustLoadPieces,
        })
      );
      if (allLoaded && !yetLoaded) {
        yetLoaded = true;
        dispatch(Extras.fetch());
        dispatch(Bubbles.fetch());
        dispatch(initialized());
        dispatch(requestDataFinished());
      }
    };

    initializeDataStore(dispatch);
    await initializeLanguage(user, dispatch);
    dispatch(requestData());
    dispatch(setUser(user));
    firebaseActions.onUserProfileUpdate((user) => {
      dispatch(setUser(user));
    });
    firebaseData.units = (await firebaseActions.getUnits()).val();
    dispatch(receiveFirebaseData(firebaseData));
    if (user.admin) {
      dispatch(fetchIngredients());
    }
    let killQuotesTimeout = setTimeout(() => {
      loadedPiece('quotes');
    }, 5000);

    AsyncStorage.getItem('VEGAN_QUOTES', (err: any, result?: Object) => {
      console.log('VEGAN_QUOTES');
      if (result) {
        try {
          result = JSON.parse(result);
        } catch (e) {}
        if (result) {
          if (!result.quotes || !_.isNumber(result.index)) {
            result = null;
          }
        }
      }
      if (!result) {
        result = {
          index: 0,
          quotes: Quotes,
          ts: new Date().getTime(),
        };
      }
      console.log('receiveQuotes');
      dispatch(updateQuotesLastVisualization(result.ts));
      dispatch(receiveQuotes(result.quotes));
      dispatch(updateQuoteIndex(result.index));

      firebaseActions.getQuotes().then((snapshot) => {
        dispatch(receiveQuotes(snapshot.val()));
        loadedPiece('quotes');
        clearTimeout(killQuotesTimeout);
      });
    });

    firebaseActions.getNutrientList().then((snapshot) => {
      firebaseData.nutrientList = snapshot.val();
      dispatch(receiveFirebaseData(firebaseData));
      dispatch(receiveNutrientList(firebaseData.nutrientList));
      loadedPiece('nutrientList');
    });
    firebaseActions.getDietsInfo().then((snapshot) => {
      dispatch(receiveDietsInfo(snapshot.val()));
      loadedPiece('dietInfo');
    });
    firebaseActions.getUserInfo((snapshot) => {
      dispatch(receiveUserInfo(snapshot.val()));
      loadedPiece('userInfo');
    });
    firebaseActions.getFilters((snapshot) => {
      dispatch(receiveFilters(snapshot.val()));
      loadedPiece('filters');
    });
    firebaseActions.getTextSpeechBeautifiers().then((snapshot) => {
      let beautifiers = _.map(snapshot.val(), (beautifier) => {
        return [new RegExp(beautifier.regex, 'gi'), beautifier.value];
      });
      dispatch(receiveTextSpeechBeautifiers(beautifiers));
    });
    firebaseActions.getWorkingPosts(function (action, snapshot) {
      switch (action) {
        case 'remove':
          dispatch(
            receiveWorkingPost({
              slug: snapshot.key,
              post: null,
            })
          );
          break;
        case 'change':
        case 'add':
          dispatch(
            receiveWorkingPost({
              slug: snapshot.key,
              post: snapshot.val(),
            })
          );
          break;
        // no default
      }
    });
    firebaseActions.getFavs((action, snapshot) => {
      let post = snapshot.val();
      switch (action) {
        case 'add':
          dispatch(
            receivePostList({
              key: 'favs',
              posts: [post],
              nextPage: null,
              orderBy: ['priorityKey'],
            })
          );
          dispatch(
            updateFavState({
              slug: post.slug,
              state: true,
            })
          );
          break;
        case 'remove':
          dispatch(
            removePostFromList({
              key: 'favs',
              post: post,
            })
          );
          dispatch(
            updateFavState({
              slug: post.slug,
              state: false,
            })
          );
          break;
        // no default
      }
    });
    firebaseActions.getPlaylist((snapshot) => {
      playlist = _.map(snapshot.val(), (step, key) => {
        return step;
      });
      dispatch(receivePlaylist(playlist));
      loadedPiece('playlist');
    });
    firebaseActions.getAlarms((snapshot) => {
      let maxDate = new Date().getTime() + 86400000;
      alarms = {};
      _.each(snapshot.val(), (alarm) => {
        if (alarm.alarmDate < maxDate) {
          alarms[alarm.id] = alarm;
        }
      });
      dispatch(loadedAlarms(alarms));
      loadedPiece('alarms');
    });
    firebaseActions.getPlanified((snapshot) => {
      planified = snapshot.val();
      dispatch(loadedPlanified(planified));
      loadedPiece('planified');
    });
    /*
        firebaseActions.getDiet((snapshot) => {
            dispatch(loadedDiet(snapshot.val()));
            loadedPiece('diet');
        });
        */
    firebaseActions.getLastSearched((action, snapshot) => {
      let searched = snapshot.val();
      switch (action) {
        case 'add':
          dispatch(addSearched(searched));
          break;
        case 'remove':
          dispatch(removeSearched(searched));
          break;
        // no default
      }
    });
    firebaseActions.getSearchSuggestions((snapshot) => {
      dispatch(setSearchSuggestions(snapshot.val()));
      loadedPiece('searchSuggestions');
    });
    firebaseActions.getSettings((snapshot) => {
      settings = snapshot.val();
      if (settings) {
        if (settings.disableSoundTouchEffect) {
          Sounds.disableEffects();
        } else {
          Sounds.enableEffects();
        }
        if (settings.disableSoundAlarm) {
          Sounds.disableAlarm();
        } else {
          Sounds.enableAlarm();
        }
      } else {
        settings = {
          language: I18n.getSelectedLanguage(),
          enableImproveTranslations: false,
          groupByRecipe: true,
          disableSoundTouchEffect: false,
          disableSoundAlarm: false,
          disableNotificationsNewRecipe: false,
          disableNotificationsAlarm: false,
          enableLinkedCalendar: false,
          measurementSystem: MEASUREMENT_SYSTEM.METRIC,
          enableRotation: true,
          fontSize: 1,
          enableVoiceControl: false,
          theme: 'vegamecum.light',
          adsConsentStatus: null,
        };
      }
      if (!settings.measurementSystem) {
        settings.measurementSystem = settings.enableScaleLess
          ? MEASUREMENT_SYSTEM.SCALELESS
          : MEASUREMENT_SYSTEM.METRIC;
      }
      dispatch(setSettings(settings));
      dispatch(setMeasurementSystem(settings.measurementSystem));
      loadedPiece('settings');
    });
    dispatch(getCart('default'));
    loadedPiece('initialize');
    dispatch(ReleaseNotes.fetch());
  });
};

export const fetchInitialData = (): Function => {
  return (dispatch, getState) => {
    if (isInitialized) {
      return;
    }
    isInitialized = true;
    dispatch(requestData());
    activeCheckAlarms(dispatch);
    I18n.initialize().then(() => {
      initializeFirebase(dispatch);
    });
  };
};

export const showNextQuote = (): Function => {
  return (dispatch) => {
    dispatch(updateQuoteIndex());
  };
};

const initializeDataStore = function (dispatch) {
  /*
  dispatch(
    clearPostList({
      key: '*',
    })
  );
  */
  dispatch(clearSearched());
  dispatch(clearCart('default'));
};

const initializeLanguage = async function (user, dispatch) {
  if (I18n.isLanguageAvailable(user.language)) {
    dispatch(
      setSettings({
        language: user.language,
      })
    );
    Promise.resolve();
  } else {
    I18n.getLanguages().then((languages) => {
      let hasSpanishAtFirst =
        languages && languages.length > 0 && languages[0].match(/^(es|ca)/i);
      if (hasSpanishAtFirst) {
        dispatch(
          setSettings({
            language: 'es',
          })
        );
        Promise.resolve();
        return;
      }
      dispatch(
        showPopOver(
          {},
          {
            type: 'language',
            centered: true,
            hideOnTouchBackdrop: false,
            onLanguageChange: (language) => {
              dispatch(setSettingProp('language', language));
            },
            onConfirmed: (settings) => {
              dispatch(
                setSettings({
                  language: settings.language,
                  enableImproveTranslations: settings.improveTranslations,
                })
              );
              Promise.resolve();
            },
          }
        )
      );
    });
  }
};

const activeCheckAlarms = function (dispatch) {
  let actualDate;
  setInterval(() => {
    actualDate = new Date().getTime();
    if (alarms) {
      _.each(alarms, (alarm) => {
        if (actualDate > alarm.alarmDate) {
          firebaseActions.removeAlarm(alarm);
          dispatch(showAlarm(alarm));
        }
      });
    }
  }, 1000);
};

export const planifyEvent = (event: Object, time: Date): Function => {
  return (dispatch) => {
    firebaseActions.planifyEvent(event, time);
  };
};

export const planifyRecipe = (recipe: Object, time: Date): Function => {
  return (dispatch) => {
    firebaseActions.planifyRecipe(recipe, time);
  };
};

export const cancelPlanification = (id: string): Function => {
  return (dispatch) => {
    firebaseActions.cancelPlanification(id);
  };
};

export const setRecipeFinished = (recipe: Object): Function => {
  return (dispatch) => {
    firebaseActions.setRecipeFinished(recipe);
  };
};

export const sendMessageToSupport = (msg: string): Function => {
  return (dispatch) => {
    firebaseActions.sendMessageToSupport(msg);
  };
};

export const loginWithEmail = (email: string, password: string): Function => {
  return (dispatch) => {
    dispatch(
      setLoginStatus({
        status: 3,
      })
    );
    firebaseActions.loginWithEmail(
      email,
      password,
      (success: boolean, obj: Object) => {
        dispatch(
          setLoginStatus({
            status: success ? 1 : 0,
            obj,
          })
        );
      }
    );
  };
};

export const loginWithFacebookToken = (token: string): Function => {
  return (dispatch) => {
    console.log('loginWithFacebookToken');
    dispatch(
      setLoginStatus({
        status: 3,
      })
    );
    firebaseActions.loginWithFacebookToken(
      token,
      (success: boolean, obj: Object) => {
        console.log('loginWithFacebookToken success? ' + String(success));
        console.log(obj);
        dispatch(
          setLoginStatus({
            status: success ? 1 : 0,
            obj,
          })
        );
      }
    );
  };
};

export const loginWithGoogleToken = (token: string): Function => {
  return async (dispatch) => {
    dispatch(
      setLoginStatus({
        status: 3,
      })
    );
    try {
      let { user } = await firebaseActions.loginWithGoogleToken(token);
      if (user) {
        return dispatch(
          setLoginStatus({
            status: 1,
            obj: {
              code: 'login-successfully',
              name: user.displayName,
            },
          })
        );
      }
    } catch (e) {
      console.log('error', error);
    }
    dispatch(
      setLoginStatus({
        status: 0,
        obj: null,
      })
    );
  };
};

export const loginWithAppleToken = (token, nonce): Function => {
  return async (dispatch) => {
    dispatch(
      setLoginStatus({
        status: 3,
      })
    );
    try {
      let { user } = await firebaseActions.loginWithAppleToken(token, nonce);
      if (user) {
        return dispatch(
          setLoginStatus({
            status: 1,
            obj: {
              code: 'login-successfully',
              name: user.displayName,
            },
          })
        );
      }
    } catch (e) {
      console.log('error login', e);
    }
    dispatch(
      setLoginStatus({
        status: 0,
        obj: null,
      })
    );
  };
};

export const forceRemoteConfigRefresh = async (): Function => {
  return await firebaseActions.forceRemoteConfigRefresh();
};

export const logout = (token: string): Function => {
  return (dispatch) => {
    dispatch(
      setLoginStatus({
        status: 3,
      })
    );
    firebaseActions.logout(() => {
      dispatch(
        setLoginStatus({
          status: 0,
        })
      );
    });
  };
};

export const sendTranslationProposal = (object: Object): Function => {
  return (dispatch) => {
    firebaseActions.sendTranslationProposal(object);
  };
};

export const updateTranslation = (object: Object): Function => {
  return (dispatch) => {
    firebaseActions.updateTranslation(object);
    dispatch(loadPostBySlug(object.slug));
  };
};

export const modifyUserData = (
  email: string,
  password: string,
  newpassword: string,
  data: Object
): Function => {
  return (dispatch) => {
    dispatch(
      setLoginStatus({
        status: 3,
      })
    );
    firebaseActions.modifyUserData(
      email,
      password,
      newpassword,
      data,
      (success, obj) => {
        dispatch(
          setLoginStatus({
            status: success ? 1 : 0,
            obj,
          })
        );
      }
    );
  };
};

export const modifyThirdPartyUserData = (data: Object): Function => {
  return (dispatch) => {
    dispatch(
      setLoginStatus({
        status: 3,
      })
    );
    firebaseActions.modifyThirdPartyUserData(
      data,
      (success: boolean, obj: Object) => {
        dispatch(
          setLoginStatus({
            status: success ? 1 : 0,
            obj,
          })
        );
      }
    );
  };
};

export const setDonateAction = (accept: boolean): Function => {
  return (dispatch) => {
    firebaseActions.setDonateAction(accept);
  };
};

export const linkEmailToUser = (
  email: string,
  password: string,
  data: Object
): Function => {
  return (dispatch) => {
    dispatch(
      setLoginStatus({
        status: 3,
      })
    );
    firebaseActions.linkEmailToUser(
      email,
      password,
      data,
      (success: boolean, obj: Object) => {
        dispatch(
          setLoginStatus({
            status: success ? 1 : 0,
            obj,
          })
        );
      }
    );
  };
};

export const unLinkEmailToUser = (): Function => {
  return (dispatch) => {
    dispatch(
      setLoginStatus({
        status: 3,
      })
    );
    firebaseActions.logout(() => {
      dispatch(
        setLoginStatus({
          status: 0,
        })
      );
    });
  };
};

export const deleteEmailUser = (password: string): Function => {
  return (dispatch) => {
    dispatch(
      setLoginStatus({
        status: 3,
      })
    );
    firebaseActions.deleteEmailUser(
      password,
      (success: boolean, obj: Object) => {
        dispatch(
          setLoginStatus({
            status: success ? 1 : 0,
            obj,
          })
        );
      }
    );
  };
};

export const deleteFacebookUser = (token: string): Function => {
  return (dispatch) => {
    dispatch(
      setLoginStatus({
        status: 3,
      })
    );
    firebaseActions.deleteFacebookUser(
      token,
      (success: boolean, obj: Object) => {
        dispatch(
          setLoginStatus({
            status: success ? 1 : 0,
            obj,
          })
        );
      }
    );
  };
};

export const saveFilter = (filter: Object): Function => {
  return (dispatch) => {
    firebaseActions.saveFilter(filter);
  };
};

export const removeFilter = (filter: Object): Function => {
  return (dispatch) => {
    firebaseActions.removeFilter(filter);
  };
};

export const saveDraft = (draft: Object): Function => {
  return (dispatch) => {
    firebaseActions.saveDraft(draft);
  };
};

export const saveSearch = (search: Object): Function => {
  return (dispatch) => {
    firebaseActions.saveSearch(search);
  };
};

export const deleteSearch = (search: Object): Function => {
  return (dispatch) => {
    firebaseActions.deleteSearch(search);
  };
};

export const setVOPostState = (data: Object): Function => {
  return (dispatch) => {
    dispatch(
      updateVOPostState({
        slug: data.post.slug,
        state: data.state,
      })
    );
  };
};

export const addFav = (recipe: Object): Function => {
  return (dispatch) => {
    firebaseActions.addFav(recipe);
  };
};

export const removeFav = (recipe: Object): Function => {
  return (dispatch) => {
    firebaseActions.removeFav(recipe);
  };
};

export const resetAccount = (): Function => {
  return (dispatch) => {
    firebaseActions.resetAccount();
  };
};

export const getCart = (cartId: string = 'default'): Function => {
  return (dispatch) => {
    firebaseActions.getCart((action: string, snapshot: Object) => {
      let entry = snapshot.val(),
        key = snapshot.key;
      switch (action) {
        case 'change':
        case 'add':
          dispatch(
            addElementToCart(
              {
                key: snapshot.key,
                entry: snapshot.val(),
              },
              cartId
            )
          );
          break;
        case 'remove':
          dispatch(
            removeElementFromCart(
              {
                key: snapshot.key,
                entry: snapshot.val(),
              },
              cartId
            )
          );
          break;
      }
    }, cartId);
  };
};

export const addIngredientToCart = (
  recipe: Object,
  ingredient: Object,
  blockIndex: number,
  index: number,
  cartId: string = 'default'
): Function => {
  return (dispatch) => {
    firebaseActions.addIngredientToCart(
      recipe,
      ingredient,
      blockIndex,
      index,
      cartId
    );
  };
};

export const addNewIngredientToCart = (
  ingredient: Object,
  cartId: string = 'default',
  recipe: Object
): Function => {
  return (dispatch) => {
    firebaseActions.addNewIngredientToCart(ingredient, cartId, recipe);
  };
};

export const checkIngredientFromCart = (
  slug: string,
  ingredient: Object,
  blockIndex: number,
  index: number,
  status: boolean,
  cartId: string = 'default'
): Function => {
  return (dispatch) => {
    firebaseActions.checkIngredientFromCart(
      slug,
      ingredient,
      blockIndex,
      index,
      status,
      cartId
    );
  };
};

export const checkIngredientsFromCart = (
  list: Array<Object>,
  status: boolean,
  cartId: string = 'default'
): Function => {
  return (dispatch) => {
    firebaseActions.checkIngredientsFromCart(list, status, cartId);
  };
};

export const removeIngredientFromCart = (
  slug: string,
  ingredient: Object,
  blockIndex: number,
  index: number,
  cartId: string = 'default'
): Function => {
  return (dispatch) => {
    firebaseActions.removeIngredientFromCart(
      slug,
      ingredient,
      blockIndex,
      index,
      cartId
    );
  };
};

export const removeAllIngredientsFromCart = (
  cartId: string = 'default'
): Function => {
  return (dispatch) => {
    firebaseActions.removeAllIngredientsFromCart(cartId);
    dispatch(clearCart(cartId));
  };
};

export const updateIngredientInCart = (
  key: string,
  object: Object,
  override: boolean,
  cartId: string = 'default'
): Function => {
  return (dispatch) => {
    firebaseActions.updateIngredientInCart(key, object, override, cartId);
  };
};

export const resetDiet = (): Function => {
  return (dispatch) => {
    firebaseActions.resetDiet();
  };
};

export const importDiet = (id: string): Function => {
  return (dispatch) => {
    firebaseActions.importDiet(id);
  };
};

export const getDiet = (id: string, shared: boolean): Function => {
  return (dispatch) => {
    firebaseActions.getDiet(id, shared, (snapshot: Object) => {
      dispatch(
        loadedDiet({
          key: id,
          ...snapshot.val(),
        })
      );
    });
  };
};

export const shareDiet = (): Function => {
  return (dispatch) => {
    return firebaseActions.shareDiet();
  };
};

export const addPlateToMeal = (
  day: string,
  meal: Object,
  plate: Object
): Function => {
  return (dispatch) => {
    firebaseActions.addPlateToMeal(day, meal, plate);
  };
};

export const editPlateOnMeal = (
  day: string,
  meal: string,
  id: string,
  plate: Object
): Function => {
  return (dispatch) => {
    firebaseActions.editPlateOnMeal(day, meal, id, plate);
  };
};

export const removePlateFromMeal = (
  day: string,
  meal: string,
  id: string
): Function => {
  return (dispatch) => {
    firebaseActions.removePlateFromMeal(day, meal, id);
  };
};

export const selectPlaylistPosition = (position: number): Function => {
  return (dispatch) => {
    playlist = _.map(playlist, (step, i) => {
      return {
        ...step,
        active: i === position,
      };
    });
    firebaseActions.setPlaylist(playlist);
  };
};

export const clearPlaylist = (): Function => {
  return (dispatch) => {
    playlist = [];
    firebaseActions.setPlaylist(playlist);
  };
};

export const addToPlaylist = (recipe: Object): Function => {
  return (dispatch) => {
    playlist = _.map(recipe.preparation, (step) => {
      return {
        recipeTitle: recipe.title,
        slug: recipe.slug,
        mainImage: recipe.mainImage,
        ...step,
      };
    });
    dispatch(setPlaylistAutoplay(true));
    firebaseActions.setPlaylist(playlist);
  };
};

export const createAlarm = (data: Object): Function => {
  return (dispatch) => {
    firebaseActions.createAlarm(data);
  };
};

export const cancelAlarm = (data: Object): Function => {
  return (dispatch) => {
    if (data) {
      firebaseActions.removeAlarm(data);
    }
    dispatch(hideAlarm(data));
  };
};

export const fetchIngredients = (): Function => {
  return (dispatch) => {
    dispatch(requestIngredients());
    firebaseActions.getIngredients((ingredients) => {
      firebaseData.ingredients = ingredients;
      dispatch(receiveFirebaseData(firebaseData));
      dispatch(loadedIngredients(ingredients));
    });
  };
};

export const loadPostBySlug = (slug: string): Function => {
  return loadPost({
    slug,
  });
};

export const loadPost = ({ slug }): Function => {
  return (dispatch, getState) => {
    dispatch(
      updateFetchingState({
        slug,
        loadingStatus: '1',
        loadingPercent: 40,
        state: 'loading',
      })
    );
    firebaseActions.getRecipe(
      {
        slug,
      },
      (snapshot) => {
        dispatch(
          updateFetchingState({
            slug,
            part: 1,
            loadingStatus: '2',
            loadingPercent: 90,
          })
        );
        var response = snapshot.val();
        let state = 'error';
        if (response && response.summary) {
          dispatch(
            loadedPost({
              slug: slug,
              post: response,
              loadingStatus: '3',
              loadingPercent: 100,
            })
          );
          state = 'finished';
        }
        dispatch(
          updateFetchingState({
            slug,
            state,
          })
        );
      },
      (progressFirebase) => {
        dispatch(
          updateFetchingState({
            slug,
            loadingStatus: '1.' + progressFirebase.status,
            loadingPercent: 40 + (progressFirebase.percent / 100) * 50,
          })
        );
      }
    );
  };
};

export const loadUSDAData = (slug: string, code: string): Function => {
  return (dispatch) => {
    dispatch(requestData());
    const url = 'https://fdc.nal.usda.gov/portal-data/external/' + code;
    fetch(url)
      .then((response) => response.json())
      .then(
        (response) => {
          let parsed = {};
          response.foodNutrients.forEach((foodNutrient) => {
            if (foodNutrient && foodNutrient.value) {
              if (
                foodNutrient.nutrient.name === 'Energy' &&
                foodNutrient.nutrient.nutrientUnit.name === 'kJ'
              ) {
                //discarted
              } else {
                parsed[foodNutrient.nutrient.name] = foodNutrient.value;
              }
            }
          });
          //let parsed = USDAParser(response);
          firebaseActions
            .setIngredientNutrients(slug, code, parsed)
            .then(() => {
              dispatch(fetchIngredients());
            });
        },
        () => {
          dispatch(requestDataFinished());
        }
      );
  };
};

export const updateIngredientUnit = (
  ingredientName: string,
  unitName: string,
  unitValue: Object
): Function => {
  return (dispatch) => {
    //dispatch(requestData());
    firebaseActions
      .setIngredientUnit(ingredientName, unitName, unitValue)
      .then(() => {
        //dispatch(fetchIngredients());
      });
  };
};

export const fetchData = (
  params: Array<Object>,
  key: string,
  mustRefresh: boolean
): Function => {
  return (dispatch) => {
    if (key === 'favs') {
    } else if (key === 'drafts') {
      dispatch(fetchDataFB(params, key, mustRefresh));
      dispatch(
        receivePostList({
          key: key,
          posts: [],
        })
      );
    } else if (key === 'planified') {
    } else if (key === 'wp') {
      dispatch(fetchDataWP(params, key, mustRefresh));
    } else {
      dispatch(fetchDataFB(params, key, mustRefresh));
    }
  };
};

export const fetchDataFB = (
  params: Array<Object>,
  key: string,
  mustRefresh: boolean
): Function => {
  return async (dispatch, getState) => {
    if (mustRefresh) {
      dispatch(
        clearPostList({
          key: key,
        })
      );
    }
    let query = '*';
    let action = params[0];
    let actionParams = params[1] || {};
    dispatch(
      requestPostList({
        key: key,
      })
    );
    if (action === 'search' && actionParams.searchParam) {
      let response = await firebaseActions.search(
        actionParams.searchParam,
        actionParams.next
      );
      if (response.hits) {
        let hits = response.hits
          ? response.hits.map((hit) => {
              return hit._source;
            })
          : [];
        console.log('response.nextCall', response.nextCall, response);
        dispatch(
          receivePostList({
            key: key,
            posts: hits,
            filters: params,
            nextPage: response.nextCall
              ? [
                  {
                    searchParam: actionParams.searchParam,
                    next: response.nextCall,
                  },
                ]
              : null,
            pager: response.pager || {},
          })
        );
      }
    } else {
      firebaseActions.getPosts(query, actionParams, (response, action) => {
        switch (action) {
          case 'added':
            dispatch(
              receivePostList({
                key: key,
                posts: response.posts,
                orderBy: [response.orderBy],
                nextPage: response.nextCall
                  ? [{ ...actionParams, next: response.nextCall }]
                  : null,
                pager: response.pager || {},
                removeOld: params[0] !== 'drafts',
                globalSave: false,
              })
            );
            break;
          case 'removed':
            dispatch(
              removePostFromList({
                key: key,
                post: response.posts[0],
              })
            );
            break;
        }
      });
    }
  };
};

export const fetchDataWP = (
  params: Array<Object>,
  key: string,
  mustRefresh: boolean
): Function => {
  return (dispatch, getState) => {
    params = params || [];
    if (params.length === 0) {
      //params.push('page_handle=value%3D2017-09-15T09%253A03%253A12%252B00%253A00%26id%3D6197&page_handle=value%3D2017-01-18T12%253A03%253A57%252B00%253A00%26id%3D4535 ');
    }
    dispatch(
      requestPostList({
        key: key,
      })
    );
    //http://50.87.179.243/wp-json/wp/v2/posts
    const url =
      'http://blog.vegamecum.com/wp-json/wp/v2/posts?' + params.join('&');
    //'https://public-api.wordpress.com/rest/v1.1/sites/vegamecum.com/posts/?' +
    console.log('url:' + url + ' mustRefresh: ' + String(mustRefresh));
    if (mustRefresh) {
      dispatch(
        clearPostList({
          key: key,
        })
      );
    }
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        let posts = _.compact(
          _.map(response, (post) => {
            let parsedPost;
            try {
              parsedPost = PostParser(
                {
                  ...post,
                  content: post.content.rendered,
                  title: post.title.rendered,
                },
                firebaseData
              );
            } catch (e) {
              console.error(post.slug);
              console.error(e);
              //alert('Error parsing post: ' + post.slug);
            }
            return parsedPost;
          })
        );
        console.log('se acabó');
        dispatch(
          receivePostList({
            key: key,
            posts: posts,
            /*
            nextPage: response.meta.next_page
              ? ['page_handle=' + encodeURIComponent(response.meta.next_page)]
              : null,
            */
            nextPage: null,
            globalSave: true,
          })
        );
      });
  };
};

export const sendErrorReport = (data: Object) => {
  return (dispatch, getState) => {
    LogError(JSON.stringify(data));
  };
};
