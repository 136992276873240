import _ from 'lodash';
import * as firebaseActions from './firebase-actions';

export const CLEAR_CHAT_ROOM = 'CLEAR_CHAT_ROOM';
export const SET_LOADING_STATUS_TO_CHAT_ROOM = 'SET_LOADING_STATUS_TO_CHAT_ROOM';
export const ADD_MESSAGE_TO_CHAT_ROOM = 'ADD_MESSAGE_TO_CHAT_ROOM';
export const REMOVE_MESSAGE_TO_CHAT_ROOM = 'REMOVE_MESSAGE_TO_CHAT_ROOM';
export const CHANGE_MESSAGE_TO_CHAT_ROOM = 'CHANGE_MESSAGE_TO_CHAT_ROOM';
export const CHANGE_VOTE_ON_CHAT_ROOM = 'CHANGE_VOTE_ON_CHAT_ROOM';
export const SET_HAS_PREVIOUS_MESSAGES = 'SET_HAS_PREVIOUS_MESSAGES';
export const SET_MOST_RATED_MESSAGES_ON_CHAT_ROOM = 'SET_MOST_RATED_MESSAGES_ON_CHAT_ROOM';


const setMostRatedMessagesOnChatRoom = (chatRoomId, data: Object): Object => {
  return {
    type: SET_MOST_RATED_MESSAGES_ON_CHAT_ROOM,
    chatRoomId,
    data,
  };
};

const addMessageToChatRoom = (chatRoomId, data: Object): Object => {
  return {
    type: ADD_MESSAGE_TO_CHAT_ROOM,
    chatRoomId,
    data,
  };
};

const removeMessageToChatRoom = (chatRoomId, data: Object): Object => {
  return {
    type: REMOVE_MESSAGE_TO_CHAT_ROOM,
    chatRoomId,
    data,
  };
};

const changeMessageToChatRoom = (chatRoomId, data: Object): Object => {
  return {
    type: CHANGE_MESSAGE_TO_CHAT_ROOM,
    chatRoomId,
    data,
  };
};

const changeVoteOnChatRoom = (chatRoomId, data: Object): Object => {
  return {
    type: CHANGE_VOTE_ON_CHAT_ROOM,
    chatRoomId,
    data,
  };
};

const setHasPreviousMessages = (chatRoomId, data: Object): Object => {
  return {
    type: SET_HAS_PREVIOUS_MESSAGES,
    chatRoomId,
    data,
  };
};

const setLoadingStatusToChatRoom = (chatRoomId, data): Object => {
  return {
    type: SET_LOADING_STATUS_TO_CHAT_ROOM,
    chatRoomId,
    data
  };
};

const clearChatRoom = (chatRoomId: Object): Object => {
  return {
    type: CLEAR_CHAT_ROOM,
    chatRoomId
  };
};

export const updateMessageOnChatRoom = (chatRoomId, parentMessage, message): Function => {
  return (dispatch, getState) => {
    firebaseActions.updateMessageOnChatRoom(chatRoomId, parentMessage, message);
  };
};

export const removeMessageOnChatRoom = (chatRoomId, message): Function => {
  return (dispatch, getState) => {
    firebaseActions.removeMessageOnChatRoom(chatRoomId, message);
  };
};

export const removeMessageAndRepliesOnChatRoom = (chatRoomId, message): Function => {
  return (dispatch, getState) => {
    firebaseActions.removeMessageAndRepliesOnChatRoom(chatRoomId, message);
  };
};

export const banUser  = (user): Function => {
  return (dispatch, getState) => {
    firebaseActions.banUser(user);
  };
};

export const sendMessageToChatRoom = (chatRoomId, message): Function => {
  return (dispatch, getState) => {
    firebaseActions.sendMessageToChatRoom(chatRoomId, message);
  };
};

export const replyMessageOnChatRoom = (chatRoomId, message, reply): Function => {
  return (dispatch, getState) => {
    firebaseActions.replyMessageOnChatRoom(chatRoomId, message, reply);
  };
};

export const voteMessageOnChatRoom = (chatRoomId, message, vote): Function => {
  return (dispatch, getState) => {
    firebaseActions.voteMessageOnChatRoom(chatRoomId, message, vote);
  };
};

export const getMostRatedCommentsOnChatRoom = (chatRoomId): Function => {
  return async (dispatch, getState) => {
    const comments = await firebaseActions.getMostRatedCommentsOnChatRoom(chatRoomId);
    dispatch(setMostRatedMessagesOnChatRoom(chatRoomId, _.values(comments)));
  }
}

export const enterChatRoom = (chatRoomId): Function => {
  return (dispatch, getState) => {
    dispatch(clearChatRoom(chatRoomId));
    firebaseActions.enterChatRoom(chatRoomId, (obj, action) => {
      console.log('action!', action, chatRoomId, obj);
      switch (action) {
        case 'added':
          dispatch(addMessageToChatRoom(chatRoomId, obj));
          break;
        case 'removed':
          dispatch(removeMessageToChatRoom(chatRoomId, obj));
          break;
        case 'changed':
          dispatch(changeMessageToChatRoom(chatRoomId, obj));
          break;
        case 'vote_changed':
          dispatch(changeVoteOnChatRoom(chatRoomId, obj));
          break;
      }
    });
  };
};

export const leaveChatRoom = (chatRoomId): Function => {
  return (dispatch, getState) => {
    firebaseActions.leaveChatRoom(chatRoomId);
  };
};

export const loadPreviousMessagesOnChatRoom = (chatRoomId, startKey, limit): Function => {
  return (dispatch, getState) => {
    dispatch(setLoadingStatusToChatRoom(chatRoomId, true));
    firebaseActions.loadPreviousMessagesOnChatRoom(chatRoomId, startKey, limit, (snapshot) => {
      const messages = _.values(snapshot.val());
      dispatch(setLoadingStatusToChatRoom(chatRoomId, false));
      dispatch(setHasPreviousMessages(chatRoomId, messages.length === limit));
      messages.forEach((message) => {
        dispatch(addMessageToChatRoom(chatRoomId, {
          message
        }));
      });
    });
  };
};