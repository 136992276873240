import _ from 'lodash';
import {getProcessedIngredientText} from './ingredient-parser';
import {getProcessedStepText} from './preparation-step-parser';

export const mergeWorkingWithRaw = function(
  working,
  raw,
  measurementSystem = 'metric',
  isVOEnabled = false,
  allIngredientsUnits = {},
) {
  if (!raw) {
    return;
  }
  let preparationDelta = [],
    ingredientsDelta = [],
    unitsDelta = {},
    post = _.cloneDeep(raw);
  if (post.__vo && isVOEnabled) {
    post = post.__vo;
  }
  if (working) {
    preparationDelta = working.preparation || [];
    if (working.ingredients) {
      ingredientsDelta = working.ingredients;
      _.each(ingredientsDelta, (ingredientBlock, i) => {
        let l = [];
        if (ingredientBlock) {
          _.each(ingredientBlock.list, (content, id) => {
            l[id] = content || {};
          });
        } else {
          ingredientBlock = {};
        }
        ingredientBlock.list = l;
        ingredientsDelta[i] = ingredientBlock;
      });
    }
    if (working.summary && working.summary.units) {
      unitsDelta = working.summary.units;
    }
    if (working.rate) {
      post.rate = working.rate;
    }
  }

  post.summary = post.summary || {};
  post.summary.units = post.summary.units || {};
  post.summary.units = _.extend({}, post.summary.units, unitsDelta);

  post.summary.portions =
    post.summary.units.value / (post.summary.units.type.step || 1);

  if (post.summary.units.type) {
    let unitName =
      post.summary.units.value > 1
        ? post.summary.units.type.plural
        : post.summary.units.type.name;
    post.unitName = unitName = unitName.replace('$n', post.summary.units.value);
  }
  post.ingredients = _.merge([], post.ingredients, ingredientsDelta);
  post.ingredients = processIngredients(post, measurementSystem);
  post.preparation = _.map(post.preparation, (step, i) => {
    return _.extend({}, step, preparationDelta[i] || {});
  });
  post.preparation = processPreparationSteps(post, measurementSystem);
  return post;
};

const processPreparationSteps = function(post, measurementSystem) {
  return _.map(post.preparation, step => {
    step = step || {};
    return {
      ...step,
      processedText: getProcessedStepText(
        step,
        post.ingredients,
        post.summary.units,
        measurementSystem,
      ),
    };
  });
};


const processIngredients = function(post, measurementSystem) {
  return _.map(post.ingredients, (ingredientBlock) => {
     ingredientBlock.list = _.map(ingredientBlock.list, (ingredient) => {
         return {
             ...ingredient,
             processedText: getProcessedIngredientText(ingredient, post.summary.units, measurementSystem)
         }
     });
     return ingredientBlock;
 })
}