/* @flow */
/*eslint-disable prefer-const */

import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useContainerStyles = makeStyles((theme) => ({
  itemTitle: {
    zIndex: 1,
    //display: 'flex',
    //flexDirection: 'column',
    //justifyContent: 'flex-end',
    //padding: '14px 8px 4px 8px',
  },
  itemTitleText: {
    fontWeight: '600',
    textAlign: 'center',
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  itemTitleTopRight: {
    color: 'black',
    position: 'absolute',
    right: '0px',
    top: '0px',
    // fontSize: '20px',
    width: '40%',
    padding: '10px 10px',
    backgroundImage:
      '-webkit-radial-gradient(right top, ellipse cover, rgb(255 255 255) 0%, rgba(255,255,255,0 ) 72%, rgba(255,255,255,0) 100%)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  itemTitleTopRightText: {
    textAlign: 'right',
  },
}));

function OverlayText({ text }) {
  const classes = useContainerStyles();
  return (
    <Box
      className={classes.itemTitle}
      fontWeight={600}
      textAlign="center"
      color="text.primary"
      flex={1}
      top={'35%'}
      position={'absolute'}
      bgcolor={'text.secondary'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      height={'30%'}
      width={'80%'}
      marginLeft={'10%'}
      padding={'10px 5px'}
    >
      <Typography variant={'h5'} className={classes.itemTitleText}>
        {text}
      </Typography>
    </Box>
  );
}

OverlayText.propTypes = {};

OverlayText.defaultProps = {
  text: '',
};

export default OverlayText;
