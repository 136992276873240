/* @flow */

import * as types from '../actions'
import produce from 'immer'

const data = (
  state = {
    initialized: false,
    active: false,
    loadingPercent: 1,
    loadingMessage: '',
    online: true,
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case types.ACTIVE_APP:
        draft.active = true
        return
      case types.UPDATE_APP_LOADING:
        return Object.assign({}, state, action.data)
      case types.INITIALIZED:
        draft.initialized = true
        return
      case types.CHANGE_ONLINE_STATUS:
        draft.online = action.data
        return
    }
  })

export default data
