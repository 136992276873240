import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetch, refresh } from '../../actions';
import { getList, isLoading } from '../../selectors';
import Component from './component';

function mapStateToProps(state, props) {
  return {
    elements: getList(state, props),
    loading: isLoading(state, props),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetch, refresh }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    forwardRef: true,
  }
)(Component);
