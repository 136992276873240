/* @flow */

import _ from 'lodash';
import * as values from '../constants/values';
import * as cache from './firebase-cache';
import * as translate from './firebase-translate';
import I18n from '../i18n';
import {
  Notification,
  firebase,
  CalendarEvents,
  DeviceInfo,
} from '../platform';
import { encodePathKey, decodePathKey } from '../utils/firebase-path-key';
import * as JSONKeyCompressor from '../utils/json-key-compressor';

type Snapshot = {
  val: Function,
  forEach: Function,
};

let firebaseUser: Object = null;
let firebasePath: string = '';
let getPostsQuery = null;
let getPostsCallbacks = null;
let isFirstTime = true;
const Analytics = firebase.analytics();

export const initialize = (callback: Function) => {
  initializeUser(null, (user, path) => {
    firebaseUser = user.toJSON();
    firebasePath = path;
    cache.getValueOnceFromPath(path + '/isAdmin', (snapshot: Snapshot) => {
      firebaseUser.admin = snapshot.val();
      if (firebaseUser.admin) {
        Notification.subscribeToTopic('admin-all-messages');
      }
      initializeTranslation(path, async (language: string) => {
        firebaseUser.language = language;
        firebase.analytics().setUserProperty('language', language);
        await initializeRemoteConfig();
        callback(firebaseUser);
      });
    });
  });
};

const initializeRemoteConfig = async () => {
  if (!isFirstTime) {
    return;
  }
  isFirstTime = false;
  firebase.config().setDefaults({
    quotes: [],
    nutrientList: [],
    extras: {},
    dietInfo: {},
    releaseNotes: {},
    textSpeechBeautifiers: [],
    filters: [],
    portal: {},
  });
  await firebase.config().fetchAndActivate();
};

let onUserProfileUpdateCallback;
export const onUserProfileUpdate = cb => {
  onUserProfileUpdateCallback = cb;
};

export const loginWithEmail = (
  email: string,
  password: string,
  cb: Function
) => {
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .catch(function(error?: any) {
      cb(false, error);
    })
    .then(({ user }?: Object) => {
      if (user) {
        cb(true, {
          code: 'login-successfully',
          name: user.displayName,
        });
      }
    });
};

export const loginWithFacebookToken = (token: string, cb: Function) => {
  firebase
    .auth()
    .signInWithCredential(firebase.auth.FacebookAuthProvider.credential(token))
    .then(({ user }?: Object) => {
      if (user) {
        cb(true, {
          code: 'login-successfully',
          name: user.displayName,
        });
      }
    });
};

export const loginWithGoogleToken = async (token: string) => {
  const googleCredential = firebase.auth.GoogleAuthProvider.credential(token);
  return await firebase.auth().signInWithCredential(googleCredential);
};

export const loginWithAppleToken = async (identityToken, nonce) => {
  const appleCredential = firebase.auth.AppleAuthProvider.credential(
    identityToken,
    nonce
  );
  // Sign the user in with the credential
  return await firebase.auth().signInWithCredential(appleCredential);
};

export const linkEmailToUser = (
  email: string,
  password: string,
  data: Object,
  cb: Function
) => {
  getUserAndPath((user: Object, path: string) => {
    firebase
      .database()
      .ref(path + '/info/email')
      .set(email);
    var credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );
    let currentUser = firebase.auth().currentUser;
    currentUser.linkWithCredential(credential).then(
      function({ user }: Object) {
        updateProfile(user, data, function(success: boolean) {
          cb(true, {
            code: 'register-successfully',
            name: user.displayName,
          });
        });
      },
      function(error?: any) {
        cb(false, error);
      }
    );
  });
};

export const setDonateAction = (accept: boolean) => {
  var promise = new Promise((resolve: Function, reject: Function) => {
    getUserAndPath((user: Object, path: string) => {
      firebase
        .database()
        .ref(path + '/info/donate')
        .set({
          accept,
          date: new Date().getTime(),
        })
        .then((snapshot: Snapshot) => {
          resolve(snapshot);
        })
        .catch(() => {
          reject();
        });
    });
  });
  return promise;
};

export const setFCMToken = async data => {
  const { path } = await getUserAndPath();
  await firebase
    .database()
    .ref(`${path}/info/fcm`)
    .set(data);
};

export const setRewardAction = data => {
  var promise = new Promise((resolve: Function, reject: Function) => {
    getUserAndPath((user: Object, path: string) => {
      firebase
        .database()
        .ref(path + '/info/reward')
        .set({
          ...data,
          date: new Date().getTime(),
        })
        .then((snapshot: Snapshot) => {
          resolve(snapshot);
        })
        .catch(() => {
          reject();
        });
    });
  });
  return promise;
};

export const logout = (cb: Function) => {
  firebase
    .auth()
    .signOut()
    .then(function() {
      cb();
    })
    .catch(function(error?: any) {
      cb();
    });
};

export const deleteEmailUser = (password: string, cb: Function) => {
  let currentUser = firebase.auth().currentUser;
  loginWithEmail(
    currentUser.email,
    password,
    (success: boolean, error?: any) => {
      if (success) {
        let currentUser = firebase.auth().currentUser;
        currentUser
          .delete()
          .then(function() {
            cb(true);
          })
          .catch(function(error) {
            cb(false, error);
          });
      } else {
        cb(false, error); //Bad relogin
      }
    }
  );
};

export const deleteFacebookUser = (token: string, cb: Function) => {
  getUserAndPath((user: Object, path: string) => {
    loginWithFacebookToken(token, () => {
      let currentUser = firebase.auth().currentUser;
      currentUser
        .delete()
        .then(function() {
          cb(true);
        })
        .catch(function(error) {
          cb(false, error);
        });
    });
  });
};

export const updateEmail = (user: Object, email: string, cb: Function) => {
  if (!email) {
    return cb(true);
  }
  user.updateEmail(email).then(
    function() {
      cb(true);
    },
    function(error) {
      console.log(error);
      cb(false, error);
    }
  );
};

export const updateProfile = (user: Object, profile: Object, cb: Function) => {
  if (!profile) {
    return cb(true);
  }
  user.updateProfile(profile).then(
    function() {
      firebaseUser = {
        ...firebaseUser,
        ...user.toJSON(),
        ...profile,
      };
      if (onUserProfileUpdateCallback) {
        onUserProfileUpdateCallback(firebaseUser);
      }
      cb(true);
    },
    function(error) {
      console.log(error);
      cb(false, error);
    }
  );
};

export const updatePassword = (
  user: Object,
  password: string,
  cb: Function
) => {
  if (!password) {
    return cb(true);
  }
  user.updatePassword(password).then(
    function() {
      cb(true);
    },
    function(error) {
      cb(false, error);
    }
  );
};

export const modifyThirdPartyUserData = (data: Object, cb: Function) => {
  let currentUser = firebase.auth().currentUser;
  updateProfile(currentUser, data, function(success, error) {
    if (success) {
      cb(true, {
        code: 'modified-successfully',
      });
    } else {
      cb(false, error); // bad update profile
    }
  });
};

export const modifyUserData = (
  email: string,
  password: string,
  newpassword: string,
  data: Object,
  cb: Function
) => {
  let currentUser = firebase.auth().currentUser;
  loginWithEmail(currentUser.email, password, (success, error) => {
    if (success) {
      updateEmail(currentUser, email, function(success, error) {
        if (success) {
          updatePassword(currentUser, newpassword, function(success, error) {
            if (success) {
              updateProfile(currentUser, data, function(success, error) {
                if (success) {
                  cb(true, {
                    code: 'modified-successfully',
                  });
                } else {
                  cb(false, error); // bad update profile
                }
              });
            } else {
              cb(false, error); // bad change password
            }
          });
        } else {
          cb(false, error); // bad update-mail
        }
      });
    } else {
      cb(false, error); //Bad relogin
    }
  });
};

export const getUserAndPath = (callback: Function) => {
  return new Promise((resolve, reject) => {
    if (!firebaseUser || firebaseUser.fake) {
      if (!callback) {
        reject();
      }
      return;
    }
    if (callback) {
      callback(firebaseUser, firebasePath);
    }
    resolve({
      user: firebaseUser,
      path: firebasePath,
    });
  });
};

export const initializeUser = (legacyUser: any, callback: Function) => {
  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      let path = '/users/' + user.uid;
      firebase
        .database()
        .ref(`/users/${user.uid}/info/last-login/`)
        .set({
          date: new Date().getTime(),
          version: DeviceInfo.getVersion(),
        });
      callback(user, path);
    } else {
      firebase
        .auth()
        .signInAnonymously()
        .catch();
    }
  });
};

export const initializeTranslation = (path: string, cb: Function) => {
  let ref = firebase.database().ref(path + '/settings/language');
  ref.off('value');
  ref.on('value', snapshot => {
    let language = snapshot.val();
    translate.initialize(language).then(language => {
      cb(language);
    });
  });
};

export const resetAccount = () => {
  getUserAndPath((user: Object, path: string) => {
    let db = firebase.database();
    db.ref(path + '/favs/').remove();
    db.ref(path + '/cart/').remove();
    db.ref(path + '/settings/').remove();
    db.ref(path + '/working_posts/').remove();
    cache.getValueOnceFromPath(path + '/alarms/', snapshot => {
      snapshot.forEach(alarm => {
        alarm = JSONKeyCompressor.decompress(alarm.val(), '/alarms/*');
        removeAlarm(alarm);
      });
    });
  });
};

export const getQuotes = async () => {
  let quotes = JSON.parse((await getRemoteConfigValue('quotes')).asString());
  return {
    val: () => quotes,
  };
};

export const getCategories = () => {
  return new Promise((resolve, reject) => {
    cache.getValueFromPath("/categories", resolve);
  });
};

export const getFilters = async (cb: Function) => {
  let globalFilters = JSON.parse(
    (await getRemoteConfigValue('filters')).asString()
  );
  globalFilters = _.map(globalFilters, (filter, k) => {
    return {
      ...filter,
      isCustom: false,
      isSpecial: !!filter.isSpecial,
    };
  });
  let { path } = await getUserAndPath();
  let ref = firebase.database().ref(path + '/filters/');
  ref.on('value', snapshot => {
    cb({
      val: () => {
        let ownFilters = [];
        snapshot.forEach((filter, k) => {
          ownFilters.push({
            ...filter.val(),
            isCustom: true,
          });
        });
        return [...ownFilters, ...globalFilters];
      },
    });
  });
};

export const getNutrientList = async () => {
  let nutrientList = JSON.parse(
    (await getRemoteConfigValue('nutrientList')).asString()
  );
  return {
    val: () => nutrientList,
  };
};

export const importRecipe = (post: Object) => {
  post.fromWP = false;
  post.slug = post.slug.replace('*wp*', '');
  post.priorityKey = 0 - new Date(post.date).getTime();
  post.indexable = false;
  return new Promise(async resolve => {
    await firebase
      .database()
      .ref('posts/' + post.slug)
      .update(post);

    let url = `https://us-central1-vegamecum-c057a.cloudfunctions.net/migratePostImage?slug=${
      post.slug
    }`;

    await migratePostImage(post.slug);
  });
};

export const migratePostImage = (slug: String) => {
  return new Promise(async resolve => {
    slug = slug.replace('*wp*', '');
    let url = `https://us-central1-vegamecum-c057a.cloudfunctions.net/migratePostImage?slug=${slug}`;
    console.log('url', url);

    return fetch(url)
      .then(response => response.json())
      .then(
        response => {
          console.log('success');
          alert('migrating post images ok!');
          resolve();
        },
        error => {
          console.log('error');
          console.log(error);
          alert('error migrating post images');
          resolve();
        }
      );
  });
};

export const importRecipeAsIngredient = (post: Object) => {
  post.fromWP = false;
  post.slug = post.slug.replace('*wp*', '');
  let units = {};
  let notes = {};
  units[post.summary.units.type.key] = post.summary.units.type;
  notes[0] = {
    slug: post.slug,
    type: 'recipe',
  };
  return firebase
    .database()
    .ref('admin-private-zone/ingredients/' + post.slug)
    .set({
      name: post.title,
      nameRegex: '<a .*' + post.slug,
      nutrients: post.nutrients.default,
      notes: notes,
      recipe: true,
    });
};

export const addRecipeToPortal = (slug: string, cb: Function) => {
  slug = slug.replace('*wp*', '');
  getRecipe(
    {
      slug,
    },
    snapshot => {
      let response = snapshot.val();
      if (response) {
        firebase
          .database()
          .ref('/lists/portal/' + slug)
          .set({
            mainImage: response.mainImage,
            priorityKey: response.priorityKey,
            slug: response.slug,
            title: response.title,
          });

        firebase
          .database()
          .ref('/posts/' + slug)
          .update({
            indexable: true,
          });
      }
      cb();
    }
  );
};

export const translatePost = (language: string, slug: string, cb: Function) => {
  let url =
    'https://us-central1-vegamecum-c057a.cloudfunctions.net/translatePost?language=' +
    language +
    '&slug=' +
    slug;
  console.log(url);
  fetch(url)
    .then(response => response.json())
    .then(
      response => {
        cb();
      },
      error => {
        console.log('error');
        console.log(error);
      }
    );
};

export const setIngredientNutrients = (
  ingredientSlug: string,
  usdaCode: string,
  nutrients: Object
) => {
  firebase
    .database()
    .ref('admin-private-zone/ingredients/' + ingredientSlug + '/usda')
    .set(usdaCode);
  return firebase
    .database()
    .ref('admin-private-zone/ingredients/' + ingredientSlug + '/nutrients')
    .set({
      ...nutrients,
    });
};

export const setIngredientUnit = (
  ingredientName: string,
  unitName: string,
  unitValue: Object
) => {
  return firebase
    .database()
    .ref(
      'admin-private-zone/ingredients/' + ingredientName + '/units/' + unitName
    )
    .update({
      ...unitValue,
    });
};

export const getAlarms = (cb: Function) => {
  getUserAndPath((user: Object, path: string) => {
    let ref = firebase.database().ref(path + '/alarms/');
    ref.off('value');
    ref.on('value', snapshot => {
      cb({
        val: () => {
          return JSONKeyCompressor.decompress(snapshot.val(), `/alarms`);
        },
      });
    });
  });
};

export const getUserInfo = (cb: Function) => {
  getUserAndPath((user: Object, path: string) => {
    let ref = firebase.database().ref(path + '/info/');
    ref.off('value');
    ref.on('value', cb);
  });
};

export const getWorkingPosts = (cb: Function) => {
  getUserAndPath((user: Object, path: string) => {
    let ref = firebase.database().ref(path + '/working_posts/');
    ref.off('child_changed');
    ref.off('child_added');
    ref.off('child_removed');
    ref.on('child_changed', cb.bind(this, 'change'));
    ref.on('child_added', cb.bind(this, 'add'));
    ref.on('child_removed', cb.bind(this, 'remove'));
  });
};

export const toggleCheckStepOnPost = (
  { slug, mainImage, title }: Object,
  stepIndex: number,
  state: boolean
) => {
  getUserAndPath((user: Object, path: string) => {
    if (state) {
      firebase
        .database()
        .ref(`${path}/working_posts/${slug}/preparation/${stepIndex}/selected`)
        .set(true);
    } else {
      firebase
        .database()
        .ref(`${path}/working_posts/${slug}/preparation/${stepIndex}/selected`)
        .remove();
    }
  });
};

export const setPersonToRecipe = (postSlug: string, number: number) => {
  getUserAndPath((user: Object, path: string) => {
    firebase
      .database()
      .ref(`${path}/working_posts/${postSlug}/summary/units/value`)
      .set(number);
  });
};

export const ratePost = async ({ slug }, number: number) => {
  const { path } = await getUserAndPath();
  firebase
    .database()
    .ref(`${path}/working_posts/${slug}/rate/`)
    .set(number);
};

export const removeAlarm = (data: Object) => {
  getUserAndPath((user: Object, path: string) => {
    cache.getValueOnceFromPath(path + '/alarms/' + data.id, alarm => {
      alarm = JSONKeyCompressor.decompress(alarm.val(), '/alarms/*');
      if (alarm && alarm.calendarEventId) {
        cancelCalendarAlarm(alarm.calendarEventId);
      }
      if (alarm && alarm.notificationId) {
        Notification.cancelLocalNotifications({ id: alarm.notificationId });
      }
      firebase
        .database()
        .ref(path + '/alarms/' + data.id)
        .remove();
    });
    cancelPlanification(data.id);
  });
};

export const createAlarm = (data: Object) => {
  getUserAndPath((user: Object, path: string) => {
    data.notificationId = String(Math.floor(Math.random() * 1000));
    let dbPath = path + '/alarms/' + data.id;
    firebase
      .database()
      .ref(dbPath)
      .set(JSONKeyCompressor.compress(data, '/alarms/*'));
    let disableNotificationsAlarm = settings
      ? settings.disableNotificationsAlarm
      : false;
    if (!disableNotificationsAlarm) {
      createLocalNotification(data);
      createCalendarAlarm(data, dbPath);
    }
  });
};

let settings;
export const getSettings = (cb: Function) => {
  getUserAndPath((user: Object, path: string) => {
    let ref = firebase.database().ref(path + '/settings');
    ref.off('value');
    ref.on('value', snapshot => {
      settings = snapshot.val();
      cb(snapshot);
    });
  });
};

export const setSettingProp = (prop: string, data: Object) => {
  getUserAndPath(async (user: Object, path: string) => {
    if (prop === 'language') {
      await firebase.analytics().setUserProperty('language', data);
      await forceRemoteConfigRefresh();
    }
    firebase
      .database()
      .ref(path + '/settings/' + prop)
      .set(data);
  });
};

export const getRecipe = (
  { slug }: Object,
  cb: Function,
  progress: Function = () => {}
) => {
  getUserAndPath((user: Object, path: string) => {
    let isDraft = slug.indexOf(`new-recipe`) === 0;
    if (isDraft) {
      path = `${path}/drafts/`;
    } else {
      let isUserRecipe = slug.indexOf(`new-recipe`) > 0;
      if (isUserRecipe) {
        path = `/lists/user-recipes/`;
      } else {
        path = `/posts/`;
      }
    }
    path = `${path}${slug}`;
    console.log('path', path);
    progress({
      status: '1',
      percent: 10,
    });
    cache.getValueFromPath(
      path,
      snapshot => {
        progress({
          status: '2',
          percent: 60,
        });
        let value = JSONKeyCompressor.decompress(snapshot.val(), '/posts/*');
        translate.translateValueFromPath(
          value,
          path,
          true,
          snapshot => {
            progress({
              status: '3',
              percent: 100,
            });
            cb({
              val: () => {
                let value = {
                  ...snapshot.val()
                }
                if (isDraft) {
                  value.isEditable = isDraft;
                  value.path = path;
                } else {
                  value.isEditable = false;
                }
                return value;
              },
              forEach: snapshot.forEach,
              ref: {
                path,
              },
            });
          },
          progressTranslate => {
            progress({
              status: '2.' + progressTranslate.status,
              percent: 70 + (progressTranslate.percent / 100) * 25,
            });
          }
        );
      },
      progressCache => {
        progress({
          status: '1.' + progressCache.status,
          percent: 20 + (progressCache.percent / 100) * 30,
        });
      }
    );
  });
};

export const getIngredients = (cb: Function) => {
  let allIngredient: Object = null;
  let allIngredientUnits: Object = null;
  let updateIngredientList = () => {
    if (!allIngredient || !allIngredientUnits) {
      return;
    }
    let ingredients = {};
    let unitKeys;
    let unit: Object;
    let units: Object;
    let ingredient;
    let keys = _.sortBy(_.keys(allIngredient));
    _.each(keys, (ingredientName: string) => {
      ingredient = allIngredient[ingredientName];
      ingredient.units = ingredient.units || {};
      ingredient.name = ingredient.name || ingredientName;
      ingredient.plural = ingredient.plural || ingredientName;
      ingredient.nameRegex =
        ingredient.nameRegex ||
        '(?:' + ingredient.name + '|' + ingredient.plural + ')';
      units = ingredient.unitType
        ? allIngredientUnits[ingredient.unitType]
        : null;
      units = units || allIngredientUnits['unknown'];
      unitKeys = [..._.keys(units), ..._.keys(ingredient.units)];
      _.each(unitKeys, key => {
        unit = units[key];
        ingredient.units[key] = { ...unit, ...ingredient.units[key] };
        if (ingredient.units[key].regex) {
          ingredient.units[key].regex = ingredient.units[key].regex
            .replace('$name', ingredient.nameRegex)
            .replace('$n', values.$N_REGEX);
          ingredient.units[key].name = ingredient.units[key].name.replace(
            '$name',
            ingredient.name
          );
          if (ingredient.units[key].plural) {
            ingredient.units[key].plural = ingredient.units[key].plural
              .replace('$plural', ingredient.plural)
              .replace('$name', ingredient.name);
          }
        } else {
          console.log('key: ' + key);
          console.log(ingredient.units);
        }
      });
      ingredients[ingredientName] = ingredient;
    });
    cb(ingredients);
  };

  let ref = firebase.database().ref('/admin-private-zone/ingredients');
  ref.off('value');
  ref.on('value', snapshot => {
    allIngredient = snapshot.val();
    updateIngredientList();
  });

  ref = firebase.database().ref('/admin-private-zone/ingredients_units');
  ref.off('value');
  ref.on('value', snapshot => {
    allIngredientUnits = snapshot.val();
    updateIngredientList();
  });
};

export const getUnits = async () => {
  let units = JSON.parse((await getRemoteConfigValue('units')).asString());
  return {
    val: () => units,
  };
};

/*
export const getCurrentRecipes = (cb: Function) => {
	getUserAndPath((user: Object, path: string) => {
		let ref = firebase.database().ref(path + '/working_posts/').orderByChild('time');
		ref.off('value');
		ref.on('value',(snapshot) => {
			cb({
				val: () => {
					return snapshot.val();
				}
			});
		});
	});
}
*/

export const addCurrentRecipe = (item: Object) => {
  getUserAndPath((user: Object, path: string) => {
    firebase
      .database()
      .ref(`${path}/working_posts/${item.slug}`)
      .set({
        ...item,
        time: new Date().getTime(),
      });
  });
};

export const deleteCurrentRecipe = (item: Object) => {
  getUserAndPath((user: Object, path: string) => {
    firebase
      .database()
      .ref(`${path}/working_posts/${item.slug}`)
      .remove();
  });
};

export const getBubbles = (cb: Function) => {
  getUserAndPath((user: Object, path: string) => {
    let ref = firebase.database().ref(path + '/bubbles/');
    ref.off('value');
    ref.on('value', snapshot => {
      cb({
        val: () => {
          return snapshot.val();
        },
      });
    });
  });
};

export const addBubble = (item: Object) => {
  getUserAndPath((user: Object, path: string) => {
    firebase
      .database()
      .ref(`${path}/bubbles`)
      .push(item);
  });
};

export const deleteBubble = (item: Object, index: number) => {
  getUserAndPath((user: Object, path: string) => {
    firebase
      .database()
      .ref(`${path}/bubbles/${index}`)
      .remove();
  });
};

export const getExtras = async (cb: Function) => {
  let globalExtras = JSON.parse(
    (await getRemoteConfigValue('extras')).asString()
  );
  let { path } = await getUserAndPath();
  let ref = firebase.database().ref(path + '/extras/');
  ref.on('value', snapshot => {
    cb({
      val: () => {
        let ownExtras = snapshot.val();
        return _.merge({}, globalExtras, ownExtras);
      },
    });
  });
};

export const getPortal = async () => {
  let portal = JSON.parse((await getRemoteConfigValue('portal')).asString());
  return portal;
};

export const toggleExtraFeatureStatus = (extra: Object) => {
  if (extra.productId) {
    getUserAndPath((user: Object, path: string) => {
      firebase
        .database()
        .ref(path + '/extras/list/' + extra.productId)
        .update({
          enabled: !extra.enabled,
        });
    });
  }
};

export const changePurchaseExtraState = (extra: Object, state: Object) => {
  if (extra.productId) {
    getUserAndPath((user: Object, path: string) => {
      firebase
        .database()
        .ref(path + '/extras/list/' + extra.productId)
        .update(state);
    });
  }
};

export const setRecipeFinished = (post: Object) => {
  getUserAndPath((user: Object, path: string) => {
    firebase
      .database()
      .ref(path + '/working_posts/' + post.slug)
      .set({
        finished: true,
      });
  });
};

export const getSearchSuggestions = (cb: Function) => {
  let path = '/search-suggestions';
  cache.getValueFromPath(path, snapshot => {
    let val = addFixedElementsToSuggestions(snapshot.val());

    translate.translateValueFromPath(val, path, true, snapshot => {
      cb(snapshot);
    });
  });
};

export const saveSearch = (search: Object) => {
  getUserAndPath((user: Object, path: string) => {
    let compressed = JSONKeyCompressor.compress(search, '/searched/*');
    let key = encodePathKey(search.text);
    firebase
      .database()
      .ref(`${path}/searched/${key}`)
      .set(compressed);
  });
};

export const deleteSearch = (search: Object) => {
  getUserAndPath((user: Object, path: string) => {
    let key = encodePathKey(search.text);
    firebase
      .database()
      .ref(`${path}/searched/${search.text}`)
      .remove();
    firebase
      .database()
      .ref(`${path}/searched/${key}`)
      .remove();
  });
};

export const saveFilter = (filter: Object) => {
  getUserAndPath((user: Object, path: string) => {
    let key = encodePathKey(filter.name);
    firebase
      .database()
      .ref(`${path}/filters/${key}`)
      .set(filter);
  });
};

export const removeFilter = (filter: Object) => {
  getUserAndPath((user: Object, path: string) => {
    let key = encodePathKey(filter.name);
    firebase
      .database()
      .ref(`${path}/filters/${filter.name}`)
      .remove();
    firebase
      .database()
      .ref(`${path}/filters/${key}`)
      .remove();
  });
};

export const getDrafts = (cb: Function) => {
  getUserAndPath((user: Object, path: string) => {
    let ref = firebase.database().ref(path + '/drafts/');
    ref.off('child_changed');
    ref.off('child_added');
    ref.off('child_removed');
    let unpackCallback = (action, snapshot) => {
      cb(action, {
        key: snapshot.key,
        ref: snapshot.ref,
        val: () => {
          return JSONKeyCompressor.decompress(snapshot.val(), '/posts/*');
        },
      });
    };
    ref.on('child_changed', unpackCallback.bind(this, 'change'));
    ref.on('child_added', unpackCallback.bind(this, 'add'));
    ref.on('child_removed', unpackCallback.bind(this, 'remove'));
  });
};

export const saveDraft = (draft: Object) => {
  getUserAndPath((user: Object, path: string) => {
    console.log(draft);
    let id = draft.id;
    delete draft.id;
    if (id) {
      firebase
        .database()
        .ref(path + '/drafts/' + id)
        .set(draft);
    } else {
      firebase
        .database()
        .ref(path + '/drafts/')
        .push(draft);
    }
  });
};

export const getFavs = (cb: Function) => {
  getUserAndPath((user: Object, path: string) => {
    let ref = firebase.database().ref(path + '/favs/');
    ref.off('child_added');
    ref.off('child_removed');
    ref.on('child_added', snapshot => {
      cb('add', {
        val: () => {
          return JSONKeyCompressor.decompress(snapshot.val(), '/favs/*');
        },
      });
    });
    ref.on('child_removed', snapshot => {
      cb('remove', {
        val: () => {
          return JSONKeyCompressor.decompress(snapshot.val(), '/favs/*');
        },
      });
    });
  });
};

export const addFav = (post: Object) => {
  if (post.slug) {
    getUserAndPath((user: Object, path: string) => {
      let compressed = JSONKeyCompressor.compress(
        {
          title: post.title,
          slug: post.slug,
          mainImage: post.mainImage,
          priorityKey: 0 - new Date().getTime(),
        },
        '/favs/*'
      );
      firebase
        .database()
        .ref(path + '/favs/' + post.slug)
        .set(compressed);
    });
  }
};

export const removeFav = (post: Object) => {
  if (post.slug) {
    getUserAndPath((user: Object, path: string) => {
      firebase
        .database()
        .ref(path + '/favs/' + post.slug)
        .remove();
    });
  }
};

/****************** CART ******************/

export const getCart = (cb: Function, cartId: string) => {
  getUserAndPath((user: Object, path: string) => {
    let cartPath = cartId === 'default' ? 'cart/' : `carts/list/${cartId}/`;
    let ref = firebase.database().ref(`${path}/${cartPath}`);
    ref.off('child_changed');
    ref.off('child_added');
    ref.off('child_removed');
    let unpackCallback = (action, snapshot) => {
      cb(action, {
        key: snapshot.key,
        val: () => {
          return JSONKeyCompressor.decompress(snapshot.val(), '/cart/*');
        },
      });
    };
    ref.on('child_changed', unpackCallback.bind(this, 'change'));
    ref.on('child_added', unpackCallback.bind(this, 'add'));
    ref.on('child_removed', unpackCallback.bind(this, 'remove'));
  });
};

export const addNewIngredientToCart = (
  ingredient: Object,
  cartId: string,
  recipe: Object = {
    slug: 'other',
    title: 'Other',
    blockIndex: 0,
  }
) => {
  getUserAndPath((user: Object, path: string) => {
    let cartPath = cartId === 'default' ? 'cart/' : `carts/list/${cartId}/`;
    let compressed = JSONKeyCompressor.compress(
      {
        ...ingredient,
        recipe,
      },
      '/cart/*'
    );
    firebase
      .database()
      .ref(`${path}/${cartPath}`)
      .push(compressed);
  });
};

const _hasWorkingPostLink = (slug: string) => {
  return slug !== 'other' && slug !== 'other-diet';
};

export const addIngredientToCart = (
  recipe: Object,
  ingredient: Object,
  blockIndex: number,
  index: number,
  cartId: string
) => {
  getUserAndPath((user: Object, path: string) => {
    let cartPath = cartId === 'default' ? 'cart' : `carts/list/${cartId}`;
    let key = recipe.slug + '_' + blockIndex + '_' + ingredient.name;
    let updates = {};
    updates[`/${cartPath}/${key}`] = JSONKeyCompressor.compress(
      {
        name: ingredient.name,
        gramsValue: ingredient.gramsValue,
        unit: ingredient.unit,
        unitType: ingredient.unitType,
        //units: ingredient.units,
        recipe: {
          slug: recipe.slug,
          title: recipe.title,
          image: recipe.image,
          unitValueName: recipe.unitValueName,
          blockIndex,
          index,
        },
      },
      '/cart/*'
    );
    if (ingredient.notes) {
      updates[`/${cartPath}/${key}`].notes = ingredient.notes;
    }

    if (_hasWorkingPostLink(recipe.slug)) {
      updates[
        `/working_posts/${
          recipe.slug
        }/ingredients/${blockIndex}/list/${index}/selected`
      ] = key;
    }
    firebase
      .database()
      .ref(path)
      .update(updates);
  });
};

export const checkIngredientFromCart = (
  slug: string,
  ingredient: Object,
  blockIndex: number,
  index: number,
  status: boolean,
  cartId: string
) => {
  getUserAndPath((user: Object, path: string) => {
    let cartPath = cartId === 'default' ? 'cart' : `carts/list/${cartId}`;
    let updates = {};
    updates[`/${cartPath}/${ingredient.selected}/selected`] = status;
    if (_hasWorkingPostLink(slug)) {
      updates[
        `/working_posts/${slug}/ingredients/${blockIndex}/list/${index}/selected`
      ] = status ? false : ingredient.selected;
    }
    firebase
      .database()
      .ref(path)
      .update(updates);
  });
};

export const checkIngredientsFromCart = (
  list: Array<Object>,
  status: boolean,
  cartId: string
) => {
  getUserAndPath((user: Object, path: string) => {
    let cartPath = cartId === 'default' ? 'cart' : `carts/list/${cartId}`;
    let updates = {};
    _.each(list, ingredient => {
      _.each(ingredient.recipeLinks, recipeLink => {
        updates[`/${cartPath}/${recipeLink.key}/selected`] = status;
        if (_hasWorkingPostLink(recipeLink.slug)) {
          updates[
            `/working_posts/${recipeLink.slug}/ingredients/${
              recipeLink.blockIndex
            }/list/${recipeLink.index}/selected`
          ] = status ? null : recipeLink.key;
        }
      });
    });
    firebase
      .database()
      .ref(path)
      .update(updates);
  });
};

export const removeIngredientFromCart = (
  slug: string,
  ingredient: Object,
  blockIndex: number,
  index: number,
  cartId: string
) => {
  getUserAndPath((user: Object, path: string) => {
    let cartPath = cartId === 'default' ? 'cart' : `carts/list/${cartId}`;
    let updates = {};
    updates[`/${cartPath}/${ingredient.selected}`] = null;
    if (_hasWorkingPostLink(slug)) {
      updates[
        `/working_posts/${slug}/ingredients/${blockIndex}/list/${index}/selected`
      ] = null;
    }
    firebase
      .database()
      .ref(path)
      .update(updates);
  });
};

export const removeAllIngredientsFromCart = (cartId: string) => {
  getUserAndPath((user: Object, path: string) => {
    let cartPath = cartId === 'default' ? 'cart/' : `carts/list/${cartId}/`;
    firebase
      .database()
      .ref(`${path}/${cartPath}`)
      .remove();
  });
};

export const updateIngredientInCart = (
  key: string,
  object: Object,
  override: boolean,
  cartId: string
) => {
  getUserAndPath((user: Object, path: string) => {
    let cartPath = cartId === 'default' ? 'cart' : `carts/list/${cartId}`;
    object = JSONKeyCompressor.compress(object, '/cart/*');
    let updates = override ? object : getFlattenPath(object);
    firebase
      .database()
      .ref(`${path}/${cartPath}/${key}`)
      .update(updates);
  });
};

export const addToCart = (title: string, ingredients: Array<Object>) => {
  getUserAndPath((user: Object, path: string) => {
    let compressed = JSONKeyCompressor.compress(
      {
        title,
        ingredients,
      },
      '/cart/*'
    );
    firebase
      .database()
      .ref(path + '/cart/')
      .push(compressed);
  });
};

/****************** RATTINGS ******************/
let stopRattingCallbacks = {};
export const enterRatting = async (id: string, cb: Function) => {
  const rattingRef = firebase.database().ref(`rattings/${id}/summary`);
  rattingRef.on('value', cb);
  stopRattingCallbacks[id] = stopRattingCallbacks[id] || [];
  stopRattingCallbacks[id].push(() => {
    rattingRef.off('value', cb);
  });
};

export const leaveRatting = async (id: string) => {
  if (
    stopRattingCallbacks &&
    stopRattingCallbacks[id] &&
    stopRattingCallbacks[id].length > 0
  ) {
    let stopCallback = stopRattingCallbacks[id].pop();
    stopCallback();
  }
};

/****************** CHATS ******************/

let stopChatRoomCallbacks = {};

export const loadPreviousMessagesOnChatRoom = (
  chatRoomId: string,
  startKey,
  limit,
  cb: Function
) => {
  let messagesRef = firebase
    .database()
    .ref(`chat-rooms/${chatRoomId}`)
    .orderByChild('priorityKey')
    .startAt(startKey + 1)
    .limitToFirst(limit);
  messagesRef.on('value', cb);
  stopChatRoomCallbacks[chatRoomId] = stopChatRoomCallbacks[chatRoomId] || [];
  stopChatRoomCallbacks[chatRoomId].push(() => {
    messagesRef.off('value', cb);
  });
};

export const getMostRatedCommentsOnChatRoom = async (chatRoomId: string) => {
  let ref = firebase
    .database()
    .ref(`chat-rooms/${chatRoomId}`)
    .orderByChild('score')
    .limitToFirst(3);
  let result = await cache.getValueFromRef(ref);
  return result.val();
};

export const enterChatRoom = async (chatRoomId: string, cb: Function) => {
  let { path } = await getUserAndPath();
  //console.log('JODER path!', path);
  if (!path) {
    console.log('NOT LOGGED!');
    return;
  }

  let newchatRoomQuery = firebase
    .database()
    .ref(`chat-rooms/${chatRoomId}`)
    .orderByChild('priorityKey')
    .endAt(0 - new Date().getTime());

  let modifyChatRoomQuery = firebase.database().ref(`chat-rooms/${chatRoomId}`);

  //console.log('user chat room ', `${path}/chat-rooms/${chatRoomId}`);
  let userChatRoomQuery = firebase
    .database()
    .ref(`${path}/chat-rooms/${chatRoomId}`);

  let onChildChanged = async (action: string, snapshot: Snapshot) => {
    let message = snapshot.val();
    cb({ message }, action);
  };

  let chatRoomCallbacks = {
    added: onChildChanged.bind(this, 'added'),
    changed: onChildChanged.bind(this, 'changed'),
    removed: onChildChanged.bind(this, 'removed'),
  };

  let userChatRoomCallbacks = {
    value: onChildChanged.bind(this, 'vote_changed'),
  };

  userChatRoomQuery.on('value', userChatRoomCallbacks.value);
  newchatRoomQuery.on('child_added', chatRoomCallbacks.added);
  modifyChatRoomQuery.on('child_changed', chatRoomCallbacks.changed);
  modifyChatRoomQuery.on('child_removed', chatRoomCallbacks.removed);
  stopChatRoomCallbacks[chatRoomId] = stopChatRoomCallbacks[chatRoomId] || [];

  stopChatRoomCallbacks[chatRoomId].push(() => {
    userChatRoomQuery.off('value', userChatRoomCallbacks.value);
    newchatRoomQuery.off('child_added', chatRoomCallbacks.added);
    modifyChatRoomQuery.off('child_changed', chatRoomCallbacks.changed);
    modifyChatRoomQuery.off('child_removed', chatRoomCallbacks.removed);
  });
};

export const leaveChatRoom = async (chatRoomId: Function) => {
  if (
    stopChatRoomCallbacks[chatRoomId] &&
    stopChatRoomCallbacks[chatRoomId].length > 0
  ) {
    let stopCallback = stopChatRoomCallbacks[chatRoomId].pop();
    stopCallback();
  }
};

export const sendMessageToChatRoom = (chatRoomId, message): Function => {
  let ref = firebase.database().ref(`chat-rooms/${chatRoomId}`);
  message.forEach(msg => {
    ref.child(msg._id).set({
      ...msg,
      priorityKey: 0 - new Date().getTime(),
    });
  });
};

const getPathForChatRoomAndMessage = (chatRoomId, message) => {
  let path = _.reduce(
    message.parents,
    (result, id) => {
      return `${result}/${id}/replies`;
    },
    `chat-rooms/${chatRoomId}`
  );

  return `${path}/${message._id}`;
};

export const replyMessageOnChatRoom = (
  chatRoomId,
  message,
  replies
): Function => {
  let path = getPathForChatRoomAndMessage(chatRoomId, message);
  path = `${path}/replies`;
  let ref = firebase.database().ref(path);
  replies.forEach(msg => {
    ref.child(msg._id).set({
      ...msg,
      priorityKey: 0 - new Date().getTime(),
    });
  });
};

export const updateMessageOnChatRoom = (
  chatRoomId,
  parentMessage,
  message
): Function => {
  let path;
  if (parentMessage) {
    path = getPathForChatRoomAndMessage(chatRoomId, parentMessage);
    path = `${path}/replies/${message._id}`;
  } else {
    path = getPathForChatRoomAndMessage(chatRoomId, message);
  }
  let ref = firebase.database().ref(path);
  ref.update(message);
};

export const removeMessageOnChatRoom = (chatRoomId, message): Function => {
  let path = getPathForChatRoomAndMessage(chatRoomId, message);
  let ref = firebase.database().ref(path);
  if (message.replies) {
    ref.update({
      deleted: true,
      score: 0,
    });
  } else {
    ref.remove();
  }
};

export const removeMessageAndRepliesOnChatRoom = (
  chatRoomId,
  message
): Function => {
  let path = getPathForChatRoomAndMessage(chatRoomId, message);
  let ref = firebase.database().ref(path);
  ref.remove();
};

export const banUser = (userId): Function => {
  firebase
    .database()
    .ref(`/users/${userId}/info/banned/`)
    .set(true);
};

export const voteMessageOnChatRoom = async (
  chatRoomId,
  message,
  vote
): Function => {
  let { path } = await getUserAndPath();
  let messagePath = getPathForChatRoomAndMessage(chatRoomId, message);
  let ref = firebase.database().ref(`${path}/${messagePath}`);
  ref.update({
    vote,
  });
};

/****************** EDIT POST ******************/

let editPostDraftBySlugCallbacks = {};
let editPostByPathCallbacks = {};
let postPathForTemporalId = {};

export const deleteNewRecipe = slug => {
  let postPath = postPathForTemporalId[slug];
  deletePostByPath(postPath);
};

export const deletePostByPath = postPath => {
  if (postPath) {
    firebase
      .database()
      .ref(postPath)
      .remove();
  }
};

export const startEditPostByPath = (postPath: string, cb: Function) => {
  if (editPostByPathCallbacks[postPath]) {
    return;
  }
  let ref = firebase.database().ref(postPath);
  editPostByPathCallbacks[postPath] = snapshot => {
    cb({
      val: () => {
        return JSONKeyCompressor.decompress(snapshot.val(), '/posts/*');
      },
      ref: snapshot.ref,
    });
  };
  ref.on('value', editPostByPathCallbacks[postPath]);
};

export const finishEditPostByPath = (postPath: Function) => {
  if (!editPostByPathCallbacks[postPath]) {
    return;
  }
  let ref = firebase.database().ref(postPath);
  ref.off('value', editPostByPathCallbacks[postPath]);
  delete editPostByPathCallbacks[postPath];
};

export const startEditNewRecipe = (slug: string, parentPost, cb: Function) => {
  getUserAndPath((user: Object, path: string) => {
    let draftPath = `${path}/drafts/${slug}`;
    let date = new Date().toISOString();
    let baseRecipe;
    if (parentPost) {
      baseRecipe = {
        title: parentPost.title,
        summary: parentPost.summary,
        parentPostSlug: parentPost.slug,
        categories: parentPost.categories,
        introduction: parentPost.introduction,
        ingredients: parentPost.ingredients,
        preparation: parentPost.preparation,
        introductionBlogOnly: parentPost.introductionBlogOnly,
        tags: parentPost.tags,
        mainImage: parentPost.mainImage,
        figures: parentPost.figures,
        secundaryImage: parentPost.secundaryImage,
      };
    } else {
      baseRecipe = {
        summary: {
          difficult: 'Media',
          time_minutes: '0',
          time_minutes_cooking: '0',
          units: {
            key: 'persona',
            type: {
              key: 'persona',
              name: 'Para $n persona',
              plural: 'Para $n personas',
              regex: '$n persona[s]?',
            },
            value: 2,
          },
        },
      };
    }
    baseRecipe = {
      ...baseRecipe,
      date,
      modified: date,
      priorityKey: 0 - new Date().getTime(),
      slug,
    };
    let compressed = JSONKeyCompressor.compress(baseRecipe, '/posts/*');
    firebase
      .database()
      .ref(draftPath)
      .set(compressed);
    postPathForTemporalId[slug] = draftPath;
    startEditPostByPath(draftPath, cb);
  });
};

export const finishEditNewRecipe = slug => {
  let postPath = postPathForTemporalId[slug];
  finishEditPostByPath(postPath);
};

export const startEditDraftPostBySlug = (slug: string, cb: Function) => {
  if (editPostDraftBySlugCallbacks[slug]) {
    return;
  }
  getUserAndPath((user: Object, path: string) => {
    let ref = firebase.database().ref(`${path}/drafts/${slug}`);
    editPostDraftBySlugCallbacks[slug] = snapshot => {
      cb({
        val: () => {
          return JSONKeyCompressor.decompress(snapshot.val(), '/posts/*');
        },
        ref: snapshot.ref,
      });
    };
    ref.on('value', editPostDraftBySlugCallbacks[slug]);
  });
};

export const finishEditPostDraftBySlug = (slug: Function) => {
  if (!editPostDraftBySlugCallbacks[slug]) {
    return;
  }
  getUserAndPath((user: Object, path: string) => {
    let ref = firebase.database().ref(`${path}/drafts/${slug}`);
    ref.off('value', editPostDraftBySlugCallbacks[slug]);
    delete editPostDraftBySlugCallbacks[slug];
  });
};

export const editPostTitle = (post: Object, data: string) => {
  if (!post.path) {
    console.log('[EDIT ERROR] Post without path');
    return;
  }
  console.log('editPostTitle');
  firebase
    .database()
    .ref(`${post.path}/title`)
    .set(data);
};

export const editPostIntroductionBlogOnly = (post: Object, data: string) => {
  if (!post.path) {
    console.log('[EDIT ERROR] Post without path');
    return;
  }
  firebase
    .database()
    .ref(`${post.path}/introductionBlogOnly`)
    .set(data);
};

export const editPostIntroduction = (post: Object, data: string) => {
  if (!post.path) {
    console.log('[EDIT ERROR] Post without path');
    return;
  }
  firebase
    .database()
    .ref(`${post.path}/introduction`)
    .set(data);
};

export const editPostCategories = (post, categories) => {
  if (!post.path) {
    console.log('[EDIT ERROR] Post without path');
    return;
  }
  firebase
    .database()
    .ref(`${post.path}/categories`)
    .set(_.values(categories));
};

export const editPostTags = (post, tags) => {
  if (!post.path) {
    console.log('[EDIT ERROR] Post without path');
    return;
  }
  firebase
    .database()
    .ref(`${post.path}/tags`)
    .set(_.values(tags));
};

export const editPostFigure = (post: Object, index, figure) => {
  if (!post.path) {
    console.log("[EDIT ERROR] Post without path");
    return;
  }
  if(figure.mainImage || figure.main) {
    firebase.database().ref(`${post.path}/mainImage`).set(figure.path );
  } else {
    firebase.database().ref(`${post.path}/secundaryImage/${index - 1}`).set(figure.path);
  }
  firebase.database().ref(`${post.path}/figures/${index}`).set(figure);
}

export const editPostImages = (post: Object, images: Array<string>) => {
  if (!post.path) {
    console.log('[EDIT ERROR] Post without path');
    return;
  }
  let image = images.shift();
  firebase
    .database()
    .ref(`${post.path}/mainImage`)
    .set(image || null);
  firebase
    .database()
    .ref(`${post.path}/secundaryImage`)
    .set(images);
};

export const editPostSummary = (post: Object, data: Object) => {
  if (!post.path) {
    console.log('[EDIT ERROR] Post without path');
    return;
  }
  firebase
    .database()
    .ref(`${post.path}/summary/`)
    .update(data);
};

export const editRecipeNumber = (post: Object, data: Object) => {
  if (!post.path) {
    console.log('[EDIT ERROR] Post without path');
    return;
  }
  firebase
    .database()
    .ref(`${post.path}/summary/units`)
    .update(data);
};

export const removePreparationStep = (post: Object, index: Object) => {
  if (!post.path) {
    console.log('[EDIT ERROR] Post without path');
    return;
  }
  firebase
    .database()
    .ref(`${post.path}/preparation/${index}`)
    .remove();
};

export const editPreparationStep = (post: Object, data: Object) => {
  if (!post.path) {
    console.log('[EDIT ERROR] Post without path');
    return;
  }
  firebase
    .database()
    .ref(`${post.path}/preparation/${data.index}`)
    .update(data);
};

export const removeIngredientBlock = (
  post: Object,
  block: Number,
  data: Object
) => {
  if (!post.path) {
    console.log('[EDIT ERROR] Post without path');
    return;
  }
  firebase
    .database()
    .ref(`${post.path}/ingredients/${block}`)
    .remove();
};

export const editIngredientBlock = (
  post: Object,
  block: Number,
  data: Object
) => {
  if (!post.path) {
    console.log('[EDIT ERROR] Post without path');
    return;
  }
  firebase
    .database()
    .ref(`${post.path}/ingredients/${block}/list`)
    .set(data);
};

export const editIngredientBlockName = (
  post: Object,
  block: Number,
  data: Object
) => {
  if (!post.path) {
    console.log('[EDIT ERROR] Post without path');
    return;
  }
  firebase
    .database()
    .ref(`${post.path}/ingredients/${block}/name`)
    .set(data);
};

export const updateModifiedDate = (post: Object) => {
  if (!post.path) {
    console.log('[EDIT ERROR] Post without path');
    return;
  }
  firebase
    .database()
    .ref(`${post.path}/modified`)
    .set(new Date().toISOString());
};

/****************** DIET ******************/

export const getDiet = (
  id: string = 'default',
  shared: boolean,
  cb: Function
) => {
  getUserAndPath((user: Object, path: string) => {
    if (shared) {
      path = `/share/diets/${id}`;
    } else {
      let dietPath = JSONKeyCompressor.getCompressedPath(`diet/list/${id}`);
      path = `${path}/${dietPath}`;
    }
    let ref = firebase
      .database()
      .ref(path)
      .orderByChild('time');
    ref.off('value');
    ref.on('value', snapshot => {
      cb({
        val: () => {
          return JSONKeyCompressor.decompress(
            snapshot.val(),
            `/diet/list/${id}`
          );
        },
      });
    });
  });
};

export const resetDiet = (id: string = 'default') => {
  getUserAndPath((user: Object, path: string) => {
    let dietPath = JSONKeyCompressor.getCompressedPath(`diet/list/${id}`);
    firebase
      .database()
      .ref(`${path}/${dietPath}`)
      .remove();
  });
};

export const importDiet = (id: string, destinationId: string = 'default') => {
  getUserAndPath((user: Object, path: string) => {
    getDiet(id, true, diet => {
      let dietPath = JSONKeyCompressor.getCompressedPath(
        `diet/list/${destinationId}`
      );
      diet = JSONKeyCompressor.compress(diet.val(), `diet/list/*`);
      firebase
        .database()
        .ref(`${path}/${dietPath}/`)
        .set(diet);
    });
  });
};

export const shareDiet = () => {
  var promise = new Promise((resolve, reject) => {
    getDiet('default', false, diet => {
      diet = JSONKeyCompressor.compress(diet.val(), `diet/list/*`);
      let ref = firebase
        .database()
        .ref(`/share/diets/`)
        .push(diet);
      resolve(ref.key);
    });
  });
  return promise;
};

export const addPlateToMeal = (day: string, meal: Object, plate: Object) => {
  getUserAndPath((user: Object, path: string) => {
    let mealKey = meal.startTime;
    let mealPath = JSONKeyCompressor.getCompressedPath(
      `diet/list/default/days/${day}/meals/${mealKey}`
    );
    let platesPath = JSONKeyCompressor.getCompressedPath(
      `diet/list/default/days/${day}/meals/${mealKey}/plates`
    );
    meal = JSONKeyCompressor.compress(
      meal,
      `diet/list/default/days/*/meals/${mealKey}`
    );
    plate = JSONKeyCompressor.compress(
      plate,
      `diet/list/default/days/${day}/meals/${mealKey}/plates/*`
    );
    firebase
      .database()
      .ref(`${path}/${mealPath}`)
      .update(meal);
    firebase
      .database()
      .ref(`${path}/${platesPath}`)
      .push(plate);
  });
};

export const editPlateOnMeal = (
  day: string,
  meal: string,
  id: string,
  plate: Object
) => {
  getUserAndPath((user: Object, path: string) => {
    plate = JSONKeyCompressor.compress(
      plate,
      `diet/list/default/days/${day}/meals/${meal}/plates/${id}`
    );
    let platePath = JSONKeyCompressor.getCompressedPath(
      `diet/list/default/days/${day}/meals/${meal}/plates/${id}`
    );
    firebase
      .database()
      .ref(`${path}/${platePath}`)
      .set(plate);
  });
};

export const removePlateFromMeal = (day: string, meal: string, id: string) => {
  getUserAndPath((user: Object, path: string) => {
    let platePath = JSONKeyCompressor.getCompressedPath(
      `diet/list/default/days/${day}/meals/${meal}/plates/${id}`
    );
    firebase
      .database()
      .ref(`${path}/${platePath}`)
      .remove();
  });
};

export const getDietsInfo = async () => {
  let dietInfo = JSON.parse(
    (await getRemoteConfigValue('dietInfo')).asString()
  );
  return {
    val: () => dietInfo,
  };
};

/****************** RELEASE_NOTES ******************/

export const getReleaseNotes = async (cb: Function) => {
  let releaseNotes = JSON.parse(
    (await getRemoteConfigValue('releaseNotes')).asString()
  );
  let { path } = await getUserAndPath();
  let ref = firebase.database().ref(path + '/release-notes');
  ref.on('value', snapshot => {
    cb({
      val: () => {
        return _.merge({}, releaseNotes, snapshot.val());
      },
    });
  });
};

export const updateReleaseNoteStatus = (data: Object, cb: Function) => {
  getUserAndPath((user: Object, path: string) => {
    firebase
      .database()
      .ref(path + '/release-notes/list/' + data.key + '/status')
      .set(data.status);
  });
};

/****************** PLAYLIST ******************/

export const getPlaylist = (cb: Function) => {
  getUserAndPath((user: Object, path: string) => {
    let ref = firebase.database().ref(path + '/playlist/');
    ref.off('value');
    ref.on('value', cb);
  });
};

export const setPlaylist = (steps: Array<Object>) => {
  getUserAndPath((user: Object, path: string) => {
    firebase
      .database()
      .ref(path + '/playlist/')
      .set(steps);
  });
};

export const removeFromPlaylist = (post: Object) => {
  getUserAndPath((user: Object, path: string) => {
    firebase
      .database()
      .ref(path + '/playlist/')
      .remove();
  });
};

export const getTextSpeechBeautifiers = async () => {
  let textSpeechBeautifiers = JSON.parse(
    (await getRemoteConfigValue('textSpeechBeautifiers')).asString()
  );
  return {
    val: () => textSpeechBeautifiers,
  };
};

export const sendMessageToSupport = (msg: string) => {
  getUserAndPath((user: Object, path: string) => {
    firebase
      .database()
      .ref('/chats/support/' + user.uid + '/')
      .push({
        msg: msg,
        user: user.uid,
      });
  });
};

export const sendTranslationProposal = (obj: Object) => {
  getUserAndPath((user: Object, path: string) => {
    firebase
      .database()
      .ref('/i18n/' + obj.language + '/proposals/' + obj.key)
      .push({
        text: obj.text,
        user: user.uid,
      });
  });
};

export const updateTranslation = (obj: Object) => {
  getUserAndPath((user: Object, path: string) => {
    let ref = firebase.database().ref('/i18n/' + obj.language + '/' + obj.key);
    if (_.isString(obj.text)) {
      ref.set(obj.text);
    } else {
      ref.update(obj.text);
    }
    if (obj.tsPath) {
      firebase
        .database()
        .ref('/i18n/' + obj.language + '/' + obj.tsPath)
        .set(Date.now());
    }
  });
};

export const cancelPlanification = (id: string) => {
  getUserAndPath((user: Object, path: string) => {
    firebase
      .database()
      .ref(path + '/calendar/' + id)
      .remove();
  });
};

export const planifyRecipe = (post: Object, time: Date, settings: Object) => {
  getUserAndPath((user: Object, path: string) => {
    post.time = time.getTime();
    let ref = firebase
      .database()
      .ref(path + '/calendar/')
      .push(
        JSONKeyCompressor.compress(
          {
            title: post.title,
            slug: post.slug,
            mainImage: post.mainImage,
            summary: post.summary,
            time: post.time,
          },
          `calendar/*`
        )
      );
    let endDate = new Date(post.time);
    endDate.setMinutes(endDate.getMinutes() + post.summary.time_minutes);
    createAlarm({
      alarmDate: post.time,
      alarmEndDate: endDate.getTime(),
      id: ref.key,
      title: post.title,
      slug: post.slug,
      image: post.mainImage,
      notificationText: '¡Llegó el momento! ¿Empezamos?',
    });
  });
};

export const planifyEvent = (event: Object, time: Date) => {
  getUserAndPath((user: Object, path: string) => {
    event.time = time.getTime();
    let ref = firebase
      .database()
      .ref(path + '/calendar/')
      .push(
        JSONKeyCompressor.compress(
          {
            title: event.title,
            time: event.time,
            duration: event.duration,
          },
          `calendar/*`
        )
      );
    let endDate = new Date(event.time);
    endDate.setMinutes(endDate.getMinutes() + event.duration);
    createAlarm({
      alarmDate: event.time,
      alarmEndDate: endDate.getTime(),
      id: ref.key,
      title: event.title,
      notificationText: `${event.title}`,
    });
  });
};

export const refreshRecipeFavs = (cb: Function) => {
  let allFavs = {};
  cache.getValueOnceFromPath('/users', (snapshot: Snapshot) => {
    //console.log(snapshot.val());
    snapshot.forEach((user: Object) => {
      let favs = user.val().favs;
      _.each(favs, (v: Object, k: number) => {
        allFavs[k] = allFavs[k] || 0;
        allFavs[k]++;
      });
    });
    _.each(allFavs, (v: Object, k: number) => {
      firebase
        .database()
        .ref('/posts/' + k + '/favs')
        .set(v);
    });
    cb();
  });
};

export const refreshAllPostOnPortal = (cb: Function) => {
  cache.getValueOnceFromPath('/lists/portal', (snapshot: Snapshot) => {
    snapshot.forEach(p => {
      let post = p.val();
      firebase
        .database()
        .ref('/lists/portal/' + p.key)
        .set({
          mainImage: post.mainImage,
          priorityKey: post.priorityKey,
          slug: post.slug,
          title: post.title,
        });
    });
    cb();
  });
};

export const refreshSuggestions = (cb: Function) => {
  let name;
  cache.getValueOnceFromPath(
    '/admin-private-zone/ingredients',
    (snapshot: Snapshot) => {
      snapshot.forEach(ingredient => {
        name = ingredient.val().name || ingredient.key;
        name = name.toLowerCase();
        firebase
          .database()
          .ref('/search-suggestions/ingredients/' + name)
          .set({
            text: name,
            type: 'ingredient',
          });
      });
      cache.getValueOnceFromPath('/lists/portal', (snapshot: Snapshot) => {
        snapshot.forEach(p => {
          let post = p.val();
          name = post.title || p.key;
          name = name.toLowerCase();
        });
        cb();
      });
    }
  );
};

export const sendPush = (data: Object) => {
  return cache.getValueOnceFromPath(
    '/admin-private-zone/pushKey',
    (snapshot: Snapshot) => {
      let androidData = {
        content_available: true,
        priority: 'high',
        data: {
          title: data.title,
          body: data.body,
          big_text: data.bigText || data.body,
          slug: data.slug,
          priority: 'high',
          custom_notification: {
            channelId: values.NOTIFICATION_CHANNEL,
            title: data.title,
            body: data.body,
            big_text: data.bigText || data.body,
            vibrate: 300,
            sound: 'default',
            wake_screen: true,
            lights: true,
            priority: 'high',
            picture: data.image,
            slug: data.slug,
            click_action: 'fcm.ACTION.HELLO',
            large_icon: 'logo',
            icon: 'notification',
            color: '#2C0233',
            show_in_foreground: true,
          },
        },
      };

      let iOSData = {
        content_available: true,
        notification: {
          title: data.title,
          body: data.body,
          big_text: data.bigText || data.body,
          slug: data.slug,
          click_action: 'fcm.ACTION.HELLO',
          large_icon: 'logo',
          icon: 'notification',
          color: '#2C0233',
          sound: 'default',
          priority: 'high',
        },
        data: {
          title: data.title,
          body: data.body,
          big_text: data.bigText || data.body,
          slug: data.slug,
          sound: 'default',
          priority: 'high',
        },
      };
      let key = snapshot.val();
      sendPushToKeyWithData(key, iOSData, '/topics/new-recipe');
      sendPushToKeyWithData(key, iOSData, '/topics/new-recipe-ios');
      sendPushToKeyWithData(key, androidData, '/topics/new-recipe-android');
    }
  );
};

const sendPushToKeyWithData = (key: string, data: Object, topic: string) => {
  fetch('https://fcm.googleapis.com/fcm/send', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'key=' + key,
    },
    body: JSON.stringify({
      to: topic,
      ...data,
    }),
  })
    .then(response => {
      console.log(response);
      return response.text();
    })
    .then(
      response => {
        console.log(response);
      },
      () => {}
    );
};

export const getPlanified = (cb: Function) => {
  getUserAndPath((user: Object, path: string) => {
    let ref = firebase
      .database()
      .ref(path + '/calendar/')
      .orderByChild('time');
    ref.off('value');
    ref.on('value', (snapshot: Snapshot) => {
      cb({
        val: () => {
          return JSONKeyCompressor.decompress(snapshot.val(), `/calendar`);
        },
      });
    });
  });
};

export const getLastSearched = (cb: Function) => {
  getUserAndPath((user: Object, path: string) => {
    let ref = firebase.database().ref(path + '/searched/').orderByChild('i').limitToFirst(10);
    ref.off('child_added');
    ref.off('child_removed');
    ref.on('child_added', (snapshot: Snapshot) => {
      cb('add', {
        val: () => {
          return JSONKeyCompressor.decompress(snapshot.val(), '/searched/*');
        },
      });
    });
    ref.on('child_removed', snapshot => {
      cb('remove', {
        val: () => {
          return JSONKeyCompressor.decompress(snapshot.val(), '/searched/*');
        },
      });
    });
  });
};

export const getPosts = (query: string, extraParams?: Object, cb: Function) => {
  translate.getTranslatePath().then(translatePath => {
    clearPreviousGetPosts();
    let limit = 5;
    let ref = '/posts';
    let orderBy = 'priorityKey';
    let nextCall = null;
    if (extraParams) {
      limit = extraParams.limit || limit;
      ref = extraParams.ref || ref;
      ref = extraParams.translate && translatePath ? translatePath + ref : ref;
      orderBy = extraParams.orderBy || orderBy;
    }
    getPostsQuery = firebase
      .database()
      .ref(ref)
      .orderByChild(orderBy)
      .limitToFirst(limit);
    if (extraParams && extraParams.next) {
      getPostsQuery = getPostsQuery.startAt(extraParams.next);
    }
    let received = 0;
    let onChildChanged = (action: string, snapshot: Snapshot) => {
      let obj = JSONKeyCompressor.decompress(snapshot.val(), '/posts/*');
      obj.path = `${ref}/${snapshot.key}`;
      obj = [obj];
      switch (action) {
        case 'added':
          received++;
          if (received === limit) {
            //clearPreviousGetPosts();
            nextCall = 'auto';
          }
          cb(
            {
              posts: obj,
              nextCall,
              orderBy,
            },
            action
          );
          break;
        case 'removed':
          received--;
          cb(
            {
              posts: obj,
              nextCall,
              orderBy,
            },
            action
          );
          break;
        case 'changed':
          cb(
            {
              posts: obj,
              nextCall,
              orderBy,
            },
            'removed'
          );
          cb(
            {
              posts: obj,
              nextCall,
              orderBy,
            },
            'added'
          );
          break;
      }
    };
    getPostsCallbacks = {
      added: onChildChanged.bind(this, 'added'),
      changed: onChildChanged.bind(this, 'changed'),
      removed: onChildChanged.bind(this, 'removed'),
    };

    getPostsQuery.on('child_added', getPostsCallbacks.added);
    getPostsQuery.on('child_changed', getPostsCallbacks.changed);
    getPostsQuery.on('child_removed', getPostsCallbacks.removed);
  });
};

const clearPreviousGetPosts = () => {
  if (getPostsCallbacks && getPostsQuery) {
    getPostsQuery.off('child_added', getPostsCallbacks.added);
  }
};

export const search = async (queryString: string, extraParams: Object) => {
  let limit = 5;
  let from = 0;
  if (extraParams && extraParams.limit) {
    limit = extraParams.limit;
  }
  if (extraParams && extraParams.from) {
    from = extraParams.from;
  }
  let index = await translate.getElasticIndex();
  console.log('extraParams', extraParams);
  console.log(
    'url',
    `https://us-central1-vegamecum-c057a.cloudfunctions.net/search?q=${queryString}&limit=${limit}&from=${from}&index=${index}`
  );
  return await fetch(
    `https://us-central1-vegamecum-c057a.cloudfunctions.net/search?q=${queryString}&limit=${limit}&from=${from}&index=${index}`
  ).then(response => response.json());
};

const authorizeEventStore = cb => {
  CalendarEvents.authorizeEventStore()
    .then(status => {
      if (status === 'authorized') {
        cb();
      }
    })
    .catch(error => {});
};

const createCalendarAlarm = (data, dbPath) => {
  let enableLinkedCalendar = settings ? settings.enableLinkedCalendar : false;
  if (enableLinkedCalendar) {
    authorizeEventStore(() => {
      let startDate = new Date(data.alarmDate),
        endDate = new Date(data.alarmEndDate || data.alarmDate);
      CalendarEvents.saveEvent(data.title, {
        //location: 'location',
        //notes: 'notes',
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        alarms: [
          {
            date: 0,
          },
        ],
      })
        .then(id => {
          firebase
            .database()
            .ref(dbPath)
            .update({
              calendarEventId: id,
            });
        })
        .catch(error => {
          console.log(error);
        });
    });
  }
};

const cancelCalendarAlarm = id => {
  let enableLinkedCalendar = settings ? settings.enableLinkedCalendar : false;
  if (enableLinkedCalendar) {
    authorizeEventStore(() => {
      CalendarEvents.removeEvent(id + '');
    });
  }
};

const createLocalNotification = data => {
  Notification.localNotificationSchedule({
    id: data.notificationId,
    ignoreInForeground: true,
    channelId: values.NOTIFICATION_CHANNEL,
    largeIcon: 'logo',
    smallIcon: 'notification',
    color: '#2C0233',
    vibrate: true,
    vibration: 300,
    priority: 'high',
    playSound: true,
    soundName: 'alarm.mp3',
    title: data.notificationText || I18n.t('notifications.alamFinished'),
    message: data.title,
    date: new Date(data.alarmDate),
    allowWhileIdle: false,
    userInfo: {
      slug: data.slug,
    },
  });
};

const addFixedElementsToSuggestions = (val: Object) => {
  _.each(values.PROMO_VIDEO.INGREDIENTS_INDEXED, k => {
    val.ingredients[k] = {
      text: k,
      type: 'ingredient',
    };
  });
  _.each(values.PROMO_VIDEO.RECIPES_INDEXED, k => {
    val.lists.portal[k] = {
      text: k,
      type: 'recipe',
    };
  });
  return val;
};

const getRemoteConfig = () => {
  return firebase.config();
  return new Promise(resolve => {
    let checkRemoteConfig = () => {
      console.log('getRemoteConfig???');
      if (isRemoteConfigInitialized) {
        console.log('getRemoteConfig!!!');
        resolve(firebase.config());
      } else {
        console.log('NOP!!!');
        setTimeout(checkRemoteConfig, 500);
      }
    };
    checkRemoteConfig();
  });
};

const getRemoteConfigValue = async value => {
  let firebaseConfig = await getRemoteConfig();
  return await firebaseConfig.getValue(value);
};

export const forceRemoteConfigRefresh = async () => {
  console.log('forceRemoteConfigRefresh')
  try {
    await firebase.config().fetch(0);
    await firebase.config().activate();
  } catch(e) {
    console.log('forceRemoteConfigRefresh failed', e)
  }
  return
};

const getFlattenPath = (
  obj: Object,
  map: Object = {},
  path: Array<string> = [],
  validKeysRegex?: RegExp
) => {
  _.each(obj, (v: Object, k: string) => {
    let p = path.concat([k]);
    if (_.isObject(v) || _.isArray(v)) {
      getFlattenPath(v, map, p, validKeysRegex);
    } else {
      k = p.join('/');
      if (!validKeysRegex || validKeysRegex.test(k)) {
        map[k] = v;
      }
    }
  });
  return map;
};
