import * as firebaseActions from './firebase-actions'
export const LOADED_EXTRAS = 'LOADED_EXTRAS'
export const CHANGE_ENABLE_RESTORATION = 'CHANGE_ENABLE_RESTORATION'

const receiveExtras = (data: Object): Object => {
  return {
    type: LOADED_EXTRAS,
    data,
  }
}

export const changeEnableRestoration = (data: Object): Object => {
  return {
    type: CHANGE_ENABLE_RESTORATION,
    data,
  }
}

export const fetch = (): Function => {
  return (dispatch, getState) => {
    firebaseActions.getExtras(snapshot => {
      dispatch(receiveExtras(snapshot.val()))
    })
  }
}

export const changePurchaseExtraState = (
  extra: Object,
  state: Object
): Function => {
  return (dispatch, getState) => {
    firebaseActions.changePurchaseExtraState(extra, state)
  }
}

export const toggleStatus = (data: Object): Function => {
  return (dispatch, getState) => {
    firebaseActions.toggleExtraFeatureStatus(data)
  }
}
