import * as moment from "moment";
import "moment/locale/es";
import * as slugify from "slugify";
import I18n from "../i18n";

const BASE_PATH = "https://vegamecum.com";

const Slugify = (str) => {
  return slugify(str, {
    replacement: "-",
    remove: null,
    lower: true,
  });
};

const getBasePath = (language, absolute) => {
  let languagePath = language && language !== "x-default" ? `/${language}` : "";
  let basePath = absolute ? BASE_PATH : "";
  return `${basePath}${languagePath}`;
};

export const getLinkForPathAndSearch = (
  { path, search },
  language,
  absolute
) => {
  language = language || I18n.getSelectedLanguage();
  let pathSplitted = path.split("/");
  if (
    pathSplitted.length > 1 &&
    (pathSplitted[1] === "es" || pathSplitted[1] === "en")
  ) {
    pathSplitted.splice(1, 1);
    path = pathSplitted.join("/");
  }
  let url = path + (search ? `?${search}` : "");
  return `${getBasePath(language, absolute)}${url}`;
};

export const getPostLink = (post, language, absolute, isAMP = false) => {
  language = language || I18n.getSelectedLanguage();
  let date = moment(post.date);
  if (post.isDraft) {
    let pathParams = post.path.split("/");
    return `${getBasePath(language, absolute)}/drafts/${pathParams[2]}/${
      pathParams[4]
    }`;
  }
  let url = `${getBasePath(language, absolute)}/${date.format(
    "YYYY/MM/DD"
  )}/${Slugify(post.slug)}/`;
  if(isAMP) {
    url = `${url}/amp/`;
  }
  return url;
};

export const getFilterLink = (filter, language, absolute) => {
  language = language || I18n.getSelectedLanguage();
  return `${getBasePath(language, absolute)}/filter/${Slugify(filter)}/`;
};

export const getTagLink = (tag, language, absolute) => {
  language = language || I18n.getSelectedLanguage();
  return `${getBasePath(language, absolute)}/tag/${Slugify(tag)}/`;
};

export const getCategoryLink = (category, language, absolute) => {
  language = language || I18n.getSelectedLanguage();
  return `${getBasePath(language, absolute)}/category/${Slugify(category)}/`;
};

export const getTextSlug = text => {
  return Slugify(text);
}