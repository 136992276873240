import {createSelector} from 'reselect';
import {isRewardActive} from './reward';
import _ from 'lodash';

const getExtras = (state, props) => state.extras;

export const isAdActive = createSelector(
  [getExtras, isRewardActive],
  (extras, rewardActive) => {
    return !(_.get(extras, 'list.disable_ads_365.enabled') || rewardActive);
  },
);

export const isRecipeShortcutActive = createSelector(
  [getExtras, isRewardActive],
  (extras, rewardActive) => {
    return _.get(extras, 'list.recipe_shortcut.enabled') || rewardActive;
  },
);

export const isPremiumUser = createSelector(
  [getExtras, isRewardActive],
  (extras, rewardActive) => {
    return !!_.get(extras, 'list.premium_access.enabled');
  },
);

export const isDarkThemeActive = createSelector(
  [getExtras, isRewardActive],
  (extras, rewardActive) => {
    return _.get(extras, 'list.dark_theme.enabled') || rewardActive;
  },
);

export const isRecipeEditorActive = createSelector(
  [getExtras, isRewardActive],
  (extras, rewardActive) => {
    return _.get(extras, 'list.custom_recipes.enabled') || rewardActive;
  },
);
