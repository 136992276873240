/* @flow */
/*eslint-disable prefer-const */

import { CssBaseline } from '@material-ui/core';
import React, { useEffect } from 'react';
import PortalDynamicElement from '../dynamic-element';

function PortalDynamicContent(props) {
  useEffect(() => {
    if (props.elements.length === 0) {
      props.fetch();
    }
  }, []);
  return (
    <React.Fragment>
      <CssBaseline />
      {/* <Container maxWidth="xl"> */}
      {props.elements.map((element, index) => (
        <PortalDynamicElement key={index} index={index} />
      ))}
      {/* </Container> */}
    </React.Fragment>
  );
}

PortalDynamicContent.propTypes = {};

PortalDynamicContent.defaultProps = {};

export default PortalDynamicContent;
