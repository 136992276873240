/* @flow */

import produce from 'immer';
import * as types from '../actions/portal';

const data = (
  state: Object = {
    list: {},
    loaded: false,
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case types.REFRESH_PORTAL:
        draft.list = {};
        draft.loaded = false;
        return;
      case types.LOADED_PORTAL:
        Object.keys(action.data).forEach(key => {
          draft[key] = action.data[key];
        });
        draft.loaded = true;
        return;
    }
  });

export default data;
