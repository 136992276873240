/* @flow */

  import {DeviceInfo} from '../platform';
  
  export const IS_TABLET = DeviceInfo.isTablet();
  
  export const NAVBAR_HEIGHT = 56;
  export const CONTENT_CONTAINER_OFFSET = NAVBAR_HEIGHT;
  export const HEIGHT_CORRECTION = 0;
  export const STATUSBAR_HEIGHT = 0;
  export const STATUSBAR_LANDSCAPE_HEIGHT = STATUSBAR_HEIGHT;
  export const NAVBAR_LANDSCAPE_HEIGHT = NAVBAR_HEIGHT;
  export const CONTENT_LANDSCAPE_CONTAINER_OFFSET = NAVBAR_LANDSCAPE_HEIGHT;
  export const DOWN_BAR_SHADOW_HEIGHT = 10;
  export const PLAYLIST_HEIGHT = 46;
  export const ACTION_BUTTON_WIDTH = 56;
  export const NUTRITION_SUMMARY_BAR_HEIGHT = 160 + DOWN_BAR_SHADOW_HEIGHT;
  export const NUTRITION_SUMMARY_BAR_LANDSCAPE_HEIGHT = NAVBAR_HEIGHT  + DOWN_BAR_SHADOW_HEIGHT;
  export const PADDING_TOP_TEXT = 5;
  export const TITLE_ALIGN = 'left';
  export const ADMOB_ID = 'pub-3496513159625922';
  export const ADMOB_CODE = 'ca-app-pub-3496513159625922~7073482891';
  export const AD_INTERSTITIAL_CODE = 'ca-app-pub-3496513159625922/5454120099';
  export const AD_BANNER_CODE = 'ca-app-pub-3496513159625922/8550216093';
  export const AD_BANNER_HEIGHT = 250;
  export const AD_BANNER_WIDTH = 300;
  export const AD_BANNER_LABEL_HEIGHT = 50;
  export const $N_REGEX = '(\\d+(?:(?:/|\.)\\d+)?)( \\d+(?:(?:/|\.)\\d+)?)?';
  export const MARKET_LINK = 'https://play.google.com/store/apps/details?id=com.vegamecum.android';
  export const MAIL_ADDRESS = 'mailto:vegamecum@gmail.com?subject=Contacto app';
  export const SOCIAL_NETWORKS = {
	  twitter : 'https://twitter.com/vegamecum',
	  facebook : 'https://www.facebook.com/Vegamecum-1705334993044654/',
	  pinterest : 'https://es.pinterest.com/vegamecum/',
	  instagram : 'https://www.instagram.com/vegamecum/'
  };
  export const TOPIC_NEW_RECIPE = "/topics/new-recipe-android";
  export const TOPIC_NEW_RECIPE_OLD = "/topics/new-recipe";
  export const NOTIFICATION_CHANNEL = "default-channel";
  export const INLINE_IMAGE_SIZE_CORRECTOR = 1;
  export const HORIZONTAL_MAX_WIDTH = IS_TABLET ? 500 : 300;
  export const PLANIFICATION_ROW_MAX_WIDTH = 300;
  export const LOADING_WIDTH = 200;
  export const PAGE_HORIZONTAL_PADDING = IS_TABLET ? 40 : 10;
  export const DAY_NUMBER_WIDTH = 70;
  export const IMAGE_ASPECT_RATIO = 0.75;
  export const POST_SECTION_SELECTOR_ICON_IMAGE_SIZE = IS_TABLET ? 56 : 28;
  export const POST_SECTION_SELECTOR_ICON_SIZE = IS_TABLET ? 120 : 60;
  export const TEXTSIZE_SMALL_SMALL = IS_TABLET ? 8 * 1.2 : 8;
  export const TEXTSIZE_SMALL_NORMAL = IS_TABLET ? 10 * 1.2 : 10;
  export const TEXTSIZE_SMALL_BIG = IS_TABLET ? 12 * 1.2 : 12;
  export const TEXTSIZE_SMALL_BIGGEST = IS_TABLET ? 13 * 1.2 : 13;
  export const TEXTSIZE_NORMAL_SMALL = IS_TABLET ? 14 * 1.2 : 14;
  export const TEXTSIZE_NORMAL = IS_TABLET ? 15 * 1.2 : 15;
  export const TEXTSIZE_NORMAL_UPPER = IS_TABLET ? 16 * 1.2 : 16;
  export const TEXTSIZE_NORMAL_BIG = IS_TABLET ? 17 * 1.2 : 17;
  export const TEXTSIZE_NORMAL_BIGGER = IS_TABLET ? 18 * 1.2 : 18;
  export const TEXTSIZE_BIG_SMALL = IS_TABLET ? 20 * 1.2 : 20;
  export const TEXTSIZE_BIG_NORMAL = IS_TABLET ? 25 * 1.2 : 25;
  export const TEXTSIZE_BIGGEST = IS_TABLET ? 40 * 1.2 : 40;
  export const ICONSIZE_NORMAL = TEXTSIZE_BIG_SMALL;
  export const SHARE_ICON = "md-share";
  export const CONSOLIDATION_TIME = 2000;
  export const PROMO_VIDEO = {
	  INGREDIENTS_INDEXED: [/*
		  "Bocados Heura® Originales", 
		  "Bocados Heura® Especiados",
		  "Bocados Heura® Mediterráneos",
		  "Tiras de Heura® Originales",
		  "Tacos Heura® Especiados"
	  */],
	  RECIPES_INDEXED: [/*
		  "BBQ con Heura®",
		  "Buddha Bowl con Heura®", 
		  "Empanadillas semi-integrales de Heura® al Curry (by Mis Recetas Veganas)", 
		  "Ensalada fresca con salsa rosa",
		  "Fricasse de Heura® a la mostaza com parmetier de avellana",
		  "Heura y patatas al horno con salsa chimichurri",
		  "Paella de arroz integral y robellones",
		  "Pizza de la huerta con queso vegano y chilli",
		  "Roll de papel de arroz con Heura® estilo asiático a la lima",
		  "Sándwich Sa Conca acompañado de Patatas con salsa amostazada",
		  "Tallarines con Kale y Heura®",
		  "Wok de verduras fusión mediterráneo-vietnamita"
	  */],
	  AD: {/*
		  type: 'custom',
		  url: 'https://us-central1-vegamecum-c057a.cloudfunctions.net/showAd',
		  link: 'http://www.vegamecum.com',
		  image: 'https://firebasestorage.googleapis.com/v0/b/vegamecum-c057a.appspot.com/o/partnership%2Fheura%2Fbanner_definitivo.jpg?alt=media&token=1572200c-cfb9-44a3-bc48-cc4a8f4d1369'
	  */},
	  CUSTOM_FILTERS: [/*{
		  fetchParams: [
			  'search', {
				  ref : '/lists/partnership/heura',
				  translate: false
			}], 
			name:'Especial Heura®',
			buttonStyle: {
				backgroundColor:'#1f9044'
			},
			textStyle: {
				color:'white'
			},
			image: {
				path: 'https://firebasestorage.googleapis.com/v0/b/vegamecum-c057a.appspot.com/o/partnership%2Fheura%2Ffilter_logo.png?alt=media&token=46e713e8-d8e5-4a56-9590-8901acb54045',
				style: {
					width: 220,
					height: 63
				}
			}
		}*/]
  };