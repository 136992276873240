/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import SideMenuList from '../../components/side-menu-list';
import { getCategoryLink } from '../../utils/link-maker';
import { fetch } from '../../actions/categories';
import I18n from '../../i18n';

class Categories extends Component {
  componentDidMount() {
    this.props.dispatch(fetch());
  }
  render() {
    return <SideMenuList title={I18n.t('sideBar.categories')} items={this._getItems()}/>
  }
  _getItems() {
    return this.props.categories.map(item => ({
      name: item.name,
      link: getCategoryLink(item.name)
    }));
  }
}

Categories.propTypes = {
  dispatch: PropTypes.func,
  categories: PropTypes.array
};

Categories.defaultProps = {
  dispatch: () => {},
  categories: []
};

export default connect((state) => ({
  categories: Object.values(state.categories.list),
}))(Categories);