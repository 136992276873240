import {DOMParser, XMLSerializer} from 'xmldom'
import _ from 'lodash'

const Serializer = new XMLSerializer().serializeToString

export const USDAParser = post => {
  let doc = getDOMNodeFromString(post, 'tbody', true),
    head = getDOMNodeFromString(post, 'thead'),
    tds,
    td,
    ths,
    trs,
    node,
    lastIndex,
    startIndex,
    nutrients = {},
    i = 0,
    validColumn

  ths = head.getElementsByTagName('th')
  while (ths.length > i) {
    if (
      Serializer(ths[i]).indexOf('Value per 100 g') !== -1 ||
      Serializer(ths[i]).indexOf('Value per 100 ml') !== -1
    ) {
      validColumn = i
    }
    i++
  }
  i = 0
  trs = doc.getElementsByTagName('tr')
  while (trs.length > i) {
    node = trs[i]
    tds = node.getElementsByTagName('td')
    if (tds.length > 2) {
      td = Serializer(tds[1])
      startIndex = td.indexOf('>')
      td = td.substring(startIndex + 1)
      lastIndex = td.indexOf('<')
      td = td.substring(0, lastIndex)
      td = td.trim()
      nutrients[td] = parseFloat(tds[validColumn].textContent.trim(), 10)
    }
    i++
  }
  return nutrients
}

export const getDOMNodeFromString = (text, tagName, normalizeTrs) => {
  let closeTag = '</' + tagName + '>'
  let tableIndex = text.indexOf('<' + tagName)
  text = text.substring(tableIndex)
  text = text
    .replace(/&nbsp;/gi, ' ')
    .replace(/style="display:''/gi, ' ')
    .replace(/&micro;g/gi, 'micro')
  tableIndex = text.indexOf(closeTag)
  text = text.substring(0, tableIndex + closeTag.length)
  if (normalizeTrs) {
    let trs = text.split('</tr>')
    trs = _.compact(
      _.map(trs, tr => {
        if (tr.indexOf('<td') > -1) {
          let i = tr.indexOf('<tr>')
          if (i >= 0) {
            tr = tr.substring(i + 4, tr.length)
          }
          return (tr = '<tr>' + tr + '</tr>')
        }
      })
    )
    text = trs.join('')
  }
  return new DOMParser().parseFromString(
    text.replace(/&nbsp;/gi, ' '),
    'text/xml'
  )
}
