import * as firebaseActions from './firebase-actions'
export const RECEIVE_RELEASE_NOTES = 'RECEIVE_RELEASE_NOTES'

const receiveReleaseNotes = (data: Object): Object => {
  return {
    type: RECEIVE_RELEASE_NOTES,
    data,
  }
}

export const fetch = (): Function => {
  return (dispatch, getState) => {
    firebaseActions.getReleaseNotes(snapshot => {
      dispatch(receiveReleaseNotes(snapshot.val()))
    })
  }
}

export const updateReleaseNoteStatus = (data: Object): Function => {
  return (dispatch, getState) => {
    firebaseActions.updateReleaseNoteStatus(data)
  }
}
