/* @flow */
/*eslint-disable prefer-const */

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { getLinkForPathAndSearch } from '../../utils/link-maker';
import HorizontalList from '../horizontal-list';
import OverlayText from '../overlay-text';

const useItemStyles = makeStyles((theme) => ({
  itemContainer: {
    'backfaceVisibility': 'hidden',
    'borderRadius': '20px',
    'boxShadow':
      'rgb(0 0 0 / 69%) 0px 26px 30px -10px, rgb(0 0 0 / 73%) 0px 16px 10px -10px',
    'cursor': 'pointer',
    'display': 'block',
    'position': 'relative',
    'transform': 'scale(1, 1) translateZ(0px)',
    'transitionDuration': '300ms',
    'transitionProperty': 'transform, box-shadow',
    'transitionTimingFunction': 'ease-out',
    '&:hover, &:focus': {
      'boxShadow':
        'rgb(0 0 0 / 80%) 0px 40px 58px -16px, rgb(0 0 0 / 72%) 0px 30px 22px -10px',
      'transform':
        'scale(1.05, 1.05) translateZ(0px) translate3d(0px, 0px, 0px)',
      '&::after': {
        border: '4px solid rgba(249, 249, 249, 0.8)',
      },
    },
    '&::after': {
      position: 'absolute',
      content: '""',
      inset: '0px',
      borderRadius: '20px',
      border: '4px solid rgba(255, 255, 255, 0)',
      transition: 'border 300ms ease-out 0s',
      zIndex: 2,
    },
  },
  item: {
    position: 'relative',
    overflow: 'hidden',
    // width: theme.spacing(26),
    // height: theme.spacing(26),
    paddingTop: '70%',
    background: 'linear-gradient(rgb(48, 50, 62), rgb(30, 31, 42))',
    borderRadius: '20px',
    // margin: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingTop: '70%',
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(16),
    },
  },
  itemTitle: {
    zIndex: 1,
    backgroundImage:
      'linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.7) 100%)',
  },
  itemTitleText: {},
  image: (props) => ({
    'position': 'absolute',
    'top': 0,
    'left': 0,
    'right': 0,
    'bottom': 0,
    'margin': 'auto',
    'height': '100%',
    'width': '100%',
    'object-fit': 'cover',
    'overflow-x': 'hidden',
  }),
}));

function Item(props) {
  const classes = useItemStyles(props);
  const link = getLinkForPathAndSearch({
    path: `/posts/${props.slug}`,
  });
  //console.log('post ', props);
  return (
    <Box className={classes.slideContainer} p={2}>
      <Link
        component={RouterLink}
        to={link}
        title={props.title}
        className={classes.itemContainer}
      >
        <Box
          flexDirection="row"
          className={classes.item}
          alignItems="flex-end"
          display="flex"
        >
          <img
            className={classes.image}
            title={props.title}
            data-src={props.mainImage}
            src={props.mainImage}
            alt={props.title}
            width="840"
            height="840"
          />
          <OverlayText text={props.title} />
        </Box>
      </Link>
    </Box>
  );
}

function HorizontalPostList(props) {
  return (
    <HorizontalList>
      {_.compact(
        props.posts.map((item, index) =>
          index < props.limit ? <Item key={index} {...item} /> : null
        )
      )}
    </HorizontalList>
  );
}

HorizontalPostList.propTypes = {};

HorizontalPostList.defaultProps = {
  posts: [],
  limit: Infinity,
};

export default HorizontalPostList;
