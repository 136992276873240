import * as firebaseActions from './firebase-actions';
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES';

export const receiveCategories = (data: Object): Object => {
  return {
    type: RECEIVE_CATEGORIES,
    data,
  };
};

export const fetch = (): Function => {
  return async (dispatch, getState) => {
    firebaseActions.getCategories().then((snapshot) => {
      dispatch(receiveCategories(snapshot.val()));
    });
  };
};