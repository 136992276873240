/* @flow */

import * as types from '../actions'
import produce from 'immer'

const data = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.RECEIVE_FIREBASE_DATA:
        return Object.assign({}, state, action.data)
    }
  })

export default data
