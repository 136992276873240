import * as firebaseActions from './firebase-actions'
import * as actions from './index'

export const deleteNewRecipe = (slug): Function => {
  return (dispatch, getState) => {
    firebaseActions.deleteNewRecipe(slug)
  }
}

export const deletePostByPath = (path): Function => {
  return (dispatch, getState) => {
    firebaseActions.deletePostByPath(path)
  }
}

export const startEditNewRecipe = (slug, parentPost): Function => {
  return (dispatch, getState) => {
    firebaseActions.startEditNewRecipe(slug, parentPost, (snapshot: Object) => {
      let post = snapshot.val()
      if (post && snapshot.ref) {
        post = {
          ...post,
          isEditable: true,
          path: snapshot.ref.path,
        };
      }

      dispatch(
        actions.loadedPost({
          slug: post.slug,
          post,
        })
      )
    })
  }
}

export const startEditPostByPath = (path): Function => {
  return (dispatch, getState) => {
    firebaseActions.startEditPostByPath(path, (snapshot: Object) => {
      let post = snapshot.val()
      if (post && snapshot.ref) {
        post = {
          ...post,
          isEditable: true,
          path: snapshot.ref.path,
        };
      }
      dispatch(
        actions.loadedPost({
          slug: post.slug,
          post,
        })
      )
    })
  }
}

export const startEditPostDraftBySlug = (slug): Function => {
  return (dispatch, getState) => {
    firebaseActions.startEditDraftPostBySlug(slug, (snapshot: Object) => {
      let post = snapshot.val()
      if (post && snapshot.ref) {
        post = {
          ...post,
          isEditable: true,
          path: snapshot.ref.path,
        };
      }
      dispatch(
        actions.loadedPost({
          slug: snapshot.ref.path,
          post,
        })
      )
    })
  }
}

export const finishEditNewRecipe = (slug): Function => {
  return (dispatch, getState) => {
    firebaseActions.finishEditNewRecipe(slug)
  }
}

export const finishEditPostByPath = (path): Function => {
  return (dispatch, getState) => {
    firebaseActions.finishEditPostByPath(path)
  }
}

export const finishEditPostDraftBySlug = (slug): Function => {
  return (dispatch, getState) => {
    firebaseActions.finishEditPostDraftBySlug(slug)
  }
}

export const updateTitle = (post, data): Function => {
  return (dispatch, getState) => {
    firebaseActions.editPostTitle(post, data)
  }
}

export const updateIntroductionBlogOnly = (post, data): Function => {
  return (dispatch, getState) => {
    firebaseActions.editPostIntroductionBlogOnly(post, data)
    firebaseActions.updateModifiedDate(post);
  }
}

export const updateIntroduction = (post, data): Function => {
  return (dispatch, getState) => {
    firebaseActions.editPostIntroduction(post, data)
    firebaseActions.updateModifiedDate(post);
  }
}

export const updateCategories = (post, data): Function => {
  return (dispatch, getState) => {
    firebaseActions.editPostCategories(post, data)
  }
}

export const updateTags = (post, data): Function => {
  return (dispatch, getState) => {
    firebaseActions.editPostTags(post, data)
  }
}

export const updatePostFigure = (post, index, data): Function => {
  return (dispatch, getState) => {
    firebaseActions.editPostFigure(post, index, data);
    firebaseActions.updateModifiedDate(post);
  };
};
export const updatePostImages = (post, data): Function => {
  return (dispatch, getState) => {
    firebaseActions.editPostImages(post, data)
  }
}

export const updateSummary = (post, data): Function => {
  return (dispatch, getState) => {
    firebaseActions.editPostSummary(post, data)
    firebaseActions.updateModifiedDate(post);
  }
}

export const updateRecipeNumber = (post, data): Function => {
  return (dispatch, getState) => {
    firebaseActions.editRecipeNumber(post, data)
  }
}

export const removePreparationStep = (post, index): Function => {
  return (dispatch, getState) => {
    firebaseActions.removePreparationStep(post, index)
  }
}

export const updatePreparationStep = (post, data): Function => {
  return (dispatch, getState) => {
    firebaseActions.editPreparationStep(post, data)
  }
}

export const removeIngredientBlock = (post, block): Function => {
  return (dispatch, getState) => {
    firebaseActions.removeIngredientBlock(post, block)
  }
}

export const updateIngredientBlock = (post, block, data): Function => {
  return (dispatch, getState) => {
    firebaseActions.editIngredientBlock(post, block, data)
  }
}

export const updateIngredientBlockName = (post, block, data): Function => {
  return (dispatch, getState) => {
    firebaseActions.editIngredientBlockName(post, block, data)
  }
}

export const uploadImageFile = (file, options = { addWaterMark: false, width: '840' }) => {
  return async (dispatch, getState) => {
    const body = new FormData();
    body.append("addWaterMark", options.addWaterMark);
    body.append("width", options.width);
    body.append("file", file);
    try {
      const response = await fetch(
        "https://us-central1-vegamecum-c057a.cloudfunctions.net/uploadImage",
        {
          method: "POST",
          body,
        }
      );
      const json = await response.json();
      return json;
    } catch (e) {
      return {
        status: "error",
      };
    }
  };
};

export const uploadImage = ({ uri, name, type }) => {
  return async (dispatch, getState) => {
    const file = {
      uri,
      name,
      type,
    };
    return uploadImageFile(file);
  };
};

