/* @flow */

let isFXEnabled = true, isAlarmEnabled = true;

export const tap = {
    stop: () => {
    },
    setCurrentTime: (sec) => {
    },
    play: () => {
    }
}

export const winner = {
    stop: () => {
    },
    setCurrentTime: (sec) => {
    },
    play: () => {
    }
}

export const remove = {
    stop: () => {
    },
    setCurrentTime: (sec) => {
    },
    play: () => {
    }
}

export const alarm = {
    stop: () => {
    },
    setCurrentTime: (sec) => {
    },
    play: () => {
    }
}

export const disableEffects = (): Object => {
    isFXEnabled = false;
}

export const enableEffects = (): Object => {
    isFXEnabled = true;
}

export const disableAlarm = (): Object => {
    isAlarmEnabled = false;
}

export const enableAlarm = (): Object => {
    isAlarmEnabled = true;
}